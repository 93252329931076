import React from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

function getGradient(ctx, chartArea) {
    let gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top,
    );
    gradient.addColorStop(0, 'rgba(24, 130, 255, 0.28)');
    gradient.addColorStop(1, '#4685FF');
    return gradient;
}

const data = {
    labels: ['00', '04', '08', '12', '14', '16', '18'],
    datasets: [
        {
            label: '',
            backgroundColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                // This case happens on initial chart load
                if (!chartArea) return;
                return getGradient(ctx, chartArea);
            },
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 10,
            barPercentage: 0.6,
            data: [65, 59, 180, 81, 56, 270, 40],
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            display: false,
        },
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                border: false,
            },
        },

        y: {
            display: false,
            beginAtZero: true,
            grid: {
                display: false,
                border: false,
            },
            ticks: {
                stepSize: 100,
            },
        },
    },
};

export default function TraficChart() {
    return <Bar data={data} options={options} />;
}
