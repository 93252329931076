import { styled } from 'styled-components';
import { Pagination } from 'antd';

export const AntPagination = styled(Pagination)`
    display: flex;
    justify-content: ${(props) => (props.style ? 'end' : 'center')};

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
        border: 1px solid #dadde1;
        border-radius: 0;
        margin: 0 !important;
        padding: 6px 13px;
        height: 44px;
        font-size: 16px !important;
        @media (max-width: 768px) {
            font-size: 0.8rem !important;
            padding: 0 10px;
            height: auto;
        }
    }

    .ant-pagination-next {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .ant-pagination-prev {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .ant-pagination-item {
        a {
            color: inherit;
        }

        &.ant-pagination-item-active {
            background-color: ${(props) =>
                props.style ? '#007AFF' : '#272727'};
            border: ${(props) =>
                props.style ? '1px solid #007AFF' : '1px solid #272727'};
            a {
                color: #fff;
            }
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        a {
            color: #ffffff;
        }
    }

    .ant-pagination-prev {
        margin-right: 10px;
        background: ${(props) => (props.style ? '#007AFF' : '#272727')};
    }

    .ant-pagination-next {
        margin-left: 10px;
        background: ${(props) => (props.style ? '#007AFF' : '#272727')};
    }

    .ant-pagination-options-size-changer {
        display: none;
    }
    .ant-pagination-options {
        margin-inline-start: ${(props) => (props.style ? '0px' : 'auto')};
    }
`;
