import {
    Avatar,
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableCell,
} from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import HeaderText from '../Text/HeaderText';
import SubHeaderText from '../Text/SubHeaderText';
import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import CustomSelect from '../Input/CustomSelect';
import validationService from '../../services/validationService';
import PreLabel from '../Text/PreLabel';
import { useFormData } from '../../services/formDataContext';
import styled from 'styled-components';

const capitalizedFirstChar = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Red Hat Display',
}));

const CustomTypography = ({
    title,
    color = '#000000',
    mb = 0,
    fontWeight = 700,
    fontSize = '18px',
}) => {
    return (
        <SubHeaderText
            text={title}
            color={color}
            customstyle={{
                mb: mb,
                fontWeight: fontWeight,
                fontSize: fontSize,
                fontFamily: 'Red Hat Display',
            }}
        />
    );
};

const CampaignStep4 = ({ nextCampaignId }) => {
    const { formData } = useFormData();
    const { user } = JSON.parse(localStorage.getItem('user'));

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    backgroundColor: '#FCFCFC',
                    boxShadow:
                        '0 -2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    mt: '35px',
                }}
            >
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    gap={'10px'}
                    padding={'17px 36px'}
                >
                    <Box sx={{ width: '100%', mt: '20px' }}>
                        <CustomTypography title="Campaign Details" mb={2} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Campaign Id
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Campaign Title
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Age
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Gender
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {nextCampaignId}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.title || 'Dummy'}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.age
                                                    ?.map((age) => age?.value)
                                                    ?.join(',') || ''}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.gender || 'Dummy'}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Content Category
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Enrollment Type
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Platform(s)
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Approval Type
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.contentCategory
                                                    ?.map(
                                                        (content) =>
                                                            content?.value,
                                                    )
                                                    ?.join(',') || ''}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {formData?.payoutType}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {formData?.platforms?.join(',')}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {formData?.approvalType ===
                                                'Instant'
                                                    ? 'Instant Enrollment'
                                                    : 'Manual Approval'}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <CustomTypography title="Campaign Type" mb={2} />

                        <Box>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Campaign Type
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.campaignType ||
                                                    'Dummy'}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <CustomTypography title="Influencer Deal" mb={2} />

                        <Box>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Influencer Offered
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Product Value
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                Guaranteed Content
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.product_details}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                AED{' '}
                                                {formData?.compensation_value}
                                            </CustomTableCell>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {user?.merchant?.package ===
                                                    2 ||
                                                user?.role?.name === 'SA'
                                                    ? 'Posts, Stories, Reels'
                                                    : 'Stories'}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <CustomTypography title="Visit Location" mb={2} />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            {formData?.locations?.map((location, index) => (
                                <TableContainer
                                    key={index}
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell>
                                                    Visiting Location
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    Days
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    {location?.address}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    {formData?.selectedDays?.join(
                                                        ',',
                                                    )}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))}
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Time Slots
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.selectedTimes?.join(
                                                    ',',
                                                )}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <CustomTypography
                            title="Hashtags & Description"
                            mb={2}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Hashtags
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.hashtags?.join(',')}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    boxShadow: 'none',
                                    border: '1px solid #E9EDF4',
                                    borderBottom: 'none',
                                    borderRadius: '10px',
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>
                                                Description
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {formData?.description}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <WrapperStandardTextField md={12} lg={12}>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                        backgroundColor: '#272727',
                        color: '#FFFFFF',
                        textTransform: 'capitalize',
                        mt: '10px',
                        '&:hover': {
                            backgroundColor: '#272727',
                        },
                    }}
                >
                    Review & Submit
                </LoadingButton>
            </WrapperStandardTextField>
        </Box>
    );
};

export default CampaignStep4;
