import { Grid, Typography } from '@mui/material';
import CompletedCampaignBox from './CompletedGoingCampaign';
import { useEffect, useState } from 'react';
import dev from '../../services/axios-client';
import Loader from '../Loader';

export const PastCampaigns = ({ index }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        index === 2 && getAllPastCampaigns();
        //eslint-disable-next-line
    }, [index]);

    const getAllPastCampaigns = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                user?.user?.merchant
                    ? `/campaign/pastCampaigns?page=${page}&per_page=1000&merchantId=${user?.user?.merchant?.id}`
                    : `/campaign/pastCampaigns?page=${page}&per_page=1000`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setRows(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    return (
        <>
            <Loader loading={loading} />
            <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="start"
                columns={12}
                gap={3}
            >
                {rows?.data?.length > 0 ? (
                    rows?.data?.map((campaign) => (
                        <Grid xs="auto" key={campaign.id}>
                            <CompletedCampaignBox campaign={campaign} />
                        </Grid>
                    ))
                ) : (
                    <Grid xs={12}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                lineHeight: '22px',
                                fontFamily: 'IBM Plex Sans',
                                color: '#282f53',
                                fontWeight: 600,
                            }}
                        >
                            No past campaigns found
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
