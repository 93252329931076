import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import validationService from '../../services/validationService';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import dev from '../../services/axios-client';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CalenderIcon from '../../assets/Calendar_light.png';
import WarningDialog from '../Popups/WarningDialog';
import Loader from '../Loader';
import FormSubmitDialog from '../Popups/formSubmitDialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CurrentCampaigns = ({ tabKey }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl;
    const [modal, setModal] = useState({ open: false });
    const [loading, setLoading] = useState(false);
    const [responseModal, setResponseModal] = useState({ open: false });
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [current, setCurrent] = useState([]);

    const handleClick = (event, campaign) => {
        setAnchorEl(event.currentTarget);
        setSelectedCampaign(campaign);
    };

    let isMerchantUser = false;

    useEffect(() => {
        if (tabKey === 1) getCurrentCampaigns();
        //eslint-disable-next-line
    }, [tabKey]);

    const getCurrentCampaigns = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                user?.user?.merchant
                    ? `/api/v1/campaign?page=${page}&per_page=1000&merchantId=${user?.user?.merchant?.id}`
                    : `/api/v1/campaign?page=${page}&per_page=1000`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setCurrent(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    if (user?.user?.merchant) isMerchantUser = true;

    const handleCompleted = async () => {
        try {
            await dev.post(
                '/campaign/complete',
                { campaignId: null },
                {
                    headers: {
                        token: user.token,
                    },
                },
            );
        } catch (error) {
            setError(true);
            let res = error.response.data;
            setMsg(res.error || res.message);
            console.log('Error:', res.error || res.message);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCancelAndDelete = () => {
        setModal({
            open: true,
            title: 'Are you sure you want to delete this campaign?',
            cancelBtn: 'No, Keep It',
            submitBtn: 'Yes, Delete',
        });
    };
    const handleDeleteDocument = async (doc) => {
        setLoading(true);
        await dev
            .delete(`/campaign/${doc.id}`, {
                data: { docName: doc.docName },
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: 'Campaign deleted successfully',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete campaign',
                });
            });
    };

    const handleDelete = (flag) => {
        if (flag) {
            handleDeleteDocument(selectedCampaign);
        }
        setModal({ ...modal, open: false });
    };

    const handleDetails = () => {
        navigate(`/view-campaigns-details/${selectedCampaign.id}`);
    };

    const handleResponseClose = () => {
        setResponseModal({ open: false });
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="start"
            columns={12}
            gap={3}
        >
            <Loader loading={loading} />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleDelete} />
            {current.map((campaign, index) => (
                <Grid xs="auto" key={index}>
                    <Card
                        sx={{
                            width: ['100%', '376px'],
                            height: '397px',
                            borderRadius: '12px',
                        }}
                    >
                        <CardContent>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems={'center'}
                                height={'169px'}
                                mb={1}
                                sx={{
                                    background:
                                        'linear-gradient(111.47deg, #313131 13.55%, #141414 89.64%)',
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Red Hat Display',
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '24px',
                                        fontWeight: 400,
                                        width: '247px',
                                        color: '#EFEFEF',
                                    }}
                                >
                                    {campaign.title}
                                </Typography>
                            </Box>
                            <Box
                                mt={'20px'}
                                gap="5px"
                                width={'178px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{
                                    background: '#EDFFE8',
                                }}
                            >
                                <img
                                    alt=""
                                    width={16}
                                    height={16}
                                    src={CalenderIcon}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#0D9C00',
                                    }}
                                >
                                    Submitted on:{' '}
                                    {validationService.formatDate(
                                        campaign.createdAt,
                                    )}
                                </Typography>
                            </Box>
                            <Typography
                                color="#000000"
                                sx={{
                                    mt: '30px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontFamily: 'Red Hat Display',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                }}
                            >
                                Campaign Premise
                                <Typography
                                    color="#000000"
                                    sx={{
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {campaign?.campaignType}
                                </Typography>
                            </Typography>
                            <Typography
                                color="#000000"
                                sx={{
                                    mt: '20px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontFamily: 'Red Hat Display',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                }}
                            >
                                Platform(s)
                                <Typography
                                    color="#000000"
                                    sx={{
                                        mb: 1,
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {campaign?.platforms?.join(',')}
                                </Typography>
                            </Typography>

                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'baseline'}
                            >
                                <Button
                                    sx={{
                                        mt: '30px',
                                        width:
                                            campaign?.isActive === 1
                                                ? '118px'
                                                : campaign?.isActive === 3
                                                ? '111px'
                                                : '193px',
                                        height: '33px',

                                        background:
                                            campaign?.isActive === 1
                                                ? '#D4FF02'
                                                : campaign?.isActive === 3
                                                ? '#ECECEC'
                                                : '#FFE7E7',
                                        borderRadius: '10.06px',
                                        color:
                                            campaign?.isActive === 4
                                                ? '#DB0000'
                                                : '#000000',
                                        border: '1px solid white',
                                        textTransform: 'none',
                                        cursor: 'inherit',
                                        ':hover': {
                                            border: '1px solid white',
                                            color:
                                                campaign?.isActive === 4
                                                    ? '#DB0000'
                                                    : '#000000',
                                            background:
                                                campaign?.isActive === 1
                                                    ? '#D4FF02'
                                                    : campaign?.isActive === 3
                                                    ? '#ECECEC'
                                                    : '#FFE7E7',
                                        },
                                    }}
                                    onClick={() =>
                                        campaign?.isActive === 1
                                            ? navigate(
                                                  `/campaign-influencers/${campaign?.id}`,
                                              )
                                            : null
                                    }
                                >
                                    <Box
                                        display={'flex'}
                                        gap="5px"
                                        alignItems={'center'}
                                    >
                                        {campaign?.isActive === 4 && (
                                            <InfoOutlinedIcon
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#DB0000',
                                                }}
                                            />
                                        )}
                                        <Typography
                                            sx={{
                                                fonFamily: 'Red Hat Display',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {campaign?.isActive === 1
                                                ? 'Active | View'
                                                : campaign?.isActive === 3
                                                ? 'In Review'
                                                : 'Require changes'}
                                        </Typography>
                                    </Box>
                                </Button>
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                            open ? 'long-menu' : undefined
                                        }
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) =>
                                            handleClick(e, campaign)
                                        }
                                    >
                                        <MoreHorizIcon
                                            sx={{ fontSize: '35px' }}
                                        />
                                    </IconButton>

                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleDetails}>
                                            View Details
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCancelAndDelete}
                                        >
                                            Cancel & Delete
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default CurrentCampaigns;
