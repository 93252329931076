import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    tableCellClasses,
    Container,
} from '@mui/material';
import { useEffect, useState } from 'react';
import HeaderText from '../Text/HeaderText';
import dev from '../../services/axios-client';
import Loader from '../Loader';
import { useNavigate, useLocation } from 'react-router';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import PrimaryBtn from '../CustomButton/PrimaryBtn';
import PaymentBox from './PaymentBox';
import MerchantPaymentsTable from './MerchantPayments';
import InfluencerPayoutsTable from './InfluencerPayouts';
import TransactionHistoryTable from './TransactionHistory';
import ApproveRejectRequestsTable from './ApproveRejectRequests';
import AllBrandsTable from './AllBrands';
import InvoicesTable from './Invoices';
import {
    isCampaignManagerM,
    isFinanceManager,
    isSuperAdmin,
} from '../../services/roleCheckService';

const headerColumnStyle = {
    fontSize: '14px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontFamily: "'IBM Plex Sans'",
    color: '#2e3138',
    textAlign: 'left',
    marginBottom: '5px',
    padding: { xs: '8px 8px', md: '10px 10px', lg: '16px 16px' },
};

const activeHeader = {
    cursor: 'pointer',
    padding: '19px 22px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '500',
    color: '#272727',
    textAlign: 'center',
    border: '1px solid #E9EDF4',
    borderRadius: '5px 5px 0px 0px',
    borderCollapse: 'null',
};

const nonActiveHeader = {
    cursor: 'pointer',
    padding: '19px 22px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '500',
    color: '#74829C',
    textAlign: 'center',
};

const ManagePayment = () => {
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [active, setActive] = useState(0);
    const [paymentStats, setPaymentStats] = useState(null);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const tab = urlParams.get('tab');
    const navigate = useNavigate();
    let isAdmin = false;
    let isMerchantUser = false;

    useEffect(() => {
        const tabToActiveIndex = {
            mp: 1,
            ar: 2,
            mth: 3,
            ab: 4,
            in: 5,
        };

        setActive(tabToActiveIndex[tab] || 0);
    }, [location, tab]);

    if (isFinanceManager(user?.user) || isSuperAdmin(user?.user)) {
        isAdmin = true;
    }

    if (user?.user?.merchant) {
        isMerchantUser = true;
    }

    const _renderAdminTabs = () => {
        return (
            <>
                <TableCell
                    sx={active === 0 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments')}
                >
                    Influencer Payouts
                </TableCell>
                <TableCell
                    sx={active === 1 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=mp')}
                >
                    Merchant Payments
                </TableCell>
                <TableCell
                    sx={active === 2 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=ar')}
                >
                    Approve / Reject Requests
                </TableCell>
                <TableCell
                    sx={active === 3 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=mth')}
                >
                    Monitor Transaction History
                </TableCell>
                <TableCell
                    sx={active === 4 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=ab')}
                >
                    All Brands
                </TableCell>
            </>
        );
    };

    const _renderBrandTabs = () => {
        return (
            <>
                <TableCell
                    sx={active === 0 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments')}
                >
                    Influencer Payouts
                </TableCell>
                <TableCell
                    sx={active === 5 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=in')}
                >
                    Invoices
                </TableCell>
            </>
        );
    };
    const _renderMerchantTabs = () => {
        return (
            <>
                <TableCell
                    sx={active === 5 ? activeHeader : nonActiveHeader}
                    onClick={() => navigate('/manage-payments?tab=in')}
                >
                    Invoices
                </TableCell>
            </>
        );
    };

    const getPaymentStats = async () => {
        try {
            const response = await dev.get('/admin/paymentStats');
            setPaymentStats(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getPaymentStats();
    }, []);

    return (
        <Container maxWidth="xl">
            <Grid container sx={{ height: '100%' }}>
                <Loader loading={loading} />
                {!isMerchantUser && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <HeaderText text="Manage Payments" />
                        {/* <PrimaryBtn startIcon={<SwapVertIcon />} text="Transactions" /> */}
                    </Grid>
                )}
                <Grid container spacing={2} mt={2}>
                    {isAdmin && (
                        <Grid item xs={12} sm={6} lg={3}>
                            <PaymentBox
                                label="Total Balance in Brands and Wallet"
                                value={`$ ${
                                    paymentStats?.totalBrandsBalance || 0.0
                                }`}
                            />
                        </Grid>
                    )}
                    {isAdmin && (
                        <Grid item xs={12} sm={6} lg={3}>
                            <PaymentBox
                                label="Total Balance in Influencer Wallets"
                                value={`$ ${
                                    paymentStats?.totalInfluencersBalance || 0.0
                                }`}
                            />
                        </Grid>
                    )}
                    {!isMerchantUser && (
                        <Grid item xs={12} sm={6} lg={2}>
                            <PaymentBox
                                label="Previous Payout"
                                value={`$ ${
                                    paymentStats?.totalPreviousPayout || 0.0
                                }`}
                            />
                        </Grid>
                    )}
                    {!isMerchantUser && (
                        <Grid item xs={12} sm={6} lg={2}>
                            <PaymentBox
                                label="Next Payout"
                                value={`$ ${
                                    paymentStats?.totalNextPayout || 0.0
                                }`}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        mt: '20px',
                        padding: '30px',
                        borderRadius: '7px',
                        boxShadow: '0px 8px 24px rgba(168, 180, 208, 0.1)',
                    }}
                >
                    <TableContainer sx={{ width: isAdmin ? '100%' : '23rem' }}>
                        <Table>
                            <TableHead
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: '1px solid #E9EDF4',
                                    },
                                }}
                            >
                                <TableRow>
                                    {isAdmin
                                        ? _renderAdminTabs()
                                        : isMerchantUser
                                        ? _renderMerchantTabs()
                                        : _renderBrandTabs()}
                                    <TableCell
                                        sx={{ ...headerColumnStyle }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {active === 0 && <InfluencerPayoutsTable />}
                    {active === 1 && isAdmin && <MerchantPaymentsTable />}
                    {active === 2 && isAdmin && <ApproveRejectRequestsTable />}
                    {active === 3 && isAdmin && <TransactionHistoryTable />}
                    {active === 4 && isAdmin && <AllBrandsTable />}
                    {active === 5 && !isAdmin && <InvoicesTable />}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ManagePayment;
