import React, { useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  Modal,
  Box,
  Button,
} from "@mui/material";
import PrimaryBtn from "../../CustomButton/PrimaryBtn";
import dummy_image from "../../../assets/dummy_image.png";
import not_posted from "../../../assets/not-posted.png";

const headingStyle = {
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "21px",
  color: "#272727",
};

const cardStyle = {
  height: "100%",
  width: "100%",
  background: "#F5F5F5",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const btnStyle = {
  margin: "10px",
  width: "95%",
  height: "40px",
};

const CampaignPreviewCard = ({ posts }) => {
  const [open, setOpen] = useState(false);

  const videos = posts.filter((post) => post.contentType === "video");
  const stories = posts.filter((post) => post.contentType === "story");
  const reels = posts.filter((post) => post.contentType === "reel");

  const toggleModal = () => setOpen(!open);

  return (
    <Grid container spacing={3} sx={{ height: "auto", marginTop: "10px" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          reason !== "backdropClick" && toggleModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "400px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography>There is no link in this post</Typography>
          <Button onClick={toggleModal}>Close</Button>
        </Box>
      </Modal>

      {/* Videos */}
      <Grid item xs={12}>
        <Typography sx={headingStyle}>Videos</Typography>
      </Grid>
      {videos.length > 0 ? (
        <Grid container item xs={12} spacing={8}>
          {videos.map((video, index) => (
            <Grid key={`video-card-${index}`} item xs={12} sm={6} md={4}>
              <Card sx={cardStyle}>
                <CardActionArea sx={{ height: "185px", display: "flex" }}>
                  <img
                    alt=""
                    src={dummy_image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </CardActionArea>
                <PrimaryBtn
                  text="Preview"
                  bgColor="#272727"
                  style={btnStyle}
                  onClick={() =>
                    video.contentLink.length
                      ? window.open(video.contentLink, "_blank")
                      : toggleModal()
                  }
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={8}>
          {[1, 2, 3].map((cardNumber, index) => (
            <NotPostedCard key={index} />
          ))}
        </Grid>
      )}

      {/* Stories */}
      <Grid item xs={12}>
        <Typography sx={headingStyle}>Stories</Typography>
      </Grid>
      {stories.length > 0 ? (
        <Grid container item xs={12} spacing={8}>
          {stories.map((story, index) => (
            <Grid key={`story-card-${index}`} item xs={12} sm={6} md={4}>
              <Card sx={cardStyle}>
                <CardActionArea sx={{ height: "185px", display: "flex" }}>
                  <img
                    alt=""
                    src={dummy_image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </CardActionArea>
                <PrimaryBtn
                  text="Preview"
                  bgColor="#272727"
                  style={btnStyle}
                  onClick={() =>
                    story.contentLink.length
                      ? window.open(story.contentLink, "_blank")
                      : toggleModal()
                  }
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={8}>
          {[1, 2, 3].map((cardNumber, index) => (
            <NotPostedCard key={index} />
          ))}
        </Grid>
      )}

      {/* Reels */}
      <Grid item xs={12}>
        <Typography sx={headingStyle}>Reels</Typography>
      </Grid>
      {reels.length > 0 ? (
        <Grid container item xs={12} spacing={8}>
          {reels.map((reel, index) => (
            <Grid key={`reel-card-${index}`} item xs={12} sm={6} md={4}>
              <Card sx={cardStyle}>
                <CardActionArea sx={{ height: "185px", display: "flex" }}>
                  <img
                    alt=""
                    src={dummy_image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </CardActionArea>
                <PrimaryBtn
                  text="Preview"
                  bgColor="#272727"
                  style={btnStyle}
                  onClick={() =>
                    reel.contentLink.length
                      ? window.open(reel.contentLink, "_blank")
                      : toggleModal()
                  }
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container item xs={12} spacing={8}>
          {[1, 2, 3].map((cardNumber, index) => (
            <NotPostedCard key={index} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default CampaignPreviewCard;

const NotPostedCard = () => (
  <Grid item xs={12} sm={4}>
    <Card sx={cardStyle}>
      <CardActionArea sx={{ height: "185px", display: "flex" }}>
        <img alt="" src={not_posted} />
      </CardActionArea>
      <PrimaryBtn text="Not Posted Yet" bgColor="#272727" style={btnStyle} />
    </Card>
  </Grid>
);
