import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Slider,
    IconButton,
    Card,
    CardContent,
    CardActions,
    InputBase,
    useMediaQuery,
} from '@mui/material';
import ProductIcon from '../../assets/product.png';
import InfoIcon from '../../assets/Info.png';
import ServiceIcon from '../../assets/service.png';
import PlusIcon from '../../assets/Add_ring_fill.png';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LockIcon from '../../assets/Lock_alt.png';

import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import SubHeaderText from '../Text/SubHeaderText';
import CustomizedInputsStyled from '../Text/StandardTextField';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import CustomSelect from '../Input/CustomSelect';
import dev from '../../services/axios-client';
import LocationSearch from './AreaLocations';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useFormData } from '../../services/formDataContext';
let timeoutId;

const CustomSlider = styled(Slider)({
    color: '#EDEEF1', // Slider line color
    height: '6px', // Height of the slider line
    '&.MuiSlider-colorPrimary': {
        color: '#EDEEF1', // Change the primary color of the slider to red
    },
    '& .MuiSlider-thumb': {
        height: '27px', // Size of the circle (thumb)
        width: '52px',
        backgroundColor: '#000000', // Background color of the circle
        borderRadius: '25px',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '& .MuiSlider-valueLabel': {
            color: '#D4FF02', // Text color
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '24px',
            backgroundColor: 'unset',
            background: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        height: '6px',
        opacity: 0.5,
        backgroundColor: '#EDEEF1', // Background color of the slider rail
    },
});
const marks = [
    { value: 300, label: '' },
    { value: 400, label: '' },
    { value: 750, label: '' },
    { value: 1100, label: '' },
    { value: 1200, label: '' },
    { value: 1350, label: '' },
    { value: 1500, label: '' },
    { value: 2000, label: '' },
    { value: 2500, label: '' },
    { value: 3000, label: '' },
];

const valueLabelFormat = (value) => {
    if (value === 3000) return '>3000';
    if (value === 1100) return 750;
    if (value === 750) return 500;
    if (value === 1200) return 1000;
    if (value === 1350) return 1250;
    return value;
};

const LineWithDots = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Box
                sx={{
                    width: 6,
                    height: 6,
                    backgroundColor: 'black',
                    borderRadius: '50%',
                }}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    border: '1px solid black',
                    backgroundColor: 'black',
                }}
            />
            <Box
                sx={{
                    width: 6,
                    height: 6,
                    backgroundColor: 'black',
                    borderRadius: '50%',
                }}
            />
        </Box>
    );
};

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
const time1 = [
    'MIDNIGHT',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
];

const time2 = [
    'NOON',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM',
];

const ScheduleSelector = ({
    selectedDays,
    selectedTimes,
    setSelectedDays,
    setSelectedTimes,
}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const upgraded =
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA';
    const is1440 = useMediaQuery('(max-width:1440px)');

    const handleDayClick = (day) => {
        setSelectedDays((prevSelectedDays) =>
            prevSelectedDays.includes(day)
                ? prevSelectedDays.filter((d) => d !== day)
                : [...prevSelectedDays, day],
        );
    };

    const handleTimeClick = (time) => {
        setSelectedTimes((prevSelectedTimes) =>
            prevSelectedTimes.includes(time)
                ? prevSelectedTimes.filter((t) => t !== time)
                : [...prevSelectedTimes, time],
        );
    };

    return (
        <Box position={'relative'}>
            {!upgraded && (
                <Box
                    display={'flex'}
                    gap="5px"
                    alignItems={'center'}
                    position="absolute" // Position text absolutely
                    top="50px" // Adjust this value as needed
                    left="35%"
                    transform="translateX(-50%)" // Center horizontally
                >
                    <img src={LockIcon} alt="" />

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            fontFamily: 'Red Hat Display',
                            color: '#000000',
                        }}
                    >
                        Upgrade to unlock
                    </Typography>
                </Box>
            )}

            <Box
                sx={{
                    mt: '20px',
                    display: 'flex',
                    borderRadius: '8px',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    opacity: !upgraded ? 0.1 : 1,
                    pointerEvents: !upgraded ? 'none' : 'auto',
                }}
            >
                <Box display={'flex'} gap="8px">
                    {/* Days of the Week */}
                    {days.map((day) => (
                        <Box
                            onClick={() => handleDayClick(day)}
                            width={is1440 ? '40px' : ['40px', '50px', '56px']}
                            height={is1440 ? '60px' : ['60px', '70px', '80px']}
                            sx={{
                                background: selectedDays.includes(day)
                                    ? '#D4FF02'
                                    : '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '8px',
                                cursor: 'pointer',
                            }}
                            key={day}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    fontFamily: 'Red Hat Display',
                                }}
                            >
                                {day}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box display={'flex'} gap="20px">
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap="8px"
                        mt={2}
                    >
                        {/* Time Slots */}
                        {time1.map((time) => (
                            <Box
                                onClick={() => handleTimeClick(time)}
                                width={'136px'}
                                height={'37px'}
                                sx={{
                                    background: selectedTimes.includes(time)
                                        ? '#D4FF02'
                                        : '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                }}
                                key={time}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        fontFamily: 'Red Hat Display',
                                    }}
                                >
                                    {time}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap="8px"
                        mt={2}
                    >
                        {/* Time Slots */}
                        {time2.map((time) => (
                            <Box
                                onClick={() => handleTimeClick(time)}
                                width={'136px'}
                                height={'37px'}
                                sx={{
                                    background: selectedTimes.includes(time)
                                        ? '#D4FF02'
                                        : '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                }}
                                key={time}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        fontFamily: 'Red Hat Display',
                                    }}
                                >
                                    {time}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const CampaignStep2 = ({
    errors,
    register,
    getValues,
    setIndex,
    requiredFields,
    setRequiredFields,
    onChangeHandler,
    cities,
    compensationType,
    setCompensationType,
    locations,
    setLocations,
    selectedDays,
    selectedTimes,
    setSelectedDays,
    setSelectedTimes,
    setValue,
}) => {
    useEffect(() => {
        setRequiredFields(['compensationType', 'product_details']);
    }, []);
    const user = JSON.parse(localStorage.getItem('user'));
    const upgraded =
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA';

    const { formData } = useFormData();
    const addLocation = () => {
        if (locations.length < 10) {
            setLocations([...locations, { id: locations.length + 1 }]);
        }
    };

    const removeLocation = (id) => {
        setLocations(locations.filter((location) => location.id !== id));
    };

    const handleChange = (event, newValue) => {
        // Ensure the slider only updates if the newValue is one of the allowed values
        const allowedValues = marks.map((mark) => mark.value);

        if (allowedValues.includes(newValue)) {
            // If the value is valid, update the form state
            // Assuming you are using React Hook Form, you can set the value like this:
            setValue('compensation_value', newValue);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={12} sm={6}>
                    <WrapperStandardTextField lg={12}>
                        <SubHeaderText
                            text="Campaign Type"
                            color="#272727"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['compensationType']}
                            required={true}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            mt="20px"
                            columnGap={4}
                            rowGap={8}
                        >
                            <Box
                                display={'flex'}
                                alignItems="center"
                                flexDirection={'column'}
                                fontFamily={'Red Hat Display'}
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background:
                                        compensationType === 'product'
                                            ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                            : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '273px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setCompensationType('product')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 800,
                                        color: '#000000',
                                    }}
                                >
                                    Product
                                </Typography>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={ProductIcon} alt="" />
                                </Grid>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#000000',
                                        textAlign: 'center',
                                    }}
                                >
                                    Example: A perfume brand offering a perfume
                                    set
                                </Typography>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems="center"
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background:
                                        compensationType === 'service'
                                            ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                            : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '273px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setCompensationType('service')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 800,
                                        color: '#000000',
                                    }}
                                >
                                    Service
                                </Typography>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={ServiceIcon} alt="" />
                                </Grid>

                                <Typography
                                    sx={{
                                        mt: '20px',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#000000',
                                        textAlign: 'center',
                                    }}
                                >
                                    Example: A restaurant offering a 3-Course
                                    Meal
                                </Typography>
                            </Box>
                        </Grid>
                    </WrapperStandardTextField>

                    <Grid item xs={12} sm={7}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                                mt: 10,
                            }}
                        >
                            Value of what the influencer gets (AED):
                        </Typography>
                        <Box mt={3}>
                            <CustomSlider
                                defaultValue={1100}
                                aria-labelledby="value-slider"
                                valueLabelDisplay="on"
                                marks={marks}
                                min={300}
                                max={3000}
                                step={null} // Disable stepping since we are using specific marks
                                valueLabelFormat={valueLabelFormat}
                                onChange={handleChange}
                                {...register('compensation_value')}
                            />
                            <Box display={'flex'} gap={'10px'}>
                                <Box width={'30%'}>
                                    <LineWithDots />
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            fontFamily: 'Red Hat Display',
                                        }}
                                    >
                                        Posts, Stories
                                    </Typography>
                                </Box>
                                <Box width={'70%'}>
                                    <LineWithDots />
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            textAlign: 'center',
                                            fontFamily: 'Red Hat Display',
                                        }}
                                    >
                                        Guaranteed Posts, Stories, Reels
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Box width={'533px'} mt={6}>
                        <CustomizedInputsStyled
                            labelStyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            color="#000000"
                            height={'60px'}
                            required={true}
                            label="Details of what the influencer gets"
                            error={errors['product_details']}
                        >
                            <InputBase
                                fullWidth
                                sx={{
                                    ml: 2,
                                    flex: 1,
                                    color: '#808080',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                }}
                                placeholder={
                                    'Example: Influencer gets our new Men’s Perfume Gift Set to film & promote'
                                }
                                type={'text'}
                                inputProps={{
                                    'aria-label': 'Title',
                                }}
                                required
                                error={!!errors['product_details']}
                                helperText={
                                    errors['product_details']
                                        ? errors['product_details'].message
                                        : ''
                                }
                                {...register('product_details')}
                            />
                        </CustomizedInputsStyled>
                    </Box>
                    <Box mt={2}>
                        <SubHeaderText
                            text="Visit Location"
                            color="#272727"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['location']}
                            required={true}
                        />
                        <Box mt={3}>
                            {locations.map((location, index) => (
                                <Box
                                    key={location.id}
                                    display="flex"
                                    gap="5px"
                                    alignItems="center"
                                    mt={index > 0 ? 2 : 0} // Add margin-top only for subsequent locations
                                >
                                    <Box width="533px">
                                        <LocationSearch
                                            index={index}
                                            locations={locations}
                                            setLocations={setLocations}
                                        />
                                    </Box>
                                    {index === 0 && (
                                        <Box display="flex" alignItems="center">
                                            <IconButton onClick={addLocation}>
                                                <img
                                                    src={PlusIcon}
                                                    alt="Add"
                                                    width={24}
                                                    height={24}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: '12px',
                                                    fontFamily:
                                                        'Red Hat Display',
                                                }}
                                            >
                                                Add Location
                                            </Typography>
                                        </Box>
                                    )}
                                    {index > 0 && (
                                        <IconButton
                                            onClick={() =>
                                                removeLocation(location.id)
                                            }
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>

                {/* Right Side */}
                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        text="Create Visiting Time Slots"
                        color="#272727"
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                    />
                    <Grid
                        display={'flex'}
                        flexDirection={['column', null, 'row']}
                        gap="30px"
                        mt="20px"
                    >
                        <Card
                            sx={{
                                p: 2,
                                background: !upgraded
                                    ? '#E7E7E7'
                                    : 'radial-gradient(50% 28.9% at 50% 50%, rgba(231, 231, 231, 0.5) 0%, rgba(231, 231, 231, 0.97) 100%)',
                                height: '714px',
                                width: '492px',
                            }}
                        >
                            <CardContent>
                                <ScheduleSelector
                                    selectedDays={selectedDays}
                                    selectedTimes={selectedTimes}
                                    setSelectedDays={setSelectedDays}
                                    setSelectedTimes={setSelectedTimes}
                                />
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                p: 1,
                                backgroundColor: '#E7E7E7',
                                height: '99px',
                                width: '195px',
                            }}
                        >
                            <CardContent padding="10px 20px">
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                >
                                    <img src={InfoIcon} alt="" />
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            fontFamily: 'Red Hat Display',
                                        }}
                                    >
                                        Which days / time of the week can
                                        influencers visit?
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <WrapperStandardTextField md={12} lg={12}>
                    <LoadingButton
                        variant="contained"
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#272727',
                            border: '1px solid #272727',
                            textTransform: 'capitalize',
                            mt: '20px',
                            mr: '20px',
                            borderRadius: '5px',
                            padding: '0.5rem 3.7rem',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                            },
                        }}
                        onClick={() => setIndex((prev) => prev - 1)}
                    >
                        Back
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        sx={{
                            backgroundColor: '#272727',
                            color: '#FFFFFF',
                            textTransform: 'capitalize',
                            mt: '20px',
                            borderRadius: '5px',
                            padding: '0.5rem 3.7rem',
                            '&:hover': {
                                backgroundColor: '#272727',
                            },
                        }}
                    >
                        Next Step
                    </LoadingButton>
                </WrapperStandardTextField>
            </Grid>
        </>
    );
};

export default CampaignStep2;
