import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Grid } from '@mui/material';

export default function LineChart2() {
    const data = {
        labels: ['01', '02', '03', '04', '05', '06'],
        datasets: [
            {
                label: 'Spendings',
                data: [10, 20, 15, 30, 40, 25],
                borderColor: '#E6E8EC',
                backgroundColor: '#E6E8EC',
                pointRadius: 0,
                borderWidth: 3,
            },
            {
                label: 'Engagement',
                data: [5, 15, 10, 25, 35, 20],
                borderColor: '#5A6ACF',
                backgroundColor: '#5A6ACF',
                pointRadius: 0,
                borderWidth: 3,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Weeks',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                title: {
                    display: false,
                    text: 'Values',
                },
            },
        },
    };
    return <Line data={data} options={options} />;
}
