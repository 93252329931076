import { Avatar, Box, Grid } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dev from "../../services/axios-client";
import Pagination from "../Pagination";
import PaginationWrapper from "../Wrapper/PaginationWrapper";
import TransactionStatus from "./TransactionStatus";
import { enUSMediumDateFormat } from "../../services/helpers";
import { Person as PersonIcon } from "@mui/icons-material";

function createData(name, date, amount, status) {
  return { name, date, amount, status };
}

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const bodyColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  fontFamily: "'IBM Plex Sans'",
  color: "#212529",
  textAlign: "left",
  padding: { xs: "5px 8px", md: "8px 10px", lg: "10px 16px" },
};

function TransactionHistoryTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState({
    page: 1,
    per_page: 10,
    total: 0,
  });

  const handlePageChange = async (page) => {
    setPage((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const fetchTransactionHistory = async () => {
    try {
      const response = await dev.get(
        `/payments?page=${page.page}&per_page=${page.per_page}`
      );

      const formattedRows = response.data.data.map((item) => {
        const name = item.merchant ? item.merchant.name : item.user?.name;
        const paymentAmount =
          item.paymentType === "Payout"
            ? `$ ${item.amount}`
            : `- $ ${item.amount}`;
        const transactionType =
          item.paymentType === "Payout" ? "Cash In" : "Cash Out";
        const status = item.paymentType === "Payout" ? 1 : 0;

        return createData(
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnGap={"10px"}
          >
            <Avatar
              alt={"avt"}
              src={item.avatarUrl ?? undefined}
              sx={{ width: "50px", height: "50px" }}
            >
              {!item.avatarUrl && <PersonIcon />}
            </Avatar>
            <Box>{name}</Box>
          </Grid>,
          enUSMediumDateFormat(item.createdAt),
          paymentAmount,
          <TransactionStatus label={transactionType} status={status} icon={1} />
        );
      });
      setRows([...formattedRows]);

      setPage({
        page: response.data.page,
        per_page: response.data.per_page,
        total: response.data.total,
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchTransactionHistory();
  }, [page.page, user.token]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "1px solid #E9EDF4",
              },
            }}
          >
            <TableRow>
              <TableCell sx={headerColumnStyle}>Name</TableCell>
              <TableCell sx={headerColumnStyle}>Date</TableCell>
              <TableCell sx={headerColumnStyle}>Amount</TableCell>
              <TableCell sx={headerColumnStyle}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableRow sx={{ height: "20px" }}></TableRow>
            {rows.length > 0 ? (
              rows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={bodyColumnStyle}>{row.name}</TableCell>
                  <TableCell sx={bodyColumnStyle}>{row.date}</TableCell>
                  <TableCell
                    sx={{
                      ...bodyColumnStyle,
                      fontWeight: 700,
                      color: row.amount.startsWith("-") ? "#E94E51" : "#01AB3B",
                    }}
                  >
                    {row.amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...bodyColumnStyle,
                      textAlign: "center",
                    }}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper border={"#E9EDF4"} pt={20} pb={210} mt={22}>
        <Pagination
          currentPage={page?.page}
          pageSize={page?.per_page}
          totalUsers={page?.total}
          onPageChange={handlePageChange}
        />
      </PaginationWrapper>
    </>
  );
}

export default TransactionHistoryTable;
