import { USDollarFormatter, enUSMediumDateFormatAmPM } from './helpers';
import globeIcon from '../assets/dashboard/d-glob-icon.png';
import walletIcon from '../assets/dashboard/d-wallet-icon.png';
import cartIcon from '../assets/dashboard/d-cart-icon.png';
import fileIcon from '../assets/dashboard/d-document-icon.png';

const createData = (name, platforms, budget, completion) => {
    return { name, platforms, budget, completion };
};

const getGradient = (ctx) => {
    let gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, '#D4FF02');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    return gradient;
};

export const getCardsData = (data) => {
    return [
        {
            title: 'Total Reach Till Date',
            value: data?.totalReach.toLocaleString('en-US'),
            icon: globeIcon,
        },
        {
            title: 'Total Spend',
            value: USDollarFormatter().format(data?.totalSpent),
            icon: walletIcon,
        },
        {
            title: 'Reach per $1',
            value: data?.reachPerOne.toLocaleString('en-US'),
            icon: cartIcon,
        },
        {
            title: 'MID',
            value: data?.merchantId,
            icon: fileIcon,
        },
    ];
};

export const getNotificationsData = (data) => {
    console.log('Data service', data);
    if (!data || !data.notifications) {
        return [];
    }

    const formattedNotifications = data.notifications.map((notification) => {
        const date = new Date(notification.createdAt);
        const formattedDate = enUSMediumDateFormatAmPM(date);

        return {
            title: notification.title,
            date: formattedDate,
        };
    });

    return formattedNotifications;
};

export const getRecentCampaignsData = (data) => {
    if (!data || !data.recentCampaigns) {
        return [];
    }

    const processedData = data.recentCampaigns
        .map((campaign) => {
            const platforms = (
                typeof campaign.platforms === 'string'
                    ? JSON.parse(campaign.platforms)
                    : campaign.platforms || []
            ).map((platform) => platform.toLowerCase());
            const budget = campaign.budget
                ? USDollarFormatter().format(campaign.budget)
                : null;
            return createData(
                campaign.title,
                platforms,
                budget,
                campaign.completion,
            );
        })
        .sort((a, b) => (a.name < b.name ? -1 : 1));

    return processedData;
};

export const generateLineChartData = (data) => {
    return {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        datasets: [
            {
                data: data?.engagement.map((d) => d.total_engagement),
                border: 'none',
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx } = chart;
                    return getGradient(ctx);
                },
                fill: true,
                borderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBorderColor: '#01AB3B',
            },
        ],
    };
};

export const generateColumnChartData = (data) => {
    const influencerData = data?.influencersByCity || [];
    const cities = influencerData.map((item) => item.city);
    const influencerCounts = influencerData.map((item) => item.influencerCount);

    return {
        labels: cities,
        datasets: [
            {
                data: influencerCounts,
                borderColor: '#E6EEF5',
                backgroundColor: '#E6EEF5',
                hoverBackgroundColor: '#D4FF02',
                borderRadius: 10,
            },
        ],
    };
};
