import React, { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Image } from 'antd';
import UploadIcon from '../../assets/Upload.png';
import dev from '../../services/axios-client';

const ImageUpload = ({ image, setImage, uploading, setUploading }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            dev.post('/upload', formData, {
                headers: {
                    token: user.token,
                },
            })
                .then((res) => {
                    setImage(res?.data?.url);
                    setUploading(false);
                })
                .catch(() => {
                    setImage(null);
                    setUploading(false);
                });
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
    };

    return (
        <>
            {uploading ? (
                <h1>Uploading...</h1>
            ) : image ? (
                <Box
                    sx={{ position: 'relative', width: '100%', height: '100%' }}
                >
                    <Image
                        preview
                        src={image}
                        alt="Uploaded"
                        style={{
                            width: 100,
                            height: 100,
                            objectFit: 'cover',
                            borderRadius: '8px',
                        }}
                    />
                    <IconButton
                        onClick={handleRemoveImage}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            ) : (
                <Box
                    sx={{
                        border: '2px dashed #B9B9B9',
                        borderRadius: '5px',
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <Box
                        textAlign="center"
                        display="flex"
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            sx={{
                                color: '#808080',
                                fontWeight: 400,
                                fontSize: '14px',
                                fontFamily: 'Red Hat Display',
                            }}
                        >
                            Campaign Thumbnail
                        </Typography>
                        <img alt="" src={UploadIcon} />
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                mt: 2,
                                backgroundColor: '#D4FF02',
                                color: '#000',
                                borderRadius: '3.83px',
                                border: '1px solid #000000',
                                '&:hover': {
                                    backgroundColor: '#D4FF02',
                                },
                            }}
                        >
                            Upload Thumbnail
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ImageUpload;
