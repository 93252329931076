import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Divider,
    Grid,
    InputAdornment,
    OutlinedInput,
    Tooltip,
    debounce,
} from '@mui/material';
import Loader from '../../Loader';
import Table from '@mui/material/Table';
import Pagination from '../../Pagination';
import TableRow from '@mui/material/TableRow';
import HeaderText from '../../Text/HeaderText';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import dev from '../../../services/axios-client';
import AccectSelect from '../../Input/AccectSelect';
import share_icon from '../../../assets/share_icon.png';
import WarningDialog from '../../Popups/WarningDialog';
import HeaderWrapper from '../../Wrapper/HeaderWrapper';
import TableContainer from '@mui/material/TableContainer';
import delete_icon from '../../../assets/delete_icon.png';
import search_light from '../../../assets/Search_light.png';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import PaginationWrapper from '../../Wrapper/PaginationWrapper';
import FormRejectionDialog from '../../Popups/formRejectionDialog';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

function createData(id, platform, link, action_select, action_btns) {
    return { id, platform, link, action_select, action_btns };
}

const headerColumnStyle = {
    fontSize: '14px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontFamily: "'IBM Plex Sans'",
    color: '#2e3138',
    textAlign: 'left',
    marginBottom: '5px',
    padding: { xs: '8px 8px', md: '10px 10px', lg: '16px 16px' },
};

const bodyColumnStyle = {
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: "'IBM Plex Sans'",
    color: '#212529',
    textAlign: 'left',
    wordWrap: 'break-word',
    padding: { xs: '5px 8px', md: '8px 10px', lg: '10px 16px' },
};

const options = [
    {
        value: 1,
        label: 'Approved',
        bcolor: '#DAF9E4',
        color: '#01AB3B',
    },
    {
        value: 0,
        label: 'Declined',
        bcolor: '#FFDFE1',
        color: '#E94E51',
    },
];

function ContentApproval() {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState({});
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [modal, setModal] = React.useState({ open: false });
    const [formModal, setFormModal] = React.useState({ open: false });
    const [selectedDocument, setSelectedDocument] = React.useState({});
    const [disaprovalReason, setDisaprovalReason] = React.useState(true);
    const [responseModal, setResponseModal] = React.useState({ open: false });

    const handleClose = (flag) => {
        if (flag) {
            handleDeleteDocument(selectedDocument);
        }
        setModal({ ...modal, open: false });
    };
    const handleResponseClose = () => {
        setResponseModal({ ...responseModal, open: false });
    };

    const handleOpen = (doc) => {
        setSelectedDocument(doc);
        setModal({
            open: true,
            title: 'Are you sure to delete this Influencer Record?',
            cancelBtn: 'Cancel',
            submitBtn: 'Delete',
        });
    };

    const handleClickOpen = (e, email) => {
        if (e.value === 0)
            setFormModal({
                open: true,
                title: 'Send Disapproval Reason',
                error: true,
                email: email,
            });
        if (e.value === 1) {
            ApproveUser({ email: email });
        }
    };

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);
            ApproveUser({
                email: formModal.email,
                reason: reason,
                isApproved: false,
            });
            setFormModal({ ...modal, open: false });
        }
    };

    React.useEffect(() => {
        getAllDocuments();
        //eslint-disable-next-line
    }, [search]);

    const getAllDocuments = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                `/admin/campaigns/contents?page=${page}&per_page=10${
                    search.length ? `&search=${search.toLowerCase()}` : ''
                }`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    const data = response.data.data.map((data) =>
                        createData(
                            data.campaignId,
                            data.platform,
                            data.platformLink,
                            <AccectSelect
                                options={options}
                                defaultValue={
                                    data.isApproved ? options[0] : options[1]
                                }
                                onChange={(e) => handleClickOpen(e, data.email)}
                            />,
                            <Grid Container direction="row" alignItems="center">
                                <Tooltip title="View Details" placement="top">
                                    <img
                                        alt=""
                                        src={share_icon}
                                        style={{
                                            cursor: 'pointer',
                                            marginRight: '20px',
                                        }}
                                        onClick={() =>
                                            navigate(
                                                `/content-approval-detail/${data?.campaignId}`,
                                            )
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <img
                                        alt=""
                                        src={delete_icon}
                                        onClick={() => handleOpen(data)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Grid>,
                        ),
                    );
                    setRows(data);
                    setPage(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handlePageChange = async (page) => {
        await getAllDocuments(page);
    };

    const handleDeleteDocument = async (doc) => {
        setLoading(true);
        console.log({ doc });
        await dev
            .delete(`/admin/content/delete`, {
                data: { contentId: doc.id },
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    getAllDocuments(page?.page);
                    setResponseModal({
                        open: true,
                        title: 'Content deleted successfully',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete Content',
                });
            });
    };

    const ApproveUser = async (data) => {
        await dev
            .put(`/admin/approveUser`, data, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                getAllDocuments(page?.page);
                if (response.data) {
                }
            })
            .catch((error) => console.log(error));
    };
    const handleSearch = (e) => setSearch(e.target.value);

    return (
        <>
            <FormRejectionDialog
                hasReason={disaprovalReason}
                modal={formModal}
                onClose={handleFormClose}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleClose} />
            <Loader loading={loading} />
            {/* {rows.length > 0 && ( */}
            <>
                <Grid container spacing={2} sx={{ paddingY: 3 }}>
                    <Grid item xs={12} sm={3}>
                        <HeaderText
                            style={{
                                fontFamily: 'IBM Plex Sans',
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '22px',
                            }}
                            text="Content Approval"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={9}
                        display={'flex'}
                        justifyContent={'flex-end'}
                    >
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={'text'}
                            onChange={debounce(handleSearch, 500)}
                            sx={{
                                width: '242px',
                                height: '46px',
                                border: '1px solid #E9EDF4',
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <img
                                        alt=""
                                        src={search_light}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </InputAdornment>
                            }
                            placeholder="search"
                        />
                    </Grid>
                </Grid>
                <Divider />
            </>
            {/* )} */}
            <TableContainer>
                <Table>
                    <TableHead
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: '1px solid #E9EDF4',
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell sx={headerColumnStyle}>
                                Campaign ID
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Platform Name
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Platform Link
                            </TableCell>
                            <TableCell
                                sx={{
                                    ...headerColumnStyle,
                                    textAlign: 'center',
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        {rows?.map((row, index) => (
                            <TableRow key={row.index}>
                                <TableCell sx={bodyColumnStyle}>
                                    {row.id}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '100px',
                                    }}
                                >
                                    {row.platform}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '100px',
                                    }}
                                >
                                    {row.link}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {row.action_btns}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <PaginationWrapper border={'#E9EDF4'} pt={20} pb={210} mt={22}>
                <Pagination
                    currentPage={page?.page}
                    pageSize={page?.per_page}
                    totalUsers={page?.total}
                    onPageChange={handlePageChange}
                />
            </PaginationWrapper>
        </>
    );
}

export default ContentApproval;
