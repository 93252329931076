import {
    Box,
    Grid,
    Button,
    Avatar,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import WarningDialog from '../../Popups/WarningDialog';
import Loader from '../../Loader';

import SubHeaderText from '../../Text/SubHeaderText';
import styled from 'styled-components';
import dev from '../../../services/axios-client';
import HeaderWrapper from '../../Wrapper/HeaderWrapper';
import HeaderText from '../../Text/HeaderText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Red Hat Display',
}));

const CustomTypography = ({
    title,
    color = '#000000',
    mb = 0,
    fontWeight = 700,
    fontSize = '18px',
}) => {
    return (
        <SubHeaderText
            text={title}
            color={color}
            customstyle={{
                mb: mb,
                fontWeight: fontWeight,
                fontSize: fontSize,
                fontFamily: 'Red Hat Display',
            }}
        />
    );
};

const ViewCampaignDetails = () => {
    let { campaignId } = useParams();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [navi, setNavi] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [modal, setModal] = useState({ open: false });
    const [responseModal, setResponseModal] = useState({ open: false });
    const [formModal, setFormModal] = useState({ open: false });
    const [disaprovalReason, setDisaprovalReason] = useState(true);

    const timeSlots = campaign?.slots?.map((slot) => slot?.time);

    const navigate = useNavigate();
    useEffect(() => {
        getCampaign();
    }, []);

    const capitalizedFirstChar = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleClose = (flag) => {
        if (flag) {
            handleCampaignDelete(selectedCampaign);
        }
        setModal({ ...modal, open: false });
    };
    const handleResponseClose = () => {
        setResponseModal({ open: false });
        if (navi) navigate('/view-campaigns');
    };

    const handleOpen = (id) => {
        setNavi(true);
        setSelectedCampaign(id);
        setModal({
            open: true,
            title: 'Are you sure to delete this Campaign?',
            cancelBtn: 'Cancel',
            submitBtn: 'Delete',
        });
    };

    const handleCampaignDelete = (id) => {
        setLoading(true);
        dev.delete(`/campaign/${id}`, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: 'Merchant deleted successfully',
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete merchant',
                });
            });
    };

    const getCampaign = async () => {
        setLoading(true);
        await dev
            .get(`/campaign/single/${campaignId}`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data.data) {
                    setCampaign(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handleClickOpen = (value) => {
        setFormModal({
            open: true,
            title: 'Send Disapproval Reason',
            error: true,
        });
    };

    const rejectCampaign = async (data) => {
        await dev
            .put(
                `/campaign/reject/${campaignId}?reason=${data?.disapprovalReason}`,
                {},
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setNavi(true);
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const ApproveCampaign = async () => {
        await dev
            .put(
                `/campaign/approve/${campaignId}`,
                {},
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setNavi(true);
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);

            rejectCampaign({
                disapprovalReason: reason,
            });
            setFormModal({ open: false });
            navigate('/view-campaigns');
        }
    };

    return (
        <Grid container sx={{ height: '100%', width: '100%' }}>
            <FormRejectionDialog
                modal={formModal}
                onClose={handleFormClose}
                hasReason={disaprovalReason}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleClose} />
            <Loader loading={loading} />
            <HeaderWrapper
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <HeaderText
                    text="Campaign Details"
                    color="#000000"
                    style={{
                        fontFamily: 'Red Hat Display',
                        fontWeight: 700,
                        fontSize: '32px',
                        lineHeight: '32px',
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: 'Red Hat Display',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '21.17px',
                        display: 'flex',
                    }}
                >
                    Campaign ID:
                    <Typography
                        sx={{
                            fontWeight: 300,
                            color: '#272727',
                        }}
                    >
                        {campaign?.id}
                    </Typography>
                </Typography>
            </HeaderWrapper>
            <Box sx={{ width: '100%' }} mt={2}>
                <Box sx={{ marginInline: '150px' }}>
                    {campaign?.isActive === 4 && (
                        <Box display={'flex'} justifyContent={'center'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap="10px"
                                width={'878px'}
                                height={'76px'}
                                borderRadius={'10px'}
                                padding={'6px 10.5px 6px 10.5px'}
                                sx={{
                                    background: '#FFE7E7',
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    gap="5px"
                                    justifyContent={'center'}
                                    alignItems={'start'}
                                >
                                    <InfoOutlinedIcon
                                        sx={{
                                            fontSize: '14px',
                                            color: '#DB0000',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: '#DB0000',
                                            fontFamily: 'Red Hat Display',
                                        }}
                                    >
                                        Requires Changes
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Red Hat Display',
                                    }}
                                >
                                    We have made some changes to the campaign
                                    according to our guidelines. Kindly review
                                    and approve to publish the campaign
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={'10px'}
                    >
                        <Box sx={{ width: '100%', mt: '20px' }}>
                            <CustomTypography title="Campaign Details" mb={2} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Campaign Id
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Campaign Title
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Age
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Gender
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.id}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.title}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {Array.isArray(
                                                        campaign?.age,
                                                    )
                                                        ? campaign?.age?.join(
                                                              ',',
                                                          )
                                                        : campaign?.age}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.gender}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Content Category
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Enrollment Type
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Platform(s)
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Approval Type
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.influencerContentCategory?.join(
                                                        ',',
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.campaignType}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.platforms?.join(
                                                        ',',
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {campaign?.approvalType ===
                                                    1
                                                        ? 'Instant Enrollment'
                                                        : 'Manual Approval'}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Campaign Type" mb={2} />

                            <Box>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Campaign Type
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.paymentType}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Influencer Deal" mb={2} />

                            <Box>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Influencer Offered
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Product Value
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Guaranteed Content
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.product_details}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    AED{' '}
                                                    {
                                                        campaign?.compensation_value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Stories
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography title="Visit Location" mb={2} />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                {campaign?.locations?.map((location, index) => (
                                    <TableContainer
                                        key={index}
                                        component={Paper}
                                        sx={{
                                            boxShadow: 'none',
                                            border: '1px solid #E9EDF4',
                                            borderBottom: 'none',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        Visiting Location
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        Days
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '18px',
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        {location?.address}
                                                    </CustomTableCell>
                                                    <CustomTableCell
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '18px',
                                                            fontFamily:
                                                                'Red Hat Display',
                                                        }}
                                                    >
                                                        mon, tue
                                                    </CustomTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Time Slots
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {timeSlots?.[0]}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography
                                title="Hashtags & Description"
                                mb={2}
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Hashtags
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell>
                                                    {campaign?.hashtagbyadmin ? (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize:
                                                                        '18px',
                                                                    fontFamily:
                                                                        'Red Hat Display',
                                                                    textDecoration:
                                                                        'line-through',
                                                                    color: '#DB0000',
                                                                    mb: '20px',
                                                                }}
                                                            >
                                                                {campaign?.hashtags?.join(
                                                                    ',',
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize:
                                                                        '18px',
                                                                    fontFamily:
                                                                        'Red Hat Display',
                                                                    color: campaign?.hashtagbyadmin
                                                                        ? '#01AB3B'
                                                                        : '#000000',
                                                                }}
                                                            >
                                                                {campaign?.hashtagbyadmin?.join(
                                                                    ',',
                                                                )}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 500,
                                                                fontSize:
                                                                    '18px',
                                                                fontFamily:
                                                                    'Red Hat Display',
                                                                color: campaign?.hashtagbyadmin
                                                                    ? '#01AB3B'
                                                                    : '#000000',
                                                            }}
                                                        >
                                                            {campaign?.hashtags?.join(
                                                                ',',
                                                            )}
                                                        </Typography>
                                                    )}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: 'none',
                                        border: '1px solid #E9EDF4',
                                        borderBottom: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    Description
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <CustomTableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '18px',
                                                        fontFamily:
                                                            'Red Hat Display',
                                                    }}
                                                >
                                                    {campaign?.description}
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                    {campaign?.isActive !== 0 && (
                        <Box
                            display={'flex'}
                            gap={'10px'}
                            justifyContent={'center'}
                            mt={2}
                            sx={{
                                width: { xs: '150px', md: 'auto' },
                                marginRight: { xs: '10px', md: 0 },
                            }}
                        >
                            <PrimaryBtn
                                fontStyle={{
                                    fontFamily: 'Red Hat Display',
                                    fontWeight: 400,
                                    fontSize: '17.39px',
                                    lineHeight: '26.09px',
                                }}
                                text="Approve"
                                onClick={ApproveCampaign}
                                bgColor="#01AB3B"
                                style={{
                                    width: '136px',
                                    height: '41px',
                                    border: '1px solid #8D8D8D',
                                }}
                            />
                            <PrimaryBtn
                                fontStyle={{
                                    fontFamily: 'Red Hat Display',
                                    fontWeight: 400,
                                    fontSize: '17.39px',
                                    lineHeight: '26.09px',
                                }}
                                text="Reject"
                                onClick={() => handleClickOpen(0)}
                                bgColor="#E94E51"
                                style={{ width: '136px', height: '41px' }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default ViewCampaignDetails;
