import { Table as MUITable, TableCell as MUITableCell } from "@mui/material";
import styled from "styled-components";

export const Table = styled(MUITable)`
  border: 2px solid #ccc;
  border-radius: 8px;
`;

export const HeadTableCell = styled(MUITableCell)`
  font-family: IBM Plex Sans;
  font-size: 10px !important;
  line-height: 15px !important;
  color: #a0aec0 !important;
  padding: 1rem 0 !important;
  text-transform: uppercase;
`;

export const BodyTableCell = styled(MUITableCell)`
  padding: 1.5rem 0 !important;
  color: #2D3748 !important;
  font-size: 14px !important;
  font-family: IBM Plex Sans;
`;
