import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dev from "../../services/axios-client";
import Pagination from "../Pagination";
import PaginationWrapper from "../Wrapper/PaginationWrapper";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const bodyColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "400",
  fontFamily: "IBM Plex Sans",
  color: "#212529",
  textAlign: "left",
  padding: { xs: "5px 8px", md: "8px 10px", lg: "10px 16px" },
};

function AllBrandsTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [page, setPage] = React.useState({
    page: 1,
    per_page: 10,
    total: 0,
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    const getAllbrands = async () => {
      try {
        let url = `/merchant?page=${page.page}&per_page=${page.per_page}`;
        if (searchQuery) {
          url += `&search=${searchQuery}`;
        }
        const response = await dev.get(url, {
          headers: {
            token: user.token,
          },
        });
        setPage({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setRows([...response.data.data]);
      } catch (error) {
        console.log("Error: ", error.response?.data?.message || error.message);
      }
    };
    getAllbrands();
  }, [page.page, user.token, searchQuery]);

  const onSearchQuery = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const searchValue = formData.get("search");
    setSearchQuery(searchValue);
  };

  const handlePageChange = (page) => {
    setPage((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  return (
    <>
      <Grid container spacing={2} sx={{ paddingY: 3 }}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h4" sx={{ color: "#282F53", marginTop: "6px" }}>
            All Brands
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <form onSubmit={onSearchQuery}>
            <TextField
              name="search"
              fullWidth
              variant="outlined"
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
      </Grid>
      <TableContainer sx={{ paddingX: 0 }}>
        <Table>
          <TableHead
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderTop: "1px solid #E9EDF4",
                borderBottom: "1px solid #E9EDF4",
              },
            }}
          >
            <TableRow>
              <TableCell sx={headerColumnStyle}># SR.No</TableCell>
              <TableCell sx={headerColumnStyle}>NAME OF BRAND</TableCell>
              <TableCell sx={headerColumnStyle}>PENDING BALANCE</TableCell>
              <TableCell sx={headerColumnStyle}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableRow sx={{ height: "20px" }}></TableRow>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={bodyColumnStyle}>{row.id}</TableCell>
                  <TableCell sx={bodyColumnStyle}>{row.name}</TableCell>
                  <TableCell sx={{ ...bodyColumnStyle, fontWeight: 700 }}>
                    $ {row.balance}
                  </TableCell>
                  <TableCell sx={bodyColumnStyle}>
                    <PrimaryBtn
                      text="More Details"
                      onClick={() => navigate(`/merchant-details/${row.id}`)}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper border={"#E9EDF4"} pt={20} pb={210} mt={22}>
        <Pagination
          currentPage={page?.page}
          pageSize={page?.per_page}
          totalUsers={page?.total}
          onPageChange={handlePageChange}
        />
      </PaginationWrapper>
    </>
  );
}

export default AllBrandsTable;
