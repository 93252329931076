import {
    Box,
    Container,
    Divider,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Card, CardBody, CardHeading } from '../Dashboard/cards/dataCard';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VideoIcon from '../../assets/tiktok/video.png';
import Eye from '../../assets/tiktok/line-md_watch.png';
import ShareIcon from '../../assets/tiktok/share.png';
import MessageIcon from '../../assets/tiktok/message.png';
import TraficChart from './TraficChart';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AreaChart from './AreaChart';
import PerformanceChart from './PerformanceChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LineChart2 from '../Snapchat/LineChart2';
import SouthIcon from '@mui/icons-material/South';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import HeaderText from '../Text/HeaderText';

const IconStyle = {
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

export default function TikTok() {
    const [open, setOpen] = useState(false);
    const [timeRange, setTimeRange] = useState('This Month');

    return (
        <>
            <Grid item xs={12} mb={'20px'}>
                <HeaderWrapper>
                    <HeaderText
                        text="Tiktok Details"
                        color="#272727"
                        style={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '24px',
                            lineHeight: '36px',
                            letterSpacing: '-0.03em',
                        }}
                    />
                </HeaderWrapper>
            </Grid>
            <Grid
                xs={12}
                gap={'20px'}
                display={'flex'}
                alignItems={'end'}
                justifyContent={'space-between'}
                flexDirection={['column', null, 'row']}
            >
                <Grid xs={12} md={12} lg={8} display={'flex'} gap="20px">
                    <Grid display={'flex'} gap="20px" flexDirection={'column'}>
                        <CustomCard
                            value={'157,367'}
                            icon={VideoIcon}
                            color={'#0FAF62'}
                            iconBackground={'#5100F3'}
                            name={'VIDEO VIEWS'}
                            increaseText="6.7% Increase"
                        />
                        <CustomCard
                            value={'157,367'}
                            icon={ShareIcon}
                            color={'#E84646'}
                            iconBackground={'#F80022C9'}
                            name={'SHARES'}
                            increaseText="6.7% Increase"
                        />
                    </Grid>
                    <Grid
                        display={'flex'}
                        gap={'20px'}
                        flexDirection={'column'}
                    >
                        <CustomCard
                            value={'157,367'}
                            icon={MessageIcon}
                            color={'#0FAF62'}
                            iconBackground={'#F84C02'}
                            name={'TOTAL COMMENTS'}
                            increaseText="6.7% Increase"
                        />
                        <CustomCard
                            value={'157,367'}
                            icon={Eye}
                            color={'#0FAF62'}
                            iconBackground={'#FDEDED'}
                            name={'AVERAGE WATCH TIME'}
                            increaseText="6.7% Increase"
                        />
                    </Grid>
                </Grid>
                <Grid xs={12} md={12} lg={4}>
                    <DailyTraficCard />
                </Grid>
            </Grid>
            <Grid container spacing={3} mt="20px">
                <Grid item xs={12} md={12} lg={7} sx={{ marginBottom: 2 }}>
                    <Grid
                        padding="30px 60px 40px 20px"
                        backgroundColor="#FFFFFF"
                        borderRadius={'18.69px'}
                        boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.1)"
                    >
                        <Typography
                            sx={{
                                color: '#05004E',
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '18.69px',
                                fontWeight: 600,
                            }}
                        >
                            Engagement Trends
                        </Typography>
                        <Grid>
                            <AreaChart />

                            <Divider
                                sx={{
                                    height: '1px',
                                    width: '368px',
                                    background: '#EDF2F6',
                                    marginY: 2,
                                }}
                            />
                            <Grid
                                display={'flex'}
                                justifyContent={'space-between'}
                            >
                                <BodyCard
                                    name="Followers"
                                    value="1234"
                                    bgColor="#0080DA"
                                    justifyContent="left"
                                />
                                <Divider
                                    sx={{
                                        height: '24px',
                                        width: '1px',
                                        background: '#BDC9D3',
                                    }}
                                />
                                <BodyCard
                                    name="Growths"
                                    value="1234"
                                    bgColor="#0080DA"
                                    justifyContent="center"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={5}
                    sx={{ marginBottom: 2 }}
                    height={'344px'}
                >
                    <Card padding="30px 20px">
                        <CardHeading>
                            <Typography
                                sx={{
                                    color: '#05004E',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '18.69px',
                                    fontWeight: 600,
                                }}
                            >
                                Content Performance
                            </Typography>
                        </CardHeading>
                        <Box>
                            <Box height={'230px'}>
                                <PerformanceChart />
                            </Box>
                            <Box
                                mt="20px"
                                display={'flex'}
                                justifyContent={'space-between'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap="20px"
                                >
                                    <ContentCard
                                        letter="a"
                                        letterColor={'#030202'}
                                        bgColor="#D4FF02"
                                        name="Dance"
                                    />
                                    <ContentCard
                                        letter="c"
                                        bgColor="#F80022"
                                        name="Tutorial"
                                    />
                                </Box>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap="20px"
                                >
                                    <ContentCard
                                        letter="b"
                                        bgColor="#F84C02"
                                        name="Challenge"
                                    />
                                    <ContentCard
                                        letter="d"
                                        bgColor="#5100F3"
                                        name="Singing"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="10px 20px">
                        <CardHeading>
                            <Grid
                                display="flex"
                                justifyContent="space-between"
                                alignItems={'center'}
                            >
                                <Grid
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap="8px"
                                >
                                    <Typography
                                        sx={{
                                            fonFamily: 'Plus Jakarta Sans',
                                            fontSize: '17px',
                                            fontWeight: 600,
                                            color: '#000000',
                                        }}
                                    >
                                        Screenshot of post
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Plus Jakarta Sans',
                                            fontSize: '20px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        2.568
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#737B8B',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            letterSpacing: '0.5px',
                                        }}
                                    >
                                        <SouthIcon
                                            sx={{
                                                color: '#F2383A',
                                                fontSize: '12px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fonFamily: 'Poppins',
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                color: '#F2383A',
                                            }}
                                        >
                                            2.1%
                                        </Typography>
                                        vs last week
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '13px',
                                            fontWeight: 400,
                                            color: '#000000',
                                            opacity: 0.5,
                                        }}
                                    >
                                        Sales from 1-6 Dec, 2020
                                    </Typography>
                                </Grid>

                                <Select
                                    IconComponent={(props) => (
                                        <KeyboardArrowUpIcon
                                            sx={{
                                                fontSize: '16px',
                                                transition: 'transform 1s',
                                                transform: open
                                                    ? 'rotate(180deg)'
                                                    : 'rotate(0deg)',
                                            }}
                                            {...props}
                                        />
                                    )}
                                    sx={{
                                        width: '140px',
                                        height: '47.52px',
                                        borderRadius: '12px',
                                        fontSize: '12px',
                                        color: 'white',
                                        backgroundColor: '#007AFF',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '12px',
                                        },
                                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderRadius: '12px',
                                            },
                                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderRadius: '12px',
                                            },
                                        '.MuiSvgIcon-root ': {
                                            fill: 'white !important',
                                        },
                                    }}
                                    value={timeRange}
                                    onChange={(e) =>
                                        setTimeRange(e.target.value)
                                    }
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                >
                                    <MenuItem value="This Week">
                                        This Week
                                    </MenuItem>
                                    <MenuItem value="This Month">
                                        This Month
                                    </MenuItem>
                                    <MenuItem value="This Year">
                                        This Year
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </CardHeading>
                        <CardBody>
                            <LineChart2 />
                            <Box display={'flex'} gap="50px" mt="10px">
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9px',
                                            height: '9px',
                                            backgroundColor: '#5A6ACF',
                                            borderRadius: '50%',
                                            border: '1px solid #5A6ACF',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Last 6 days
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9px',
                                            height: '9px',
                                            backgroundColor: '#D8D9DB',
                                            borderRadius: '50%',
                                            border: '1px solid #D8D9DB',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Last week
                                    </Typography>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

const CustomCard = ({
    icon,
    iconBackground,
    color,
    name,
    value,
    increaseText,
}) => {
    return (
        <Box
            padding="30px 20px"
            minWidth="372px"
            maxHeight="148px"
            borderRadius={'8px'}
            boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.1)"
        >
            <CardBody>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontFamily: 'Public Sans',
                                fontWeight: 500,
                                fontSize: '12px',
                            }}
                            color={'#626C70'}
                        >
                            {name}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Public Sans',
                                fontWeight: 600,
                                fontSize: '24px',
                            }}
                            color={'#191B1C'}
                        >
                            {value}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: color,
                                mt: '20px',
                            }}
                        >
                            <ArrowUpwardIcon fontSize="small" />
                            <Typography variant="body2" sx={{ ml: 0.5 }}>
                                {increaseText}
                            </Typography>
                        </Box>
                    </Box>

                    <div
                        style={{
                            ...IconStyle,
                            backgroundColor: iconBackground,
                        }}
                    >
                        <img src={icon} alt="insta" />
                    </div>
                </Box>
            </CardBody>
        </Box>
    );
};

const DailyTraficCard = () => {
    return (
        <Box
            padding="30px 20px"
            minWidth="322px"
            maxHeight="295px"
            borderRadius={'17.1px'}
            boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.1)"
        >
            <CardHeading>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    sx={{
                        fontFamily: 'DM Sans',
                        fontSize: '11.97px',
                        fontWeight: 500,
                        lineHeight: '20.52px',
                        letterSpacing: '-0.02em',
                        color: '#A3AED0',
                    }}
                >
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography>Daily trafic</Typography>
                        <Typography
                            sx={{
                                color: '#2B3674',
                                fontWeight: 700,
                                fontSize: '29.07px',
                                display: 'flex',
                                alignItems: 'baseline',
                            }}
                        >
                            2.66
                            <Typography
                                sx={{
                                    color: '#A3AED0',
                                    fontWeight: 500,
                                    fontSize: '11.97px',
                                    pl: '1px',
                                }}
                            >
                                Visitors
                            </Typography>
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '10.26px',
                            fontWeight: 700,
                            lineHeight: '17.1px',
                            color: '#05CD99',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowDropUpIcon />
                        +2.45%
                    </Typography>
                </Box>
            </CardHeading>
            <CardBody padding="30px 20px">
                <TraficChart />
            </CardBody>
        </Box>
    );
};

const BodyCard = ({ name, value, bgColor, justifyContent }) => {
    return (
        <Grid display={'flex'} gap="10px" justifyContent={justifyContent}>
            <Typography
                sx={{
                    width: '19.85px',
                    height: '6.4px',
                    mt: '5px',
                    backgroundColor: bgColor,
                    borderRadius: '10.24px',
                    borderBlock: `1px solid ${bgColor}`,
                }}
            ></Typography>
            <Grid item xs={6} sm={3} width="90px">
                <Typography
                    sx={{
                        fonFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#7A8699',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
};

const ContentCard = ({
    bgColor = '#D4FF02',
    name = 'hassan',
    letter,
    letterColor = '#FFFFFF',
}) => {
    return (
        <Grid display={'flex'} gap="10px">
            <div
                style={{
                    ...IconStyle,
                    width: '26px',
                    height: '26px',
                    borderRadius: '4px',
                    fontFamily: 'Manrope',
                    fontWeight: 600,
                    fontSize: '12px',
                    backgroundColor: bgColor,
                    color: letterColor,
                }}
            >
                {letter}
            </div>
            <Grid item xs={6} sm={3} width="90px">
                <Typography
                    sx={{
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#0B0B0B',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
};
