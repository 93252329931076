import paypal_logo from '../assets/paypal_logo.png';
import stripe_logo from '../assets/stripe_logo.png';
import logos_mastercard from '../assets/logos_mastercard.png';
import { GetCountries, GetLanguages } from 'react-country-state-city';

const onKeyDownNumber = (event) => {
    const allowedChars = /^[0-9]+$/;
    if (!allowedChars.test(event.key) && event.keyCode !== 8) {
        event.preventDefault();
    }
};

const onKeyDownText = (event) => {
    const allowedChars = /^[A-Za-z]+$/;
    if (
        !allowedChars.test(event.key) &&
        event.keyCode !== 8 &&
        event.keyCode !== 32
    ) {
        event.preventDefault();
    }
};

const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    // Define months mapping
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    // Extract date components
    const year = dateObject.getFullYear();
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();

    // Format the output string
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
};

const genderOptions = [
    {
        value: 'Male',
        label: 'Male',
    },
    {
        value: 'Female',
        label: 'Female',
    },
    {
        value: 'Both',
        label: 'Both',
    },
];

const socailOptions = [
    {
        value: 'Facebook',
        label: 'Facebook',
    },
    {
        value: 'Instagram',
        label: 'Instagram',
    },
    {
        value: 'Tiktok',
        label: 'Tiktok',
    },
];

const paymentMethodOptions = [
    {
        value: 'Bank Transfer',
        label: 'Bank Transfer',
    },
    {
        value: 'Cheque',
        label: 'Cheque',
    },
];

const dealTypeOptions = [
    {
        value: 'Discount',
        label: 'Discount',
    },
    {
        value: 'Bogo',
        label: 'Bogo',
    },
];

const dealsOptions = [
    {
        value: 1,
        label: '1 Deals Available',
    },
    {
        value: 2,
        label: '2 Deals Available',
    },
    {
        value: 3,
        label: '3 Deals Available',
    },
    {
        value: 4,
        label: '4 Deals Available',
    },
    {
        value: 5,
        label: '5 Deals Available',
    },
    {
        value: 6,
        label: '6 Deals Available',
    },
    {
        value: 7,
        label: '7 Deals Available',
    },
    {
        value: 8,
        label: '8 Deals Available',
    },
    {
        value: 9,
        label: '9 Deals Available',
    },
    {
        value: 10,
        label: '10 Deals Available',
    },
];

const followersOptions = [
    {
        value: 10000,
        label: 'Min 10,000 Followers',
    },
    {
        value: 20000,
        label: 'Min 20,000 Followers',
    },
];

const miniPayoutOptions = [
    {
        value: 10000,
        label: '$10000',
    },
    {
        value: 20000,
        label: '$20000',
    },
    {
        value: 30000,
        label: '$30000',
    },
    {
        value: 40000,
        label: '$40000',
    },
    {
        value: 50000,
        label: '$50000',
    },
    {
        value: 60000,
        label: '$60000',
    },
];

const LicensedOptions = [
    {
        value: true,
        label: 'Licensed',
    },
    {
        value: false,
        label: 'Not licensed',
    },
];

const ContentCategoryOptions = [
    { value: 'Art & Design', label: 'Art & Design' },
    { value: 'Automobiles', label: 'Automobiles' },
    { value: 'Beauty & Hygiene', label: 'Beauty & Hygiene' },
    { value: 'Books & Writing', label: 'Books & Writing' },
    {
        value: 'Business & Entrepreneurship',
        label: 'Business & Entrepreneurship',
    },
    { value: 'Cinema & Entertainment', label: 'Cinema & Entertainment' },
    { value: 'Family & Relationships', label: 'Family & Relationships' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Food & Beverages', label: 'Food & Beverages' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Health & Fitness', label: 'Health & Fitness' },
    { value: 'Home & Lifestyle', label: 'Home & Lifestyle' },
    { value: 'Learning & Education', label: 'Learning & Education' },
    { value: 'Infotainment', label: 'Infotainment' },
    { value: 'Vloggers & Bloggers', label: 'Vloggers & Bloggers' },
    { value: 'Politics', label: 'Politics' },
    { value: 'Pet & Animals', label: 'Pet & Animals' },
    { value: 'Non-Profits', label: 'Non-Profits' },
    { value: 'Fan Pages', label: 'Fan Pages' },
    { value: 'Motivational', label: 'Motivational' },
    { value: 'Music & Dance', label: 'Music & Dance' },
    { value: 'News & Journalism', label: 'News & Journalism' },
    { value: 'Parenting', label: 'Parenting' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Religion & Spirituality', label: 'Religion & Spirituality' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Travel & Adventure', label: 'Travel & Adventure' },
];

const PlatformsCategoryOptions = [
    {
        value: 1,
        label: 'Instagram',
    },
    {
        value: 2,
        label: 'Tiktok',
    },
    {
        value: 3,
        label: 'Snapchat',
    },
];

const ContentNeededOptions = [
    {
        value: 'story',
        label: 'Stories',
    },
    {
        value: 'reel',
        label: 'Reel',
    },
    {
        value: 'post',
        label: 'Posts',
    },
];

const CampaignTypeOptions = [
    {
        value: 'Barter',
        label: 'Barter',
    },
    {
        value: 'Paid',
        label: 'Paid',
    },
];

const PaymentOptions = [
    {
        value: 'Bank Transfer',
        label: 'Bank Transfer',
    },
    {
        value: 'Payment Through Link',
        label: 'Payment Through Link',
    },
    {
        value: 'Online Payment',
        label: 'Online Payment',
    },
];

const ageOptions = [
    {
        value: '18-24',
        label: '18-24',
    },
    {
        value: '25-34',
        label: '25-34',
    },
    {
        value: '35-44',
        label: '35-44',
    },
    {
        value: '45-54',
        label: '45-54',
    },
    {
        value: '55-64',
        label: '55-64',
    },
    {
        value: '65+',
        label: '65+',
    },
];
const payOptions = [
    {
        value: 1,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={paypal_logo} width="20px" />{' '}
                <span style={{ marginLeft: '10px' }}>Paypal</span>
            </div>
        ),
    },
    {
        value: 2,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={stripe_logo} width="20px" />{' '}
                <span style={{ marginLeft: '10px' }}>Stripe</span>
            </div>
        ),
    },
    {
        value: 3,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logos_mastercard} width="20px" />{' '}
                <span style={{ marginLeft: '10px' }}>Mastercard</span>
            </div>
        ),
    },
];

const LanguageOptions = await GetLanguages().then((result) => {
    const arr = result.map((language) => {
        return { label: language.name, value: language.name };
    });
    return [{ label: 'English(Default)', value: 'English' }, ...arr];
});

export default {
    onKeyDownNumber,
    onKeyDownText,
    formatDate,
    payOptions,
    LanguageOptions,
    genderOptions,
    ageOptions,
    followersOptions,
    socailOptions,
    dealsOptions,
    paymentMethodOptions,
    dealTypeOptions,
    LicensedOptions,
    ContentCategoryOptions,
    miniPayoutOptions,
    PlatformsCategoryOptions,
    ContentNeededOptions,
    CampaignTypeOptions,
    PaymentOptions,
};
