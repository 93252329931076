import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import CircleArrow from '../../assets/detailcampaign/circle-arrow.png';
import SnapchatIcon from '../../assets/detailcampaign/Snapchat.png';
import InstagramIcon from '../../assets/detailcampaign/Instagram.png';
import TikTokIcon from '../../assets/detailcampaign/Tiktok.png';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import HeaderText from '../Text/HeaderText';
import PerformanceGraph from './PerformanceGraphs';
import Profile from '../../assets/detailcampaign/profile.png';
import Verify from '../../assets/detailcampaign/verify.png';
import TopCards from './TopCards';
import { useEffect, useState } from 'react';
import dev from '../../services/axios-client';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';

const IconStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #F1F4EF',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

export default function CampaignDetails() {
    let { campaignId } = useParams();
    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState({});

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        getCampaign();
    }, []);

    const getCampaign = async () => {
        setLoading(true);
        await dev
            .get(`/api/v1/campaign/single/${campaignId}`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data) {
                    setCampaign(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Loader loading={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TopCards campaign={campaign} />
                </Grid>
            </Grid>
            <Grid item xs={12} marginY={2}>
                <HeaderWrapper>
                    <HeaderText
                        text="Performance Graphs"
                        color="#272727"
                        style={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '24px',
                            lineHeight: '36px',
                            letterSpacing: '-0.03em',
                        }}
                    />
                </HeaderWrapper>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PerformanceGraph campaign={campaign} />
                </Grid>
            </Grid>
            <Grid item xs={12} marginY={2}>
                <HeaderWrapper>
                    <HeaderText
                        text="Influencers Enrolled"
                        color="#272727"
                        style={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '24px',
                            lineHeight: '36px',
                            letterSpacing: '-0.03em',
                        }}
                    />
                </HeaderWrapper>
            </Grid>
            <Grid item xs={12}>
                <InfluencerSection campaign={campaign} />
            </Grid>
        </Box>
    );
}

const InfluencerCard = ({ influencer }) => (
    <Card
        sx={{
            width: ['100%', '250px'],
            height: '369px',
            borderRadius: '12px',
        }}
    >
        <CardContent>
            <Box display="flex" justifyContent="center" mb={1}>
                <img
                    alt={influencer.name}
                    src={influencer.profile ? influencer.profile : Profile}
                    width={204}
                    height={123}
                    style={{ borderRadius: '12.08px' }}
                />
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'baseline'}
            >
                <Box>
                    <Typography
                        sx={{
                            fonFamily: 'Plus Jakarta Sans',
                            fontSize: '14.49px',
                            fontWeight: 600,
                        }}
                        textAlign="center"
                        display={'flex'}
                        alignItems={'center'}
                    >
                        {influencer.name}{' '}
                        <img src={Verify} alt="" width={12.08} height={12.08} />
                    </Typography>
                    <Typography
                        sx={{
                            mb: 1,
                            fonFamily: 'Plus Jakarta Sans',
                            fontSize: '11.27px',
                            fontWeight: 600,
                        }}
                        color="#00000080"
                        textAlign="center"
                    >
                        {influencer.city}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fonFamily: 'Plus Jakarta Sans',
                            fontSize: '19.29px',
                            fontWeight: 600,
                        }}
                        textAlign="center"
                    >
                        {influencer.followers}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        textAlign="center"
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '10.47px',
                            fontWeight: 500,
                        }}
                    >
                        Followers
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ mb: 1 }} />
            <Typography
                color="#808080"
                sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '9.666px',
                    fontWeight: 400,
                }}
            >
                Enrollment Date:
                <Typography
                    color="#808080"
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9.666px',
                        fontWeight: 400,
                    }}
                >
                    {influencer.date}
                </Typography>
            </Typography>
            <Typography
                color="#808080"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '9.666px',
                    fontWeight: 400,
                }}
            >
                Enrollment Time:
                <Typography
                    color="#808080"
                    sx={{
                        mb: 1,
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9.666px',
                        fontWeight: 400,
                    }}
                >
                    {influencer.time}
                </Typography>
            </Typography>

            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography
                    color="#808080"
                    sx={{
                        mb: 1,
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9.666px',
                        fontWeight: 400,
                    }}
                >
                    Type of enrollment:
                </Typography>

                <Button
                    sx={{
                        mb: 1,
                        width: '71.65px',
                        height: '20.13px',
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '7.25px',
                        fontWeight: 400,
                        background: '#C3E5FE',
                        borderRadius: '10.06px',
                        color: '#090909',
                        border: '1px solid white',
                        textTransform: 'none',
                        cursor: 'inherit',
                        ':hover': {
                            border: '1px solid white',
                            color: '#090909',
                            background: '#C3E5FE',
                        },
                    }}
                >
                    {influencer.type}
                </Button>
            </Box>

            <Box display={'flex'} justifyContent={'space-between'}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={'10px'}
                >
                    {influencer?.platforms?.includes('snapchat') && (
                        <Box style={IconStyle}>
                            <img
                                src={SnapchatIcon}
                                alt={''}
                                width={10}
                                height={10}
                            />
                        </Box>
                    )}
                    {influencer?.platforms?.includes('instagram') && (
                        <Box style={IconStyle}>
                            <img
                                src={InstagramIcon}
                                alt={''}
                                width={10}
                                height={10}
                            />
                        </Box>
                    )}
                    {influencer?.platforms?.includes('tiktok') && (
                        <Box style={IconStyle}>
                            <img
                                src={TikTokIcon}
                                alt={''}
                                width={10}
                                height={10}
                            />
                        </Box>
                    )}
                </Box>
                <Button
                    sx={{
                        width: '71.65px',
                        height: '20.13px',
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '7.25px',
                        fontWeight: 400,
                        background: '#FDEEED',
                        borderRadius: '10.06px',
                        color: '#F46665',
                        border: '1px solid white',
                        textTransform: 'none',
                        cursor: 'inherit',
                        ':hover': {
                            border: '1px solid white',
                            color: '#F46665',
                            background: '#FDEEED',
                        },
                    }}
                >
                    status: pending
                </Button>
            </Box>

            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography
                    color="#808080"
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '9.666px',
                        fontWeight: 400,
                    }}
                >
                    Advertising price
                </Typography>

                <Typography
                    variant="h6"
                    textAlign="center"
                    color="#000000"
                    sx={{
                        mb: 1,
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '15.3px',
                        fontWeight: 600,
                    }}
                >
                    AED {influencer.price}
                </Typography>
            </Box>

            <Box width={'100%'} sx={{ textAlign: 'right' }}>
                <Button
                    sx={{
                        width: '92.59px',
                        height: '24.15px',
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '7.25px',
                        fontWeight: 500,
                        background: '#D3F26A',
                        borderRadius: '10.06px',
                        color: '#090909',
                        border: '1px solid white',
                        textTransform: 'none',
                        ':hover': {
                            border: '1px solid white',
                            color: '#090909',
                            background: '#D3F26A',
                        },
                    }}
                    // onClick={() => {
                    //     influencer?.profile &&
                    //         window.open(influencer?.profile, '_blank');
                    // }}
                >
                    View Profile
                </Button>
            </Box>
        </CardContent>
    </Card>
);

const InfluencerSection = ({ campaign = [] }) => {
    return (
        <Box sx={{ mt: '20px' }}>
            <Grid container sx={{ display: 'flex', gap: '20px' }}>
                {campaign?.influencers?.map((influencer, index) => (
                    <InfluencerCard key={index} influencer={influencer} />
                ))}
            </Grid>
        </Box>
    );
};
