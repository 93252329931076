import React from "react";
import {
  Modal,
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Divider,
} from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import invoiceLogo from "../../assets/logo_following_uae.jpg";
import { capitalize } from "../../services/helpers";

const AddressInfo = ({ logoSrc }) => (
  <Box>
    <img src={logoSrc} alt="Logo" style={{ width: 200 }} />
    <Box sx={{ mt: 3 }}>
      <Typography variant="body2" fontSize="16px" gutterBottom>
        Business Center, Sharjah Publishing City Free Zone, Sharjah, United Arab
        Emirates
      </Typography>
    </Box>
  </Box>
);

const InvoiceInfo = ({ title, data }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    {data.map(({ label, value }) => (
      <Typography variant="subtitle1" gutterBottom key={label}>
        {label}{" "}
        <span style={{ fontWeight: 600, marginLeft: 5 }}>{value || "N/A"}</span>
      </Typography>
    ))}
  </Box>
);

const SubscriptionInfo = ({ subscriptionData }) => (
  <Box display="flex" flexDirection="column" marginTop={2}>
    <Typography variant="body2" fontSize="16px" fontWeight="600" gutterBottom>
      Subscription
    </Typography>
    {Object.entries(subscriptionData).map(([label, value]) => (
      <Typography variant="subtitle1" gutterBottom key={label}>
        {label}{" "}
        <span style={{ fontWeight: 600, marginLeft: 5 }}>{value || "N/A"}</span>
      </Typography>
    ))}
  </Box>
);

const InvoiceTemplateModal = ({ invoiceData, open, onClose }) => {
  const file = invoiceData?.fileName ?? "download.pdf";

  const tableHeaderStyle = {
    color: "#000",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
  };

  const downloadInvoiceHandler = () => {
    const pages = document.querySelectorAll("#invoice");
    const pdf = new jsPDF("portrait");

    pages.forEach((page, index) => {
      html2canvas(page, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        if (index < pages.length - 1) {
          pdf.addPage();
        } else {
          pdf.save(file);
        }
      });
    });

    onClose();
  };

  const convertDate = (date) => {
    const inputDate = new Date(date);

    const options = {
      year: "2-digit",
      month: "short",
      day: "2-digit",
    };

    const formattedDate = inputDate.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: 24,
          p: 4,
          width: { sm: "50%", xs: "90%" },
        }}
      >
        <div id="invoice" style={{ padding: "2rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AddressInfo logoSrc={invoiceLogo} />
              <Divider sx={{ maxWidth: 100, borderWidth: "2px" }} />
              <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
                <Typography
                  variant="body2"
                  fontSize="16px"
                  fontWeight="600"
                  gutterBottom
                >
                  Billed To
                </Typography>
                <Typography variant="body2" fontSize="16px" gutterBottom>
                  {invoiceData.user?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box ml={{ xs: 0, sm: 8 }}>
                <InvoiceInfo
                  title="Invoice"
                  data={[
                    { label: "Invoice #", value: invoiceData.invoiceId },
                    {
                      label: "Invoice Date",
                      value: convertDate(invoiceData.invoiceDate),
                    },
                    {
                      label: "Invoice Amount",
                      value: invoiceData.totalAmount
                        ? `$ ${invoiceData.totalAmount}`
                        : null,
                    },
                    { label: "Customer ID", value: invoiceData.user?.id },
                  ]}
                />
                <Divider sx={{ maxWidth: 100, borderWidth: "2px" }} />
                <SubscriptionInfo
                  subscriptionData={{
                    ID: invoiceData.suscriptionId,
                    "Billing Period": invoiceData.billingPeriod,
                    "Next Billing Date": invoiceData.nextBillingDate,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} sx={tableHeaderStyle}>
                  Description
                </TableCell>
                <TableCell sx={tableHeaderStyle}>Units</TableCell>
                <TableCell sx={tableHeaderStyle}>Unit Price</TableCell>
                <TableCell sx={tableHeaderStyle}>Amount (USD)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.items?.map((service, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={3}>
                    {capitalize(service?.platform)}
                  </TableCell>
                  <TableCell>{service?.categories}</TableCell>
                  <TableCell>$ {service?.pricePerCategory}</TableCell>
                  <TableCell>$ {service?.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Total{" "}
              <span style={{ marginLeft: "30px" }}>
                {invoiceData.totalAmount
                  ? `$${invoiceData.totalAmount}`
                  : "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Payments{" "}
              <span style={{ marginLeft: "20px" }}>
                {invoiceData.totalAmount
                  ? `($${invoiceData.totalAmount})`
                  : "N/A"}
              </span>
            </Typography>
          </Box>
        </div>
        <Grid item xs={12} px={4}>
          <PrimaryBtn text="Close" bgColor="#FF4321" onClick={onClose}>
            Close
          </PrimaryBtn>
          <PrimaryBtn
            text="Download Invoice"
            onClick={downloadInvoiceHandler}
            style={{ ml: 1 }}
          >
            Download Invoice
          </PrimaryBtn>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InvoiceTemplateModal;
