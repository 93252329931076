import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../Pagination";
import PaginationWrapper from "../Wrapper/PaginationWrapper";
import ViewIcon from "../../assets/share_icon.png";
import DownloadIcon from "../../assets/download-icon-dark.png";
import DeleteIcon from "../../assets/delete_icon.png";
import InvoiceDeleteModal from "./InvoiceDeleteModal";
import InvoiceTemplateModal from "./InvoiceTemplate";
import dev from "../../services/axios-client";

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const bodyColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  fontFamily: "'IBM Plex Sans'",
  color: "#212529",
  textAlign: "left",
  padding: { xs: "5px 8px", md: "8px 10px", lg: "10px 16px" },
};

const initialRows = [
  {
    date: "06 July 2023",
    name: "Joan Powell",
    invoice_no: "T-123456789",
    amount: "120",
    services: [
      { date: "2024-03-01", description: "Service 1", rate: 50, quantity: 2 },
      { date: "2024-03-05", description: "Service 2", rate: 60, quantity: 3 },
    ],
  },
  {
    date: "06 July 2023",
    name: "Joan Powell",
    invoice_no: "T-123456789",
    amount: "120",
    services: [
      { date: "2024-03-01", description: "Service 1", rate: 50, quantity: 2 },
      { date: "2024-03-05", description: "Service 2", rate: 60, quantity: 3 },
    ],
  },
  {
    date: "06 July 2023",
    name: "Joan Powell",
    invoice_no: "T-123456789",
    amount: "120",
    services: [
      { date: "2024-03-01", description: "Service 1", rate: 50, quantity: 2 },
      { date: "2024-03-05", description: "Service 2", rate: 60, quantity: 3 },
    ],
  },
];

function InvoicesTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState({
    page: 1,
    per_page: 10,
    total: 0,
  });
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [openInvoiceModal, setOpenInvoiceModal] = React.useState(false);

  const convertDate = (date) => {
    const inputDate = new Date(date);

    const options = {
      year: "2-digit",
      month: "short",
      day: "2-digit",
    };

    const formattedDate = inputDate.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const getAllInvoices = async () => {
    try {
      let url = `/admin/invoice/all?page=${page.page}&per_page=${page.per_page}`;
      const response = await dev.get(url);
      setPage({
        page: response.data.page,
        per_page: response.data.per_page,
        total: response.data.total,
      });
      setRows([...response.data.data]);
    } catch (error) {
      console.log("Error: ", error.response?.data?.message || error.message);
    }
  };

  React.useEffect(() => {
    getAllInvoices();
  }, [page.page]);

  const handlePageChange = async (page) => {
    console.log("Page", page);
  };

  const handleButtonClick = (action, row) => {
    switch (action) {
      case "view":
      case "download":
        setSelectedInvoice(row);
        setOpenInvoiceModal(true);
        break;
      case "delete":
        setSelectedInvoice(row);
        setOpenDeleteModal(true);
        break;

      default:
        console.log("Invalid action");
    }
  };

  return (
    <>
      {openDeleteModal && (
        <InvoiceDeleteModal
          showModal={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          invoiceID={selectedInvoice.id}
          getAllInvoices={getAllInvoices}
        />
      )}

      <TableContainer>
        <Table>
          <TableHead
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "1px solid #E9EDF4",
              },
            }}
          >
            <TableRow>
              <TableCell sx={headerColumnStyle}>Date</TableCell>
              <TableCell sx={headerColumnStyle}>Name</TableCell>
              <TableCell sx={headerColumnStyle}>Invoice Number</TableCell>
              <TableCell sx={headerColumnStyle}>Amount</TableCell>
              <TableCell sx={headerColumnStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableRow sx={{ height: "20px" }}></TableRow>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={bodyColumnStyle}>
                  {convertDate(row.invoiceDate)}
                </TableCell>
                <TableCell sx={bodyColumnStyle}>{row.user?.name}</TableCell>
                <TableCell sx={bodyColumnStyle}>{row.invoiceId}</TableCell>
                <TableCell sx={{ ...bodyColumnStyle, fontWeight: 700 }}>
                  {row.totalAmount ? `$ ${row.totalAmount}` : "N/A"}
                </TableCell>
                <TableCell sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{
                      width: { sm: "auto", xs: "11px" },
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={ViewIcon}
                      alt="View"
                      style={{
                        width: "inherit",
                        cursor: "pointer",
                        marginTop: "2px",
                      }}
                      onClick={() => handleButtonClick("view", row)}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { sm: "auto", xs: "14px" },
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      alt="Download"
                      style={{ width: "inherit", cursor: "pointer" }}
                      onClick={() => handleButtonClick("download", row)}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { sm: "auto", xs: "14px" },
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      style={{ width: "inherit", cursor: "pointer" }}
                      onClick={() => handleButtonClick("delete", row)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper border={"#E9EDF4"} pt={20} pb={210} mt={22}>
        <Pagination
          currentPage={page?.page}
          pageSize={page?.per_page}
          totalUsers={page?.total}
          onPageChange={handlePageChange}
        />
      </PaginationWrapper>

      {selectedInvoice && (
        <InvoiceTemplateModal
          open={openInvoiceModal}
          onClose={() => setOpenInvoiceModal(false)}
          invoiceData={selectedInvoice}
        />
      )}
    </>
  );
}

export default InvoicesTable;
