import { Grid, InputBase, Typography } from '@mui/material';
const InfoLabel = ({
    label = 'text',
    value = 'value',
    color = '#212529',
    onChange = null,
    name = null,
    showLabel = true,
    suffix,
    renderSuffix = false,
    fontSize = '14px',
    fontStyle = {},
    mt = '20px',
    xs = '12',
}) => {
    return (
        <Grid xs="4" mt={mt}>
            <Typography
                component={'span'}
                sx={{
                    fontSize: fontSize,
                    textAlign: 'left',
                    lineHeight: '22px',
                    fontWeight: '500',
                    fontFamily: "'IBM Plex Sans'",
                    color: color,
                    ...fontStyle,
                }}
            >
                {showLabel && <span>{`${label}: `}</span>}{' '}
                <b>{!onChange && value}</b>
            </Typography>
            {onChange && (
                <InputBase
                    value={value}
                    name={name}
                    onChange={onChange}
                    sx={{
                        pl: '5px',
                        fontSize: '14px',
                        textAlign: 'left',
                        lineHeight: '22px',
                        fontWeight: '600',
                        fontFamily: "'IBM Plex Sans'",
                        color: color,
                        border: ' 1px solid #E9EDF4',
                        borderRadius: '7px',
                    }}
                    placeholder={label}
                    type={'text'}
                    inputProps={{ 'aria-label': 'ID' }}
                />
            )}
            {renderSuffix && (
                <span
                    style={{
                        marginLeft: '5px',
                        fontSize: '18px',
                        textAlign: 'left',
                        lineHeight: '22px',
                        fontWeight: '600',
                        fontFamily: "'IBM Plex Sans'",
                        color: color,
                    }}
                >
                    {suffix}
                </span>
            )}
        </Grid>
    );
};

export default InfoLabel;
