import { Box, Grid, InputBase, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import sc_icon from '../../assets/sc_icon.png';
import CustomSelect from '../Input/CustomSelect';
import BarterIcon from '../../assets/giftbox.png';
import SubHeaderText from '../Text/SubHeaderText';
import tt_icon from '../../assets/tiktok-color-icon.png';
import ig_icon from '../../assets/ig-instagram-icon.png';
import SheildIcon from '../../assets/Chield_check_light.png';
import PaidCampaignIcon from '../../assets/paidcampaigns.png';
import LockIcon from '../../assets/Lock_alt.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomizedInputsStyled from '../Text/StandardTextField';
import validationService from '../../services/validationService';
import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { useFormData } from '../../services/formDataContext';
import { useEffect } from 'react';

const CampaignStep1 = ({
    onChangeHandler,
    errors,
    register,
    setRequiredFields,
    setPayoutType,
    payoutType,
    socialMediaChannels,
    setSocialMediaChannels,
    getValues,
    gender,
    setGender,
    approvalType,
    setApprovalType,
    campaignType,
    setCampaignType,
}) => {
    useEffect(() => {
        setRequiredFields([
            'age',
            'gender',
            'title',
            'instagramhandle',
            'payoutType',
            'campaignType',
            'platforms',
        ]);
    }, []);
    const user = JSON.parse(localStorage.getItem('user'));
    const upgraded =
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA';

    const { formData } = useFormData();

    const handleSocialMediaChannels = (name) => {
        let temp = [];
        if (socialMediaChannels?.includes(name)) {
            temp = socialMediaChannels.filter((smc) => smc !== name);
            setSocialMediaChannels(temp);
        } else {
            if (socialMediaChannels?.length) {
                setSocialMediaChannels([...socialMediaChannels, name]);
            } else {
                setSocialMediaChannels([name]);
            }
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={10}>
                        <CustomizedInputsStyled
                            labelStyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            color="#000000"
                            height={'60px'}
                            required={true}
                            label="Campaign Title"
                            error={errors['title']}
                        >
                            <InputBase
                                fullWidth
                                sx={{
                                    ml: 2,
                                    flex: 1,
                                    color: '#808080',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                }}
                                placeholder={'Enter campaign title'}
                                type={'text'}
                                inputProps={{
                                    'aria-label': 'Title',
                                    maxLength: 55,
                                }}
                                required
                                error={!!errors['title']}
                                helperText={
                                    errors['title']
                                        ? errors['title'].message
                                        : ''
                                }
                                {...register('title')}
                            />
                        </CustomizedInputsStyled>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        text="Approval Type"
                        color="#000000"
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                        error={errors['approvalType']}
                        required={true}
                    />
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        mt="20px"
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="5px"
                            onClick={() => setApprovalType('instant')}
                        >
                            {approvalType === 'instant' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{
                                        color: '#000000',
                                        fontSize: '28px',
                                    }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{
                                        color: '#6A6A6A',
                                        fontSize: '28px',
                                    }}
                                />
                            )}
                            <SubHeaderText
                                text="Instant Enrollment"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="40px"
                            sx={{
                                opacity: !upgraded ? 0.5 : 1,
                                pointerEvents: !upgraded ? 'none' : 'auto',
                            }}
                            onClick={() => setApprovalType('manual')}
                        >
                            {approvalType === 'manual' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{
                                        color: '#000000',
                                        fontSize: '28px',
                                    }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{
                                        color: '#6A6A6A',
                                        fontSize: '28px',
                                    }}
                                />
                            )}
                            <SubHeaderText
                                text="Manual Approval"
                                color="#272727"
                                customstyle={{
                                    margin: '0px 3px 0px 5px',
                                }}
                            />
                        </Box>
                        {!upgraded && (
                            <Box
                                ml="10px"
                                gap="5px"
                                display="flex"
                                padding="7px 15px"
                                borderRadius="22.5px"
                                backgroundColor="#EAEAEA"
                                border="1px solid #EAEAEA"
                                cursor="pointer"
                            >
                                <img src={LockIcon} alt="" />
                                <Typography
                                    sx={{
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                    }}
                                >
                                    Upgrade to unlock
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={10}>
                        <SubHeaderText
                            text="Influencer Age *"
                            color="#000000"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['age']}
                        />
                        <CustomSelect
                            defaultValue={formData?.age}
                            multi
                            onChange={onChangeHandler}
                            placeholder="Select Age"
                            options={validationService.ageOptions}
                            name="age"
                            getValues={getValues}
                            height={'60px'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        text="Influencer Gender"
                        color="#000000"
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                        error={errors['gender']}
                        required={true}
                    />
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        mt="20px"
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="5px"
                            onClick={() => setGender('female')}
                        >
                            {gender === 'female' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{ color: '#000000', fontSize: '28px' }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{ color: '#6A6A6A', fontSize: '28px' }}
                                />
                            )}
                            <SubHeaderText
                                text="Female"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="40px"
                            onClick={() => setGender('male')}
                        >
                            {gender === 'male' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{ color: '#000000', fontSize: '28px' }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{ color: '#6A6A6A', fontSize: '28px' }}
                                />
                            )}
                            <SubHeaderText
                                text="Male"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="40px"
                            onClick={() => setGender('both')}
                        >
                            {gender === 'both' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{ color: '#000000', fontSize: '28px' }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{ color: '#6A6A6A', fontSize: '28px' }}
                                />
                            )}
                            <SubHeaderText
                                text="Both"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={10}>
                        <CustomizedInputsStyled
                            labelStyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            color="#000000"
                            height={'60px'}
                            required={true}
                            label="Instagram Handle"
                            error={errors['instagramhandle']}
                        >
                            <InputBase
                                fullWidth
                                sx={{
                                    ml: 2,
                                    flex: 1,
                                    color: '#808080',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                }}
                                placeholder={'@yourcompanyuser'}
                                type={'text'}
                                inputProps={{
                                    'aria-label': 'Title',
                                }}
                                required
                                error={!!errors['instagramhandle']}
                                helperText={
                                    errors['instagramhandle']
                                        ? errors['instagramhandle'].message
                                        : ''
                                }
                                {...register('instagramhandle')}
                            />
                        </CustomizedInputsStyled>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        text="Enrollment Type"
                        color="#000000"
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                        error={errors['payoutType']}
                        required={true}
                    />
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        mt="20px"
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="5px"
                            onClick={() => setPayoutType('In-store / Walk-in')}
                        >
                            {payoutType === 'In-store / Walk-in' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{ color: '#000000', fontSize: '28px' }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{ color: '#6A6A6A', fontSize: '28px' }}
                                />
                            )}
                            <SubHeaderText
                                text="In-store"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            ml="40px"
                            onClick={() => setPayoutType('PR Delivery')}
                        >
                            {payoutType === 'PR Delivery' ? (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{ color: '#000000', fontSize: '28px' }}
                                />
                            ) : (
                                <RadioButtonUncheckedRoundedIcon
                                    fontSize="large"
                                    sx={{ color: '#6A6A6A', fontSize: '28px' }}
                                />
                            )}
                            <SubHeaderText
                                text="PR Delivery"
                                color="#272727"
                                customstyle={{ margin: '0px 3px 0px 5px' }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <WrapperStandardTextField
                        lg={12}
                        style={{ paddingRight: '0px', paddingTop: '20px' }}
                    >
                        <SubHeaderText
                            text="Platforms"
                            color="#000000"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['platforms']}
                            required={true}
                        />
                        <SubHeaderText
                            text="Select Platforms for Campaign "
                            color="#000000"
                            customstyle={{
                                fontWeight: 400,
                                fontSize: '14px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['platforms']}
                            required={true}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            mt="20px"
                            columnGap={4}
                            rowGap={8}
                        >
                            <Box
                                onClick={() =>
                                    handleSocialMediaChannels('instagram')
                                }
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems="center"
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background: socialMediaChannels?.includes(
                                        'instagram',
                                    )
                                        ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                        : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '176px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={ig_icon} width={'60px'} alt="" />
                                    <SubHeaderText
                                        text="Instagram"
                                        color="#272727"
                                        customstyle={{
                                            lineHeight: '21px',
                                            fontWeight: 600,
                                        }}
                                    />
                                </Grid>
                            </Box>
                            <Box
                                onClick={() =>
                                    handleSocialMediaChannels('tiktok')
                                }
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems="center"
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background: socialMediaChannels?.includes(
                                        'tiktok',
                                    )
                                        ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                        : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '176px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={tt_icon} width={'60px'} alt="" />
                                    <SubHeaderText
                                        text="TikTok"
                                        color="#272727"
                                        customstyle={{
                                            lineHeight: '21px',
                                            fontWeight: 600,
                                        }}
                                    />
                                </Grid>
                            </Box>
                            <Box
                                onClick={() =>
                                    handleSocialMediaChannels('snapchat')
                                }
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems="center"
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background: socialMediaChannels?.includes(
                                        'snapchat',
                                    )
                                        ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                        : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '176px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={sc_icon} width={'60px'} alt="" />
                                    <SubHeaderText
                                        text="Snapchat"
                                        color="#272727"
                                        customstyle={{
                                            lineHeight: '21px',
                                            fontWeight: 600,
                                        }}
                                    />
                                </Grid>
                            </Box>
                            {/* <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                padding: '10px',
                                borderRadius: '20px',
                                backgroundColor: '#cecece',
                                border: '1px solid #e7e5e5',
                                boxSizing: 'border-box',
                                height: '176px',
                                width: '176px',
                            }}
                        >
                            <Tooltip title="This will be added in the near future">
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={in_icon} width={'60px'} alt="" />
                                    <SubHeaderText
                                        text="Linkedin"
                                        color="#272727"
                                        customstyle={{ fontWeight: 600 }}
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid> */}
                            {/* <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                padding: '10px',
                                borderRadius: '20px',
                                backgroundColor: '#cecece',
                                border: '1px solid #e7e5e5',
                                boxSizing: 'border-box',
                                height: '176px',
                                width: '176px',
                            }}
                        >
                            <Tooltip title="This will be added in the near future">
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={yt_icon} width={'60px'} alt="" />
                                    <SubHeaderText
                                        text="Youtube"
                                        color="#272727"
                                        customstyle={{ fontWeight: 600 }}
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid> */}
                        </Grid>
                    </WrapperStandardTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <WrapperStandardTextField
                        lg={12}
                        style={{
                            paddingRight: '0px',
                            paddingTop: '20px',
                            marginTop: '30px',
                        }}
                    >
                        <SubHeaderText
                            text="Campaign Type"
                            color="#272727"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['campaignType']}
                            required={true}
                        />
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            mt="20px"
                            columnGap={4}
                            rowGap={8}
                        >
                            <Box
                                display={'flex'}
                                alignItems="center"
                                flexDirection={'column'}
                                fontFamily={'Red Hat Display'}
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background:
                                        campaignType === 'barter'
                                            ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                            : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '273px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setCampaignType('barter')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 800,
                                        color: '#000000',
                                    }}
                                >
                                    Barter
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#000000',
                                    }}
                                >
                                    Included in subscription
                                </Typography>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={BarterIcon} alt="" />
                                </Grid>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#000000',
                                    }}
                                >
                                    Nano & Micro Influencers
                                </Typography>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems="center"
                                sx={{
                                    padding: '10px',
                                    borderRadius: '20px',
                                    background:
                                        campaignType === 'paid'
                                            ? 'linear-gradient(158.86deg, #E9FF60 6.82%, #D4FF02 87.88%)'
                                            : 'transparent',
                                    border: '1px solid #e7e5e5',
                                    boxSizing: 'border-box',
                                    height: '273px',
                                    width: '176px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setCampaignType('paid')}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 800,
                                        color: '#000000',
                                    }}
                                >
                                    Paid
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#000000',
                                    }}
                                >
                                    Hire larger creators
                                </Typography>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="7px"
                                >
                                    <img src={PaidCampaignIcon} alt="" />
                                </Grid>
                                <Box display={'flex'} mt={'20px'}>
                                    <img src={SheildIcon} alt="" />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            color: '#000000',
                                        }}
                                    >
                                        Pay per influencer
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </WrapperStandardTextField>
                </Grid>
                <WrapperStandardTextField md={12} lg={12}>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        sx={{
                            width: '361px',
                            backgroundColor: '#272727',
                            color: '#FFFFFF',
                            textTransform: 'capitalize',
                            mt: '30px',
                            padding: '0.5rem 3.7rem',
                            '&:hover': {
                                backgroundColor: '#272727',
                            },
                        }}
                    >
                        Next Step
                    </LoadingButton>
                </WrapperStandardTextField>
            </Grid>
        </>
    );
};

export default CampaignStep1;
