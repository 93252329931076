import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const roundedBarsPlugin = {
    id: 'roundedBarsPlugin',
    beforeDatasetsDraw(chart) {
        const {
            ctx,
            chartArea: { top, bottom },
            scales: { x, y },
        } = chart;
        chart.getDatasetMeta(0).data.forEach((bar, index) => {
            const barHeight = bar.y - bottom;
            const value = chart.data.datasets[0].data[index];
            const diffValue = chart.data.datasets[1].data[index];
            const xPos = bar.x;
            const yPos = bar.y;
            const width = bar.width;
            const radius = 10;

            // Draw the lower bar (actual data)
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(xPos - width / 2, yPos - diffValue);
            ctx.lineTo(xPos + width / 2, yPos - diffValue);
            ctx.arcTo(
                xPos + width / 2,
                yPos - diffValue + radius,
                xPos,
                yPos - diffValue + radius,
                radius,
            );
            ctx.arcTo(
                xPos - width / 2,
                yPos - diffValue + radius,
                xPos - width / 2,
                yPos - diffValue,
                radius,
            );
            ctx.lineTo(xPos - width / 2, bottom);
            ctx.arcTo(
                xPos - width / 2,
                bottom - radius,
                xPos,
                bottom - radius,
                radius,
            );
            ctx.arcTo(
                xPos + width / 2,
                bottom - radius,
                xPos + width / 2,
                bottom,
                radius,
            );
            ctx.lineTo(xPos + width / 2, yPos - diffValue);
            ctx.closePath();
            ctx.fillStyle = '#007AFF';
            ctx.fill();
            ctx.restore();

            // Draw the upper bar (difference to peak)
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(xPos - width / 2, yPos);
            ctx.lineTo(xPos + width / 2, yPos);
            ctx.arcTo(
                xPos + width / 2,
                yPos - radius,
                xPos,
                yPos - radius,
                radius,
            );
            ctx.arcTo(
                xPos - width / 2,
                yPos - radius,
                xPos - width / 2,
                yPos,
                radius,
            );
            ctx.lineTo(xPos - width / 2, top);
            ctx.lineTo(xPos + width / 2, top);
            ctx.closePath();
            ctx.fillStyle = '#F2F7FF';
            ctx.fill();
            ctx.restore();
        });
    },
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            display: false,
        },
        legend: {
            display: false,
        },
        roundedBarsPlugin,
    },
    scales: {
        x: {
            grid: {
                display: false,
                border: false,
            },
            stacked: true,
        },

        y: {
            beginAtZero: true,
            grid: {
                display: false,
                border: false,
            },
            ticks: {
                stepSize: 100,
            },
            stacked: true,
        },
    },
};

const ActivityChart = ({ activity = [] }) => {
    const dataValues = activity.length
        ? activity?.map((d) => +d?.totalFollowers)
        : [];
    const peakValue = Math.max(...dataValues);
    const differenceValues = dataValues?.map((value) => peakValue - value);
    const data = {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        datasets: [
            {
                label: '',
                backgroundColor: '#007AFF',
                borderColor: 'white',
                borderWidth: 1,
                borderRadius: 10,
                barPercentage: 0.6,
                data: dataValues,
            },
            {
                label: '',
                backgroundColor: '#F2F7FF',
                borderColor: 'white',
                borderWidth: 1,
                borderRadius: Number.MAX_VALUE,
                barPercentage: 0.6,
                data: differenceValues,
            },
        ],
    };
    return <Bar data={data} options={options} />;
};

export default ActivityChart;
