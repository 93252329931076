import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { useState } from "react";
import HeaderText from "../Text/HeaderText";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import { useNavigate } from "react-router-dom";
import WaitListTable from "./WaitListTable";
import MerchantsTable from "./MerchantsTable";
// import AllDocuments from "../AllDocuments";

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "500",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const activeHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#272727",
  textAlign: "center",
  border: "1px solid #E9EDF4",
  borderRadius: "5px 5px 0px 0px",
  borderCollapse: "null",
};

const nonActiveHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#74829C",
  textAlign: "center",
};

const ManageSupport = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const handleClick = () => navigate("/create-merchant-account");

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={12}>
        <HeaderText
          text="User Management"
          color="#272727"
          style={{
            fontWeight: 700,
            fontSize: "32px",
            lineHeight: "32px",
          }}
        />
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#FFFFFF",
          mt: "20px",
          borderRadius: "7px",
        }}
      >
        <TableContainer>
          <Table>
            <TableRow>
              <TableCell
                sx={active === 0 ? activeHeader : nonActiveHeader}
                style={{ width: "72px" }}
                onClick={() => setActive(0)}
              >
                Merchants
              </TableCell>
              <TableCell
                sx={active === 1 ? activeHeader : nonActiveHeader}
                style={{ width: "102px" }}
                onClick={() => setActive(1)}
              >
                Waitlist Users
              </TableCell>
              <TableCell
                style={{
                  gap: 10,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    color: "#808080",
                    background: "white",
                    border: "1px solid #808080",
                    textTransform: "unset",
                  }}
                  onClick={() => navigate("/all-documents")}
                  //   onClick={() => setActive(2)}
                >
                  View Documents List
                </Button>
                <PrimaryBtn
                  onClick={handleClick}
                  text="+ Create New Merchent"
                />
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        {active === 0 && <MerchantsTable />}
        {active === 1 && <WaitListTable />}
        {/* {active === 2 && <AllDocuments />} */}
      </Grid>
    </Grid>
  );
};

export default ManageSupport;
