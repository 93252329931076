import React from "react";
import { Card, LeftColumn, Title, SubTitle, Icon } from "./styled";

const StatCard = ({ title, value, icon }) => {
  return (
    <Card>
      <LeftColumn>
        <Title>{title}</Title>
        <SubTitle>{value}</SubTitle>
      </LeftColumn>
      <Icon src={icon} alt={`${title} icon`} />
    </Card>
  );
};

export default StatCard;
