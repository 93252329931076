import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import PrimaryBtn from "../CustomButton/PrimaryBtn";
import dev from "../../services/axios-client";

export default function UploadFileModal(props) {
  const {
    setModal,
    modal,
    requestData,
    setMsg,
    setResponseReceived,
    setIsError,
    setRequestData,
  } = props;
  const [file, setFile] = React.useState(null);

  const handleClose = () => {
    setModal(false);
    setFile(null);
  };

  const chooseFile = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleAction = async (endpoint) => {
    try {
      if (!file) {
        return setFile("Please select a file to upload.");
      }
      const formData = new FormData();
      formData.append("receipt", file);

      const response = await dev.post(
        `/payments/${requestData.id}/${endpoint}`,
        formData
      );
      setIsError(false);
      setMsg("Receipt is attached, Successfully");
      setResponseReceived(true);
      setRequestData(response.data);
      handleClose();
    } catch (error) {
      setIsError(true);
      setMsg(error.response?.data?.message || error.message);
      setResponseReceived(true);
      handleClose();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={modal}
      sx={{
        textAlign: "center",
        "& .MuiPaper-root": {
          borderRadius: "15px",
          px: { xs: 1.5, md: 3 },
          pb: { xs: 1.5, md: 3 },
          width: "430px",
        },
      }}
    >
      <DialogTitle sx={{ fontSize: { xs: "22px", md: "25px" } }}>
        Upload Transfer Slip
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            border: "2px dashed #7866e3",
            borderRadius: "5px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            mt: 2,
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <CloudUploadIcon sx={{ fontSize: "48px", color: "#7866e3" }} />
          <Typography
            variant="body1"
            fontSize={{ xs: "16px", md: "19px" }}
            my={2}
          >
            Drop your file here or <br />
            <label
              htmlFor="getFile"
              style={{ cursor: "pointer", color: "#fe8d57", fontWeight: "600" }}
            >
              Choose a file
              <input
                type="file"
                id="getFile"
                style={{ display: "none" }}
                onChange={chooseFile}
              />
            </label>
          </Typography>
        </Box>
        {file && (
          <Typography variant="body1" mt={3} color={file.name ? "#000" : "red"}>
            {file.name ? file.name : file}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <PrimaryBtn
          text={"Upload"}
          style={{ backgroundColor: "#7866e3 !important" }}
          fullWidth={true}
          onClick={() => handleAction("add/receipt")}
        />
        <PrimaryBtn text={"close"} fullWidth={true} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
