// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import CampaignGoalSummary from './CampaignGoalSummary';
import HeaderWrapper from '../../Components/Wrapper/HeaderWrapper';
import HeaderText from '../../Components/Text/HeaderText';
import CampaignTable from './CampaignTable';
import dev from '../../services/axios-client';
import Loader from '../Loader';

const labelStyle = {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20.52px',
    fontWeight: '700',
    lineHeight: '25.85px',
};

const Campaigns = () => {
    const [cardsData, setCardsData] = useState();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        getCardsData();
        //eslint-disable-next-line
    }, []);

    const getCardsData = async () => {
        setLoading(true);
        await dev
            .get('/api/v1/dashboard/cards', {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data) {
                    setCardsData(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Loader loading={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Summary data={cardsData} />
                </Grid>
                <Grid item xs={12}>
                    <HeaderWrapper>
                        <HeaderText
                            text="Common Features"
                            color="#272727"
                            style={{
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '32px',
                            }}
                        />
                    </HeaderWrapper>
                </Grid>

                <Grid item xs={12}>
                    <CampaignTable />
                </Grid>
                <Grid item xs={12}>
                    <HeaderWrapper>
                        <HeaderText
                            text="Campaign Goal Summary"
                            color="#272727"
                            style={{
                                fontWeight: 700,
                                fontSize: '18px',
                                lineHeight: '32px',
                            }}
                        />
                    </HeaderWrapper>
                </Grid>
                <CampaignGoalSummary />
            </Grid>
        </Box>
    );
};

const Summary = ({ data }) => (
    <Grid
        container
        sx={{
            display: 'flex',
            justifyContent: ['space-around', 'space-between'],
            gap: '20px',
            alignItems: 'end',
        }}
    >
        <Card
            sx={{
                background: '#D3F26A',
                minWidth: ['95%', '95%', '230px'],
                minHeight: ['112px', '112px', '92px'],
            }}
        >
            <CardContent>
                <Typography
                    sx={{ ...labelStyle, fontSize: ['26px', '24px', '20px'] }}
                >
                    {data?.totalCampaigns}
                </Typography>
                <Typography
                    sx={{
                        ...labelStyle,
                        fontSize: ['24px', '20px', '16px'],
                        mt: '10px',
                    }}
                >
                    Total campaigns
                </Typography>
            </CardContent>
        </Card>
        <Card
            sx={{
                background: '#F84C02',
                minWidth: ['95%', '95%', '230px'],
                minHeight: ['112px', '112px', '92px'],
            }}
        >
            <CardContent>
                <Typography
                    sx={{
                        ...labelStyle,
                        fontSize: ['26px', '24px', '20px'],
                        color: 'white',
                    }}
                >
                    {data?.activeCampaigns}
                </Typography>
                <Typography
                    sx={{
                        ...labelStyle,
                        color: 'white',
                        fontSize: ['24px', '20px', '16px'],
                        mt: '10px',
                    }}
                >
                    Active campaigns
                </Typography>
            </CardContent>
        </Card>
        <Card
            sx={{
                background: '#5100F3',
                minWidth: ['95%', '95%', '230px'],
                minHeight: ['112px', '112px', '92px'],
            }}
        >
            <CardContent>
                <Typography
                    sx={{
                        ...labelStyle,
                        fontSize: ['26px', '24px', '20px'],
                        color: 'white',
                    }}
                >
                    {data?.pastCampaigns}
                </Typography>
                <Typography
                    sx={{
                        ...labelStyle,
                        color: 'white',
                        fontSize: ['24px', '20px', '16px'],
                        mt: '10px',
                    }}
                >
                    Past campaigns
                </Typography>
            </CardContent>
        </Card>
        <Box
            sx={{
                minWidth: ['95%', '95%', '387px'],
                minHeight: ['112px', '112px', '82px'],
                display: 'flex',
                gap: '20px',
                flexDirection: ['column', null, 'row'],
            }}
        >
            <Card
                sx={{
                    minWidth: ['95%', '95%', '193.5px'],
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            ...labelStyle,
                            fontSize: ['24px', '20px', '16px'],
                            fontWeight: 500,
                        }}
                    >
                        Total Spent
                    </Typography>
                    <Typography
                        sx={{
                            ...labelStyle,
                            fontSize: ['26px', '24px', '17.03px'],
                            mt: '2px',
                        }}
                    >
                        {data?.totalSpent} AED
                    </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    minWidth: ['90%', '95%', '193.5px'],
                    background: '#E9E9E9',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            ...labelStyle,
                            fontSize: ['24px', '20px', '16px'],
                            fontWeight: 500,
                        }}
                    >
                        Total Reach
                    </Typography>
                    <Typography
                        sx={{
                            ...labelStyle,
                            fontSize: ['26px', '24px', '17.03px'],
                            mt: '2px',
                        }}
                    >
                        {data?.totalReach}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

export default Campaigns;
