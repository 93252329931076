import React from 'react';
import { AntPagination } from './styles';

const itemRender = (style) => (_, type, originalElement) => {
    if (type === 'prev') {
        return <a> {style && '<<'} Prev</a>;
    }
    if (type === 'next') {
        return <a>Next {style && '>>'}</a>;
    }
    return originalElement;
};

export default function Pagination({
    currentPage,
    onPageChange,
    totalUsers,
    pageSize = 10,
    style,
}) {
    return (
        <AntPagination
            style={style}
            current={currentPage}
            total={totalUsers}
            pageSize={pageSize}
            itemRender={itemRender(style)}
            onChange={onPageChange}
            responsive={true}
        />
    );
}
