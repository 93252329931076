import { Avatar, Box, Grid } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
import * as React from "react";
import dev from "../../services/axios-client";
import SubHeaderText from "../Text/SubHeaderText";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import { enUSShortDate, enUSShortTime } from "../../services/helpers";

function Notifications() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState({
    page: 1,
    per_page: 10,
    total: 0,
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    console.log("get notifications");
    const getNotifications = async () => {
      try {
        let url = `/notifications?page=${page.page}&per_page=${page.per_page}`;
        const response = await dev.get(url, {
          headers: {
            token: user.token,
          },
        });
        setPage({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setRows((prevRows) => [...prevRows, ...response.data.data]);
      } catch (error) {
        console.log("Error: ", error.response?.data?.message || error.message);
      }
      setIsLoading(false);
      setIsFetching(false);
    };
    getNotifications();
  }, [page.page]);

  const handlePageChange = (e) => {
    e.preventDefault();

    setPage((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
    setIsFetching(true);
  };

  return (
    <Grid container mt="45px">
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          {rows.map((row, i) => (
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <Grid xs="1">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  padding={"0px 28px"}
                >
                  <Box>
                    <SubHeaderText
                      text={enUSShortDate(row.createdAt)}
                      color="#8F8FB1"
                      customstyle={{
                        fontWeight: 600,
                        fontSize: "13px",
                        textAlign: "right",
                      }}
                    />
                    <SubHeaderText
                      text={enUSShortTime(row.createdAt)}
                      color="#8F8FB1"
                      customstyle={{
                        fontWeight: 600,
                        fontSize: "21px",
                        textAlign: "right",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid xs="auto">
                <Box
                  sx={{
                    borderRadius: "9px",
                    backgroundColor: "#fff",
                    border: "3px solid #0E3228",
                    boxSizing: "border-box",
                    width: "18px",
                    height: "18px",
                    marginRight: "-11px",
                  }}
                ></Box>
              </Grid>
              <Grid
                xs="10"
                sx={{
                  borderLeft: "4px solid rgba(108, 95, 252, 0.2)",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  columnGap={"12px"}
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #E7E5E5",
                    backgroundColor: "#fff",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.03)",
                    width: "100%",
                    textAlign: "left",
                    fontSize: "15px",
                    color: "#100f15",
                    marginLeft: "45px",
                    padding: "14px 20px",
                  }}
                >
                  <Avatar
                    alt={"avt"}
                    src={
                      row.user
                        ? row.user.avatarUrl
                        : row.merchant
                        ? row.merchant.avatarUrl
                        : null
                    }
                    variant="square"
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "7px",
                    }}
                  >
                    {(!row.user || !row.user.avatarUrl) &&
                      (!row.merchant || !row.merchant.avatarUrl) && (
                        <PersonIcon />
                      )}
                  </Avatar>
                  <Box flexGrow={2}>
                    <SubHeaderText
                      text="Dennis Trexy"
                      color="#100F15"
                      customstyle={{
                        fontSize: "13px",
                      }}
                    />
                    <SubHeaderText text={row.activity_description} />
                  </Box>
                  <Box flexGrow={1}>
                    <SubHeaderText
                      text="2 Hrs ago"
                      color="#74829C"
                      customstyle={{
                        float: "right",
                        fontSize: "11px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {rows.length - 1 != i && (
                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                >
                  <Grid xs="1"></Grid>
                  <Grid xs="auto">
                    <Box
                      sx={{
                        borderLeft: "4px solid rgba(108, 95, 252, 0.2)",
                        height: "30px",
                        width: "80%",
                        marginLeft: "7px",
                      }}
                    ></Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
          {isFetching && (
            <Grid item xs={12}>
              <Box textAlign="center" mt={2}>Loading more...</Box>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              margin: "25px 0px 60px",
            }}
          >
            <PrimaryBtn
              text="Load more"
              style={{ padding: "7px 32px" }}
              onClick={(e) => handlePageChange(e)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Notifications;
