import React from "react";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";

const LineChart = ({ data }) => {
  console.log({ data });
  const minValue = data.datasets[0].data
    ? Math.min(...data.datasets[0].data)
    : 100;
  const maxValue = data.datasets[0].data
    ? Math.max(...data.datasets[0].data)
    : 0;

  const stepSize = Math.ceil((maxValue - minValue) / 10);

  const options = {
    responsive: true,
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        border: {
          dash: [4, 4],
          display: false,
        },
        grid: {
          tickBorderDash: [2, 3],
          drawTicks: true,
          tickColor: "#fff",
        },
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return (value / 1000).toFixed(0) + "k";
            }
            return value;
          },
          stepSize: stepSize,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box mt={2}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default LineChart;
