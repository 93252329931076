import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Grid,
    CircularProgress,
    MenuItem,
    Select,
} from '@mui/material';
import CloudDownload from '../../assets/instagram/cloud-download 1.png';

import ConversionIcon from '../../assets/instagram/graph 1.png';
import Eye from '../../assets/instagram/visibility 1.png';
import LikeIcon from '../../assets/instagram/like 1.png';
import CommentIcon from '../../assets/instagram/icComment.png';
import Pagination from '../Pagination';
import dev from '../../services/axios-client';

const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
const convertArrayOfObjectsToCSV = (array) => {
    const header = Object.keys(array[0]).join(',');
    const rows = array.map((obj) => Object.values(obj).join(','));
    return [header, ...rows].join('\n');
};

const AdDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [timeRange, setTimeRange] = useState('This Month');
    const [rows, setRows] = React.useState([]);
    const [pagination, setPagination] = React.useState({ per_page: 2 });
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        getAllDocuments();
        //eslint-disable-next-line
    }, []);

    const getAllDocuments = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/campaigns/platform-wise?page=${page}&perPage=${pagination?.per_page}`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setRows(response.data.data);
                    setPagination({ ...response.data, per_page: 2 });
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handlePageChange = async (page) => {
        await getAllDocuments(page);
    };

    const handleDownload = () => {
        const csvData = convertArrayOfObjectsToCSV(rows);
        downloadCSV(csvData, 'data.csv');
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Button
                    sx={{
                        width: '235px',
                        height: '60px',
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '16px',
                        fontWeight: 400,
                        background: '#FFFFFF',
                        borderRadius: '10.06px',
                        color: '#007AFF',
                        border: '1px solid white',
                        textTransform: 'none',
                        boxShadow: '0px 6px 24px 0px #3537510F',

                        ':hover': {
                            border: '1px solid white',
                            color: '#007AFF',
                            background: '#FFFFFF',
                        },
                    }}
                    onClick={handleDownload}
                >
                    <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                        <img alt="" src={CloudDownload} />
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 500,
                                color: '#141515',
                            }}
                        >
                            Download Pdf
                        </Typography>
                    </Box>
                </Button>
                <Box>
                    <Select
                        sx={{
                            width: '143px',
                            height: '60px',
                            borderRadius: '12px',
                            fontSize: '18px',
                            padding: '20px',
                            backgroundColor: '#FFFFFF',
                            fontFamily: 'Plus Jakarta Sans',

                            '.MuiOutlinedInput-notchedOutline': {
                                border: 0,
                                boxShadow: '0px 4px 6px 0px #3E49540A',
                            },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                    boxShadow: '0px 4px 6px 0px #3E49540A',
                                },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                    boxShadow: '0px 4px 6px 0px #3E49540A',
                                },
                        }}
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                    >
                        <MenuItem value="This Week">Week</MenuItem>
                        <MenuItem value="This Month">Month</MenuItem>
                        <MenuItem value="This Year">Year</MenuItem>
                    </Select>
                </Box>
            </Box>
            <Grid item xs={12}>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '280px', // Adjust this height as needed
                        }}
                    >
                        <CircularProgress sx={{ color: '#6c5ffc' }} />
                    </Box>
                ) : (
                    <Box>
                        {rows?.map((data, index) => (
                            <AdDetailCard key={index} adData={data} />
                        ))}
                    </Box>
                )}
                <Grid
                    xs={12}
                    mt={'20px'}
                    gap={'20px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flexDirection={['column', null, 'row']}
                >
                    <Typography sx={{ textAlign: 'left' }}>
                        Show {rows?.length} from {pagination?.total} data
                    </Typography>
                    <Pagination
                        style
                        currentPage={pagination?.page}
                        pageSize={pagination?.per_page}
                        totalUsers={pagination?.total}
                        onPageChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AdDetails;

const CustomizeListItemText = ({ name, value, icon }) => {
    return (
        <ListItemText
            sx={{ display: 'flex', gap: '20px' }}
            primary={icon}
            secondary={
                <Box>
                    <Typography>{value}</Typography>
                    {name}
                </Box>
            }
        />
    );
};

function formatDate(dateString) {
    const date = new Date(dateString);

    // Format the date
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
}

export const AdDetailCard = ({ adData }) => {
    return (
        <Box
            p={2}
            mt="20px"
            display={'flex'}
            justifyContent={'space-between'}
            backgroundColor="#FFFFFF"
            borderRadius={'3px'}
        >
            <Box>
                <Button
                    sx={{
                        width: '131px',
                        height: '38px',
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '16px',
                        fontWeight: 400,
                        background: '#E5F2FF',
                        borderRadius: '10.06px',
                        color: '#007AFF',
                        border: '1px solid white',
                        textTransform: 'none',
                        ':hover': {
                            border: '1px solid white',
                            color: '#007AFF',
                            background: '#E5F2FF',
                        },
                    }}
                >
                    {adData?.id}
                </Button>
                <Typography variant="h6">{adData?.title}</Typography>
                <Typography variant="body2">
                    {formatDate(adData?.createdAt)}
                </Typography>
            </Box>
            <List sx={{ display: 'flex' }}>
                <ListItem>
                    <CustomizeListItemText
                        name="Conversion"
                        value={adData.totalViews}
                        icon={<img alt="" src={ConversionIcon} />}
                    />
                </ListItem>
                <ListItem>
                    <CustomizeListItemText
                        name="Engagement"
                        value={adData?.totalEngagement}
                        icon={<img alt="" src={Eye} />}
                    />
                </ListItem>
                <ListItem>
                    <CustomizeListItemText
                        name="Likes"
                        value={adData?.totalLikes}
                        icon={<img alt="" src={LikeIcon} />}
                    />
                </ListItem>
                <ListItem>
                    <CustomizeListItemText
                        name="Comments"
                        value={adData?.totalComments}
                        icon={<img alt="" src={CommentIcon} />}
                    />
                </ListItem>
            </List>
        </Box>
    );
};
