import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import delete_icon from '../../../assets/delete_icon.png';
import dev from '../../../services/axios-client';
import Pagination from '../../Pagination';
import PaginationWrapper from '../../Wrapper/PaginationWrapper';
import AccectSelect from '../../Input/AccectSelect';
import share_icon from '../../../assets/share_icon.png';
import { useNavigate } from 'react-router-dom';
import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import WarningDialog from '../../Popups/WarningDialog';
import Loader from '../../Loader';
import ShareIcon from '../../../assets/share_icon.png';
import validationService from '../../../services/validationService';

function createData(
    id,
    name,
    email,
    phone,
    avatar,
    gender,
    influencerStats,
    createdAt,
    action_select,
    action_btns,
) {
    return {
        id,
        name,
        email,
        phone,
        avatar,
        gender,
        influencerStats,
        createdAt,
        action_select,
        action_btns,
    };
}

const headerColumnStyle = {
    fontWeight: 600,
    fontSize: '16px',
    color: '#4B5563',
    backgroundColor: '#F9FAFB',
    fontFamily: 'Plus Jakarta Sans',
};

const bodyColumnStyle = {
    fontFamily: 'Red Hat Display',
    fontWeight: 500,
    fontSize: '16px',
    color: '#74829C',
    padding: '12px 16px',
    border: '1px solid #E9EDF4', // Add border to body cells
};

const options = [
    {
        value: 1,
        label: 'Approved',
        bcolor: '#DAF9E4',
        color: '#01AB3B',
    },
    {
        value: 0,
        label: 'Declined',
        bcolor: '#FFDFE1',
        color: '#E94E51',
    },
    {
        value: 2,
        label: 'Pending',
        bcolor: '#FFFBEA',
        color: '#FFCF00',
    },
];

function InfluencerRequets({
    search,
    formData,
    setRefetchInfluencers,
    refetchInfluencers,
    setAllInfluencers,
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState({});
    const navigate = useNavigate();
    const [selectedDocument, setSelectedDocument] = React.useState({});
    const [modal, setModal] = React.useState({ open: false });
    const [formModal, setFormModal] = React.useState({ open: false });
    const [responseModal, setResponseModal] = React.useState({ open: false });
    const [loading, setLoading] = React.useState(false);
    const [disaprovalReason, setDisaprovalReason] = React.useState(true);

    const handleClose = (flag) => {
        if (flag) {
            handleDeleteDocument(selectedDocument);
        }
        setModal({ ...modal, open: false });
    };
    const handleResponseClose = () => {
        setResponseModal({ ...responseModal, open: false });
        getAllDocuments(page.page);
    };

    const handleOpen = (doc) => {
        setSelectedDocument(doc);
        setModal({
            open: true,
            title: 'Are you sure to delete this Influencer Record?',
            cancelBtn: 'Cancel',
            submitBtn: 'Delete',
        });
    };

    const handleClickOpen = (e, data) => {
        if (e.value === 0)
            setFormModal({
                open: true,
                title: 'Send Disapproval Reason',
                error: true,
                data: data,
            });
        if (e.value === 1) {
            ApproveUser({ email: data.email, id: data.id, isApproved: true });
        }
    };

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);

            ApproveUser({
                email: formModal.data.email,
                disapprovalReason: reason,
                isApproved: false,
                id: formModal.data.id,
            });
            setFormModal({ ...modal, open: false });
        }
    };

    React.useEffect(() => {
        if (refetchInfluencers || search === '' || search) {
            getAllDocuments();
        }
        //eslint-disable-next-line
    }, [search, refetchInfluencers]);

    const getOptionValue = (data) => {
        if (data?.isApproved === 0) return options[2];
        if (data?.isApproved === 1) return options[0];
        return options[1];
    };

    const getAllDocuments = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                `/admin/getAllInfluencers?page=${page}&per_page=10${
                    search.length ? `&search=${search.toLowerCase()}` : ''
                }${
                    formData?.followersFrom.length
                        ? `&followersFrom=${formData?.followersFrom}`
                        : ''
                }${
                    formData?.followersTo.length
                        ? `&followersTo=${formData.followersTo}`
                        : ''
                }${
                    formData?.influencerAge.length
                        ? `&influencerAge=${formData.influencerAge}`
                        : ''
                }${
                    formData?.gender.length ? `&gender=${formData.gender}` : ''
                }${
                    formData?.influencerUaeLicense.length
                        ? `&influencerUaeLicense=${formData.influencerUaeLicense}`
                        : ''
                }${
                    formData?.contentCategory.length
                        ? `&contentCategory=${formData.contentCategory}`
                        : ''
                }${
                    formData?.platform.length
                        ? `&platform=${formData.platform}`
                        : ''
                }`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    const data = response.data.data.map((data) =>
                        createData(
                            data.id,
                            data.name,
                            data.email,
                            data.phoneNumber,
                            data.avatar,
                            data.gender,
                            data.influencerStats,
                            data.createdAt,
                            <AccectSelect
                                options={options}
                                defaultValue={getOptionValue(data)}
                                onChange={(e) => handleClickOpen(e, data)}
                            />,
                            <Grid
                                Container
                                direction="row"
                                alignItems="center"
                                minWidth={'60px'}
                            >
                                <Tooltip title="View Details" placement="top">
                                    <img
                                        alt=""
                                        src={share_icon}
                                        onClick={() =>
                                            navigate(
                                                `/influencer-records/${data.id}`,
                                            )
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            marginRight: '20px',
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                    <img
                                        alt=""
                                        src={delete_icon}
                                        onClick={() => handleOpen(data)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Tooltip>
                            </Grid>,
                        ),
                    );
                    setRows(data);
                    setPage(response.data);
                    setLoading(false);
                    setAllInfluencers(response.data.data);
                    setRefetchInfluencers(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handlePageChange = async (page) => {
        await getAllDocuments(page);
    };

    const handleDeleteDocument = async (doc) => {
        setLoading(true);
        await dev
            .delete(`/admin/deleteUser`, {
                data: { email: doc.email, id: doc.id },
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: 'Influencer deleted successfully',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete document',
                });
            });
    };

    const ApproveUser = async (data) => {
        setLoading(true);
        await dev
            .put(
                `/admin/${data.isApproved ? 'approveUser' : 'disapproveUser'}`,
                data,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: `Request ${
                            data?.isApproved ? 'approved' : 'declined'
                        }`,
                    });
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handleViewDetail = (row) => {
        navigate(`/influencer-profile/${row?.id}`);
    };

    return (
        <>
            <FormRejectionDialog
                hasReason={disaprovalReason}
                modal={formModal}
                onClose={handleFormClose}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleClose} />
            <Loader loading={loading} />
            <TableContainer sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: '1px solid #E9EDF4',
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell sx={headerColumnStyle}>
                                Picture
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Full Name
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>Gender</TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Member Since
                            </TableCell>
                            <TableCell sx={headerColumnStyle}>Phone</TableCell>
                            <TableCell sx={headerColumnStyle}>
                                Platform(s)
                            </TableCell>
                            <TableCell
                                sx={{
                                    ...headerColumnStyle,
                                    textAlign: 'center',
                                }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Avatar src={row?.avatar} alt={row?.name} />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '150px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {row?.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '110px',
                                    }}
                                >
                                    {row?.gender}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '130px',
                                    }}
                                >
                                    {validationService.formatDate(
                                        row?.createdAt,
                                    )}
                                </TableCell>
                                <TableCell sx={bodyColumnStyle}>
                                    {row?.phone}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        maxWidth: '180px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {row?.influencerStats
                                        ?.map((stat) => stat?.platform)
                                        ?.join(',')}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        ...bodyColumnStyle,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleViewDetail(row)}
                                    >
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                fontWeight: '500',
                                                marginRight: '4px',
                                            }}
                                        >
                                            View Details
                                        </Typography>
                                        <img
                                            style={{ marginLeft: '5px' }}
                                            src={ShareIcon}
                                            alt=""
                                            width={14}
                                            height={14}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationWrapper border={'#E9EDF4'} pt={20} pb={210} mt={22}>
                <Pagination
                    currentPage={page?.page}
                    pageSize={page?.per_page}
                    totalUsers={page?.total}
                    onPageChange={handlePageChange}
                />
            </PaginationWrapper>
        </>
    );
}

export default InfluencerRequets;
