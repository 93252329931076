export const capitalize = (str) => {
  if (!str) return "";
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const enUSMediumDateFormat = (inputDate) => {
  const date = new Date(inputDate);

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};

export const enGBShortDateFormat = (inputDate) => {
  const date = new Date(inputDate);

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  return date.toLocaleDateString("en-GB", options);
};

export const enUSShortDate = (inputDate) => {
  const date = new Date(inputDate);

  const options = { day: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const enUSShortTime = (inputDate) => {
  const date = new Date(inputDate);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};

export const enUSMediumDateFormatAmPM = (inputDate) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const amOrPm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day} ${month} ${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amOrPm}`;
};

export const dynamicSlice = (pathname) => {
  const index = pathname.lastIndexOf("/");
  if (index !== -1) {
    return pathname.slice(0, index);
  }
  return pathname;
};

export const USDollarFormatter = () => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
};
