import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import HeaderText from '../Text/HeaderText';
import dev from '../../services/axios-client';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import Loader from '../Loader';
import FormSubmitDialog from '../Popups/formSubmitDialog';
import CustomizedSteppers from '../Stepper';
import CampaignStep1 from './step1';
import CampaignStep2 from './step2';
import CampaignStep3 from './step3';
import CampaignStep4 from './step4';
import { useFormData } from '../../services/formDataContext';
import CampaignStep5 from './step5';

const days = {
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday',
    Su: 'Sunday',
};

const CreateNewCampaign = () => {
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [errors, setErrors] = useState({});
    const [index, setIndex] = useState(0);
    const [cities] = useState([]);
    const [requiredFields, setRequiredFields] = useState([]);
    const [modal, setModal] = useState({ open: false });
    const [payoutType, setPayoutType] = useState('');
    const [socialMediaChannelsData, setSocialMediaChannelsData] = useState([]);
    const [socialMediaChannels, setSocialMediaChannels] = useState([]);
    const [gender, setGender] = useState('');
    const [approvalType, setApprovalType] = useState('instant');
    const [campaignType, setCampaignType] = useState('');
    const [compensationType, setCompensationType] = useState('');
    const [locations, setLocations] = useState([{ id: 1 }]);
    const [hashtags, setHastags] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [nextCampaignId, setNextCampaignId] = useState(null);

    const upgraded =
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA';

    const [image, setImage] = useState(null);

    const { formData, updateFormData } = useFormData();
    const handleClose = () => {
        setModal({ ...modal, open: false });
        setLoading(false);
    };

    const { register, setValue, handleSubmit, getValues, reset, watch } =
        useForm();

    const getNextCampaignId = () => {
        dev.get(`/api/v1/campaignId`, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                setNextCampaignId(response?.data?.id);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        getNextCampaignId();
    }, []);

    const onChangeHandler = (e, name) => {
        // console.log(name);
        const { [name]: tmp, ...rest } = errors;
        setErrors(rest);
        setValue(
            name,
            name === 'age' || name === 'contentCategory' ? e : e?.value,
        );
    };

    const handleFormChange = (event) => {
        if (event?.target?.name) {
            const { [event.target.name]: tmp, ...rest } = errors;
            setErrors(rest);
        }
    };
    function validateFormData(formData, requiredFields) {
        let errors = {};

        requiredFields.forEach((field) => {
            const value = formData[field];

            if (!value) {
                errors[field] = { message: `${field} is required` };
            } else if (value instanceof FileList) {
                if (value.length < 1) {
                    errors[field] = { message: `${field} is required` };
                }
            } else if (Array.isArray(value)) {
                if (value.length === 0) {
                    errors[field] = { message: `${field} is required` };
                }
            }
        });

        return errors;
    }
    console.log({ formData });
    const onSubmit = async (data, e) => {
        let compensationValue = data?.compensation_value;

        if (+data?.compensation_value === 1100) compensationValue = 750;
        if (+data?.compensation_value === 750) compensationValue = 500;
        if (+data?.compensation_value === 1200) compensationValue = 1000;
        if (+data?.compensation_value === 1350) compensationValue = 1250;

        console.log({ data });

        data = {
            ...data,
            avatar: image,
            gender,
            hashtags,
            payoutType,
            locations,
            approvalType,
            campaignType,
            selectedDays,
            selectedTimes: selectedTimes.map((time) =>
                time === 'NOON'
                    ? '12:00 PM'
                    : time === 'MIDNIGHT'
                    ? '12:00 AM'
                    : time,
            ),
            compensationType,
            platforms: socialMediaChannels,
            compensation_value: compensationValue,
            minFollowers: data?.minFollowers || 0,
        };

        const validatedFormData = validateFormData(data, requiredFields);
        setErrors(validatedFormData);

        if (Object.entries(validatedFormData).length > 0) {
            setModal({
                error: true,
                open: true,
                title: `Enter required fields`,
            });
            return;
        }
        if (index === 1 && !locations[0]?.address)
            return alert('Please select at least one location');
        if (
            index === 1 &&
            upgraded &&
            (!selectedDays.length || !selectedTimes.length)
        )
            return alert('Missing time or date slot');
        if (index === 2 && !image)
            return alert('Please select thumbnail for the campaign');

        index < 3 && setIndex((prev) => prev + 1);

        updateFormData({ ...formData, ...data });

        if (index === 0) {
            setIndex(1);
        } else {
            updateFormData({ ...formData, ...data });

            if (index === 3) {
                try {
                    const slots = selectedDays.map((dayAbbreviation) => ({
                        day: days[dayAbbreviation],
                        time: selectedDays.includes(dayAbbreviation)
                            ? selectedTimes
                            : [],
                    }));
                    const body = {
                        ...formData,
                        locations: locations.map((loc) => ({
                            ...loc,
                            latitude: loc.lat,
                            longitude: loc?.lng,
                        })),
                        merchantId: user?.user?.merchant
                            ? user?.user?.merchant.id
                            : user?.user?.id,
                        influencerContentCategory:
                            formData?.contentCategory.map((v) => v?.value),
                        age: formData?.age.map((v) => v?.label),
                        campaignType:
                            payoutType === 'In-store / Walk-in'
                                ? 'walkIn'
                                : 'Delivery',
                        slots,
                        approvalType: approvalType === 'instant' ? 1 : 2,
                        compensation_type: compensationType,
                        paymentType: campaignType,
                    };

                    const response = await dev.post('/api/v1/campaign', body, {
                        headers: {
                            token: user.token,
                        },
                    });
                    if (response.data) {
                        setLoading(false);
                        setIndex(0);
                        reset();
                        alert('Campaign is created successfully');
                        window.location.reload();
                    }
                } catch (error) {
                    // Handle errors
                    console.log('Error:', error);
                    setLoading(false);
                }
            }
        }
    };
    const onError = (errors, e) => console.log(errors, e);

    return (
        <Grid container sx={{ height: '100%' }}>
            <Loader loading={loading} />
            <FormSubmitDialog modal={modal} onClose={handleClose} />

            <HeaderWrapper
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <HeaderText
                    text="Create New Campaign"
                    color="#000000"
                    style={{
                        fontFamily: 'Red Hat Display',
                        fontWeight: 700,
                        fontSize: '32px',
                        lineHeight: '32px',
                    }}
                />
                <Typography
                    sx={{
                        fontFamily: 'Red Hat Display',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '21.17px',
                        display: 'flex',
                    }}
                >
                    Campaign ID:
                    <Typography
                        sx={{
                            fontWeight: 300,
                            color: '#272727',
                        }}
                    >
                        {nextCampaignId}
                    </Typography>
                </Typography>
            </HeaderWrapper>

            <Divider
                sx={{
                    width: '100%',
                    marginY: '20px',
                    border: '1px solid #E9EDF4',
                }}
            />

            <Grid xs={12}>
                <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    onInput={handleFormChange}
                    id="user-form"
                    noValidate
                    autoComplete="on"
                >
                    <Grid
                        xs={12}
                        sx={{
                            mt: '20px',
                            padding: '30px 0px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            mb="30px"
                            sx={{ padding: { lg: '0px 150px' } }}
                        >
                            <CustomizedSteppers activeStep={index} />
                        </Grid>

                        <Grid
                            container
                            alignContent={'center'}
                            justifyContent="left"
                            direction="row"
                            alignItems="center"
                            mt="33px"
                        >
                            {index === 0 && (
                                <CampaignStep1
                                    gender={gender}
                                    errors={errors}
                                    register={register}
                                    setGender={setGender}
                                    approvalType={approvalType}
                                    setApprovalType={setApprovalType}
                                    onChangeHandler={onChangeHandler}
                                    setRequiredFields={setRequiredFields}
                                    setPayoutType={setPayoutType}
                                    payoutType={payoutType}
                                    campaignType={campaignType}
                                    setCampaignType={setCampaignType}
                                    socialMediaChannels={socialMediaChannels}
                                    setSocialMediaChannels={
                                        setSocialMediaChannels
                                    }
                                    getValues={getValues}
                                />
                            )}
                            {index === 1 && (
                                <CampaignStep2
                                    compensationType={compensationType}
                                    setCompensationType={setCompensationType}
                                    cities={cities}
                                    getValues={getValues}
                                    setIndex={setIndex}
                                    errors={errors}
                                    locations={locations}
                                    register={register}
                                    setLocations={setLocations}
                                    onChangeHandler={onChangeHandler}
                                    requiredFields={requiredFields}
                                    setRequiredFields={setRequiredFields}
                                    selectedDays={selectedDays}
                                    selectedTimes={selectedTimes}
                                    setSelectedDays={setSelectedDays}
                                    setSelectedTimes={setSelectedTimes}
                                    setValue={setValue}
                                />
                            )}
                            {index === 2 && (
                                <CampaignStep3
                                    image={image}
                                    setImage={setImage}
                                    setIndex={setIndex}
                                    getValues={getValues}
                                    errors={errors}
                                    register={register}
                                    hashtags={hashtags}
                                    setHastags={setHastags}
                                    onChangeHandler={onChangeHandler}
                                    setRequiredFields={setRequiredFields}
                                    socialMediaChannels={socialMediaChannels}
                                    socialMediaChannelsData={
                                        socialMediaChannelsData
                                    }
                                    setSocialMediaChannelsData={
                                        setSocialMediaChannelsData
                                    }
                                />
                            )}
                            {index === 3 && (
                                <CampaignStep4
                                    nextCampaignId={nextCampaignId}
                                    setIndex={setIndex}
                                    errors={errors}
                                    register={register}
                                    onChangeHandler={onChangeHandler}
                                    setRequiredFields={setRequiredFields}
                                    socialMediaChannels={socialMediaChannels}
                                    setSocialMediaChannelsData={
                                        setSocialMediaChannelsData
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default CreateNewCampaign;
