import { Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AudienceEngagementChart from './AudienceEngagementChart';
import AdDetails from './AdDetails';
import UserInfo from './UserInfo';
import { Card, CardBody, CardHeading } from '../Dashboard/cards/dataCard';
import CloudDownload from '../../assets/instagram/cloud-download 1.png';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import HeaderText from '../Text/HeaderText';
import dev from '../../services/axios-client';
import ActivityCard from './ActivityCard';
import Loader from '../Loader';

export default function Instagram() {
    const [loading, setLoading] = useState(false);
    const [instagram, setInstagram] = useState({});

    const user = JSON.parse(localStorage.getItem('user'));

    const getInstagramData = async () => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/platform/social-summary?platform=instagram&filter=monthly`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setInstagram(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    useEffect(() => {
        getInstagramData();
        //eslint-disable-next-line
    }, []);

    return (
        <Box>
            <Loader loading={loading} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeaderWrapper>
                        <HeaderText
                            text="Instagram Details"
                            color="#272727"
                            style={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '24px',
                                lineHeight: '36px',
                                letterSpacing: '-0.03em',
                            }}
                        />
                    </HeaderWrapper>
                </Grid>
                {/* Activity Chart */}
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <ActivityCard />
                </Grid>
                {/* Audience Engagement Chart */}
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="30px 20px">
                        <CardHeading>Audience Engagement</CardHeading>
                        <CardBody>
                            <AudienceEngagementChart instagram={instagram} />
                        </CardBody>
                    </Card>
                </Grid>
                {/* Ad Details */}
                <Grid
                    xs={12}
                    minHeight={'174px'}
                    padding={'20px 20px 40px 10px'}
                    backgroundColor="#F5F5F5"
                >
                    <AdDetails />
                </Grid>
                {/* User Info */}
                <Grid item xs={12} md={6} lg={5}>
                    <UserInfo />
                </Grid>
            </Grid>
        </Box>
    );
}
