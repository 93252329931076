import { Grid } from '@mui/material';
import TopCard from './TopCard';

const TopCards = ({ campaign }) => (
    <Grid
        container
        sx={{
            display: 'flex',
            justifyContent: ['space-around', 'space-between'],
            gap: '20px',
            alignItems: 'end',
        }}
    >
        <TopCard
            background="#D3F26A"
            color="#141515"
            name="Total Posts done"
            value={campaign?.posts}
        />
        <TopCard
            background="#F84C02"
            color="#FFFFFF"
            name="Total impressions"
            value={campaign?.impressions}
        />
        <TopCard
            background="#5100F3"
            color="#FFFFFF"
            name="Engagement rate"
            value={campaign?.engagement}
        />
        <TopCard
            background="#0A1121"
            color="#FFFFFF"
            name="Number of influencers"
            value={campaign?.influencersCount}
        />
    </Grid>
);

export default TopCards;
