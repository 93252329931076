import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    Box,
    Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import { LoadingButton } from '@mui/lab';
import HeaderText from '../Text/HeaderText';
import SubHeaderText from '../Text/SubHeaderText';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import StripeModal from '../Stripe';
import BankTransferModal from './BankTransferModal';
import LinkPaymentModal from './LinkPaymentModal';
import StripeSuccess from '../Stripe/StripeSuccess';
import StripeFailure from '../Stripe/StripeFailure';
import { useFormData } from '../../services/formDataContext';
import dev from '../../services/axios-client';

const stripePromise = loadStripe(
    'pk_live_51OWZbJADTNbHc8P64U5TRGYB0s1BaArIuNuITbOwEulrtBpoaV9I57cDBLjj1wzboJsSPavZ6FXWFSrIcAoULWkn00QSAileSF',
);

const priceMapping = {
    instagram: {
        story: { nano: 350, micro: 500, gema: 3500 },
        post: { nano: 500, micro: 2000, gema: 12500 },
        reel: { nano: 750, micro: 4000, gema: 12500 },
    },
    tiktok: {
        shortvideo: { nano: 500, micro: 3500, gema: 12500 },
        story: { nano: 350, micro: 500, gema: 3500 },
    },
    snapchat: {
        story: { nano: 350, micro: 500, gema: 3500 },
    },
};

const PaymentPage = ({ onSubmit }) => {
    const AED = 40;
    const { formData, updateFormData } = useFormData();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [stripe, setStripe] = useState(false);
    const [bank, setBank] = useState(false);
    const [link, setLink] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [clientSecret, setClientSecret] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));

    const increaseCount = (platform, content, type) => {
        const updatedEligibilities = [...formData.eligibilities];
        const index = updatedEligibilities.findIndex(
            (eligibility) =>
                eligibility.platform === platform &&
                eligibility.content === content,
        );
        if (
            index !== -1 &&
            type &&
            updatedEligibilities[index][type] !== undefined
        ) {
            updatedEligibilities[index][type] += 1;
            // Update influencers count here
            updatedEligibilities[index].influencers =
                updatedEligibilities[index].barter +
                updatedEligibilities[index].nano +
                updatedEligibilities[index].micro +
                updatedEligibilities[index].gema;
            // total paid and total barter influencers
            const paidInfluencers = updatedEligibilities.reduce(
                (total, item) => total + item.micro + item.nano + item.gema,
                0,
            );

            const barterInfluencers = updatedEligibilities.reduce(
                (total, item) => total + item.barter,
                0,
            );
            let costOfInfluencers = 0;
            updatedEligibilities?.forEach((obj) => {
                const { platform, content, nano, micro, gema } = obj;
                const prices = priceMapping[platform]?.[content];

                if (prices) {
                    costOfInfluencers +=
                        prices.nano * nano +
                        prices.micro * micro +
                        prices.gema * gema;
                }
            });
            updateFormData({
                ...formData,
                eligibilities: updatedEligibilities,
                paidInfluencers,
                barterInfluencers,
                costOfInfluencers,
                perInrolmentFee: AED * (paidInfluencers + barterInfluencers),
                totalCost:
                    costOfInfluencers +
                    AED * (paidInfluencers + barterInfluencers),
            });
        }
    };

    const decreaseCount = (platform, content, type) => {
        const updatedEligibilities = [...formData.eligibilities];
        const index = updatedEligibilities.findIndex(
            (eligibility) =>
                eligibility.platform === platform &&
                eligibility.content === content,
        );
        if (
            index !== -1 &&
            type &&
            updatedEligibilities[index][type] !== undefined &&
            updatedEligibilities[index][type] > 0
        ) {
            updatedEligibilities[index][type] -= 1;
            // Update influencers count here
            updatedEligibilities[index].influencers =
                updatedEligibilities[index].barter +
                updatedEligibilities[index].nano +
                updatedEligibilities[index].micro +
                updatedEligibilities[index].gema;
            // total paid and total barter influencers
            const paidInfluencers = updatedEligibilities.reduce(
                (total, item) => total + item.micro + item.nano + item.gema,
                0,
            );
            const barterInfluencers = updatedEligibilities.reduce(
                (total, item) => total + item.barter,
                0,
            );
            let costOfInfluencers = 0;
            updatedEligibilities?.forEach((obj) => {
                const { platform, content, nano, micro, gema } = obj;
                const prices = priceMapping[platform]?.[content];

                if (prices) {
                    costOfInfluencers +=
                        prices.nano * nano +
                        prices.micro * micro +
                        prices.gema * gema;
                }
            });
            updateFormData({
                ...formData,
                eligibilities: updatedEligibilities,
                paidInfluencers,
                barterInfluencers,
                costOfInfluencers,
                perInrolmentFee: AED * (paidInfluencers + barterInfluencers),
                totalCost:
                    costOfInfluencers +
                    AED * (paidInfluencers + barterInfluencers),
            });
        }
    };

    const uniquePlatforms = Array.from(
        new Set(
            formData?.eligibilities.map((eligibility) => eligibility.platform),
        ),
    );

    function checkInfluencers(data) {
        const messages = [];

        data?.eligibilities?.forEach((obj) => {
            if (!obj.hasOwnProperty('influencers') || obj.influencers === 0) {
                messages.push(
                    `${obj.platform} ${obj.content} influencers required`,
                );
            }
        });

        return messages;
    }

    const handlePaymentForm = () => {
        fetchClientSecret();
        const messages = checkInfluencers(formData);
        if (messages.length) {
            if (messages.length === formData.eligibilities.length)
                return alert('Select influencers for platform');
            else return alert(messages);
        }
        if (!paymentMethod) return alert('Select at least one payment method');

        switch (paymentMethod) {
            case 'Online Payment':
                setStripe(true);
                setBank(false);
                setLink(false);
                break;
            case 'Bank Transfer':
                setStripe(false);
                setBank(true);
                setLink(false);
                break;
            case 'Payment Link':
                setStripe(false);
                setBank(false);
                setLink(true);
                break;
            default:
                setStripe(false);
                setBank(false);
                setLink(false);
                break;
        }
    };

    const fetchClientSecret = async () => {
        try {
            const response = await dev.post(
                '/stripe/intent',
                {
                    currency: 'aed',
                    amount: formData.totalCost * 100,
                },
                {
                    headers: {
                        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzE2LCJlbWFpbCI6Im0udXNtYW5AZm9sbG93aW5nLmFlIiwicmVmcmVzaFRva2VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnBaQ0k2TXpFMkxDSmxiV0ZwYkNJNkltMHVkWE50WVc1QVptOXNiRzkzYVc1bkxtRmxJaXdpZFhObGNsUjVjR1VpT2lKSmJtWnNkV1Z1WTJWeUlpd2lhV0YwSWpveE56QTFORGc1TXpZeExDSmxlSEFpT2pFM09URTRPRGt6TmpGOS4ycjkyZ3VSSjJqb1E4R1hRSTJBNDdkVG4tV1RIWl9PQmtHWkk4VXVrZGdFIiwidXNlclR5cGUiOiJJbmZsdWVuY2VyIiwiaWF0IjoxNzA1NTAzODkwLCJleHAiOjE3OTE5MDM4OTB9.jGeXBcv1eR0lVanHjlUWEMpHDZ0nJgnMRILK__7rVLw',
                    },
                },
            );
            const { client_secret } = response?.data;
            setClientSecret(client_secret);
        } catch (error) {
            console.error('Error fetching client secret:', error);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <HeaderText text="Payment" color="#282F53" />
            {uniquePlatforms.map((platform, index) => (
                <Box key={index} display={'flex'} mt={'24px'}>
                    <TableContainer
                        component={Paper}
                        sx={{ mb: '10px', width: '215px' }}
                    >
                        <Table sx={{ height: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: '600',
                                        }}
                                    >
                                        <img
                                            src={
                                                platform === 'instagram'
                                                    ? '/Insta.png'
                                                    : platform === 'tiktok'
                                                    ? '/Tiktok.png'
                                                    : '/Snapchat.png'
                                            }
                                            alt=""
                                        />
                                        <Typography sx={{ fontWeight: '600' }}>
                                            {platform}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData?.eligibilities
                                    .filter(
                                        (eligibility) =>
                                            eligibility.platform === platform,
                                    )
                                    .map((channels, i) => (
                                        <TableRow key={i}>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    borderBottom: 'none',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {channels.content}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} sx={{ mb: '10px' }}>
                        <Table sx={{ height: '100%' }}>
                            <TableHead>
                                {formData?.paymentType === 'Barter' ? (
                                    <TableRow>
                                        {['All Range of Followers'].map(
                                            (header, index) => (
                                                <TableCell
                                                    key={index}
                                                    align="center"
                                                    sx={{ fontWeight: '600' }}
                                                >
                                                    <img
                                                        src={`${header}.png`}
                                                        alt=""
                                                    />
                                                    <Typography
                                                        sx={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        {header}
                                                    </Typography>
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        {[
                                            'All Range of Followers',
                                            'Less than 20K Followers',
                                            '20k-499k Followers',
                                            'More Than 500k Followers',
                                        ].map((header, index) => (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                sx={{ fontWeight: '600' }}
                                            >
                                                <img
                                                    src={`${header}.png`}
                                                    alt=""
                                                />
                                                <Typography
                                                    sx={{ fontWeight: '600' }}
                                                >
                                                    {header}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {formData?.eligibilities
                                    .filter(
                                        (eligibility) =>
                                            eligibility.platform === platform,
                                    )
                                    .map((channels, i) => (
                                        <TableRow key={i}>
                                            {formData?.paymentType ===
                                            'Barter' ? (
                                                <TableCell
                                                    align="center"
                                                    sx={{ fontWeight: '600' }}
                                                >
                                                    <IconButton
                                                        sx={{
                                                            backgroundColor:
                                                                'grey',
                                                            color: 'white',
                                                            width: '20px',
                                                            height: '20px',
                                                            mr: '11px',
                                                        }}
                                                        onClick={() =>
                                                            decreaseCount(
                                                                platform,
                                                                channels?.content,
                                                                'barter',
                                                            )
                                                        }
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    {channels.barter}
                                                    <IconButton
                                                        sx={{
                                                            backgroundColor:
                                                                '#A4F400',
                                                            color: 'white',
                                                            width: '20px',
                                                            height: '20px',
                                                            ml: '11px',
                                                        }}
                                                        onClick={() =>
                                                            increaseCount(
                                                                platform,
                                                                channels?.content,
                                                                'barter',
                                                            )
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </TableCell>
                                            ) : (
                                                <>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    'grey',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                mr: '11px',
                                                            }}
                                                            onClick={() =>
                                                                decreaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'barter',
                                                                )
                                                            }
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {channels.barter}
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    '#A4F400',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                ml: '11px',
                                                            }}
                                                            onClick={() =>
                                                                increaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'barter',
                                                                )
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    'grey',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                mr: '11px',
                                                            }}
                                                            onClick={() =>
                                                                decreaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'nano',
                                                                )
                                                            }
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {channels.nano}
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    '#A4F400',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                ml: '11px',
                                                            }}
                                                            onClick={() =>
                                                                increaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'nano',
                                                                )
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    'grey',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                mr: '11px',
                                                            }}
                                                            onClick={() =>
                                                                decreaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'micro',
                                                                )
                                                            }
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {channels.micro}
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    '#A4F400',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                ml: '11px',
                                                            }}
                                                            onClick={() =>
                                                                increaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'micro',
                                                                )
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    'grey',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                mr: '11px',
                                                            }}
                                                            onClick={() =>
                                                                decreaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'gema',
                                                                )
                                                            }
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {channels.gema}
                                                        <IconButton
                                                            sx={{
                                                                backgroundColor:
                                                                    '#A4F400',
                                                                color: 'white',
                                                                width: '20px',
                                                                height: '20px',
                                                                ml: '11px',
                                                            }}
                                                            onClick={() =>
                                                                increaseCount(
                                                                    platform,
                                                                    channels?.content,
                                                                    'gema',
                                                                )
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}

            <Box
                sx={{
                    backgroundColor: '#FCFCFC',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '10px 30px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    borderRadius: '10px',
                    mt: '20px',
                    boxShadow: '0px 0px 1px 0px gray',
                }}
            >
                <Typography>
                    Total Paid Influencers: {formData?.paidInfluencers}
                </Typography>
                <Typography>
                    Total Barter Influencers: {formData?.barterInfluencers}
                </Typography>
                <Typography>
                    Cost of Influencers: {formData?.costOfInfluencers}AED
                </Typography>
                <Typography>
                    Per Enrollment Fees: {formData?.perInrolmentFee}AED
                </Typography>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '10px 30px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    borderRadius: '10px',
                    mt: '20px',
                }}
            >
                {user?.user?.merchant && (
                    <Typography sx={{ color: 'white' }}>
                        Exiting Wallet Balance:{' '}
                        <span style={{ fontWeight: '600' }}>
                            {' '}
                            {user?.user?.merchant?.balance}AED
                        </span>
                    </Typography>
                )}
                <Typography sx={{ color: 'white' }}>
                    Total Cost:
                    <span style={{ fontWeight: '600' }}>
                        {' '}
                        {formData?.totalCost}AED
                    </span>
                </Typography>
            </Box>
            <WrapperStandardTextField
                lg={12}
                style={{ paddingRight: '0px', paddingTop: '20px' }}
            >
                <SubHeaderText
                    text="Payment Method"
                    color="#272727"
                    customstyle={{
                        fontWeight: 600,
                    }}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    mt="20px"
                    columnGap={8}
                    rowGap={8}
                >
                    <Box
                        justifyContent={'center'}
                        alignItems="center"
                        sx={{
                            padding: '10px',
                            borderRadius: '20px',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #e7e5e5',
                            boxSizing: 'border-box',
                            width: '330px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="end"
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPaymentMethod('Online Payment');
                                }}
                            >
                                {paymentMethod === 'Online Payment' ? (
                                    <CheckCircleOutlineRoundedIcon
                                        fontSize="medium"
                                        sx={{ color: '#01AB3B' }}
                                    />
                                ) : (
                                    <RadioButtonUncheckedRoundedIcon
                                        fontSize="medium"
                                        sx={{ color: '#272727' }}
                                    />
                                )}
                            </span>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            mt="7px"
                        >
                            <img src={'/OnlinePayment.png'} alt="" />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{ padding: '24px 28px' }}
                            >
                                <img src="/OnlinePayment_2.png" alt="" />
                                <Typography sx={{ fontWeight: '600' }}>
                                    Online Payment
                                </Typography>
                            </Box>
                            <Typography
                                component={'span'}
                                sx={{ fontSize: '16px', textAlign: 'center' }}
                            >
                                Campaign will go live once payment is manually
                                verified in 2-3 days
                            </Typography>
                        </Grid>
                    </Box>
                    <Box
                        justifyContent={'center'}
                        alignItems="center"
                        sx={{
                            padding: '10px',
                            borderRadius: '20px',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #e7e5e5',
                            boxSizing: 'border-box',
                            width: '330px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="end"
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPaymentMethod('Bank Transfer');
                                }}
                            >
                                {paymentMethod === 'Bank Transfer' ? (
                                    <CheckCircleOutlineRoundedIcon
                                        onCl
                                        fontSize="medium"
                                        sx={{ color: '#01AB3B' }}
                                    />
                                ) : (
                                    <RadioButtonUncheckedRoundedIcon
                                        fontSize="medium"
                                        sx={{ color: '#272727' }}
                                    />
                                )}
                            </span>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            mt="7px"
                        >
                            <img src={'/BankTransfer.png'} alt="" />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{ padding: '24px 28px' }}
                            >
                                <Typography sx={{ fontWeight: '600' }}>
                                    Bank Transfer
                                </Typography>
                            </Box>
                            <Typography
                                component={'span'}
                                sx={{ fontSize: '16px', textAlign: 'center' }}
                            >
                                Campaign will go live once payment is manually
                                verified in 2-3 days
                            </Typography>
                        </Grid>
                    </Box>
                    <Box
                        justifyContent={'center'}
                        alignItems="center"
                        sx={{
                            padding: '10px',
                            borderRadius: '20px',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #e7e5e5',
                            boxSizing: 'border-box',
                            width: '330px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="end"
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPaymentMethod('Payment Link');
                                }}
                            >
                                {paymentMethod === 'Payment Link' ? (
                                    <CheckCircleOutlineRoundedIcon
                                        fontSize="medium"
                                        sx={{ color: '#01AB3B' }}
                                    />
                                ) : (
                                    <RadioButtonUncheckedRoundedIcon
                                        fontSize="medium"
                                        sx={{ color: '#272727' }}
                                    />
                                )}
                            </span>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            mt="7px"
                        >
                            <img src={'/PaymentLink.png'} alt="" />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{ padding: '24px 28px' }}
                            >
                                <Typography sx={{ fontWeight: '600' }}>
                                    Payment Link
                                </Typography>
                            </Box>
                            <Typography
                                component={'span'}
                                sx={{ fontSize: '16px', textAlign: 'center' }}
                            >
                                Campaign will go live once payment is manually
                                verified in 2-3 days
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </WrapperStandardTextField>
            <WrapperStandardTextField md={12} lg={12}>
                <LoadingButton
                    variant="contained"
                    onClick={handlePaymentForm}
                    sx={{
                        backgroundColor: '#272727',
                        color: '#FFFFFF',
                        textTransform: 'capitalize',
                        mt: '10px',
                    }}
                >
                    Continue
                </LoadingButton>
            </WrapperStandardTextField>
            {stripePromise && clientSecret && (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                        appearance: {
                            theme: 'stripe',
                        },
                    }}
                >
                    <StripeModal
                        showModal={stripe}
                        setFilureModal={setFailure}
                        setSuccessModal={setSuccess}
                        onClose={() => {
                            setStripe(false);
                            setClientSecret('');
                        }}
                    />
                </Elements>
            )}
            <LinkPaymentModal
                showModal={link}
                onSubmit={onSubmit}
                onClose={() => {
                    setLink(false);
                }}
            />
            <BankTransferModal
                showModal={bank}
                onSubmit={onSubmit}
                onClose={() => {
                    setBank(false);
                }}
            />
            <StripeSuccess
                showModal={success}
                onSubmit={onSubmit}
                onClose={() => setSuccess(false)}
            />
            <StripeFailure
                showModal={failure}
                setStripe={setStripe}
                onClose={() => setFailure(false)}
            />
        </Box>
    );
};

export default PaymentPage;
