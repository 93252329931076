import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import SocialMedia from './socialMedia';
import FormSubmitDialog from '../Popups/formSubmitDialog';
import {
    Box,
    Button,
    Chip,
    Grid,
    InputBase,
    Modal,
    Stack,
    TextareaAutosize,
    TextField,
    Typography,
} from '@mui/material';
import CustomizedInputsStyled from '../Text/StandardTextField';
import SubHeaderText from '../Text/SubHeaderText';
import CustomSelect from '../Input/CustomSelect';
import validationService from '../../services/validationService';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import ImageUpload from './ImageUpload';
import LockIcon from '../../assets/Lock_alt.png';
import { useFormData } from '../../services/formDataContext';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    border: '1px solid #B9B9B9',
    borderRadius: '5px',
    padding: '20px',
    fontFamily: 'Red Hat Display',
    fontSize: '16px',
    outline: 'none',
    marginTop: '10px',
    resize: 'none',
    '&::placeholder': {
        color: '#808080',
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Red Hat Display',
    },
}));

const CampaignStep3 = ({
    setIndex,
    errors,
    onChangeHandler,
    register,
    getValues,
    image,
    setImage,
    hashtags,
    setHastags,
    setRequiredFields,
}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const upgraded =
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA';
    const [hashtag, setHastag] = useState('');
    const [uploading, setUploading] = useState(false);
    const [openAddTags, setOpenAddTags] = useState(false);
    const [error, setError] = useState('');
    const [errorBar, setErrorBar] = useState(false);

    const { formData } = useFormData();

    useEffect(() => {
        user?.user?.merchant?.package === 2 || user?.user?.role?.name === 'SA'
            ? setRequiredFields([
                  'minFollowers',
                  'description',
                  'contentCategory',
                  'hashtags',
              ])
            : setRequiredFields(['description', 'contentCategory', 'hashtags']);
    }, []);

    const handleTagDelete = (tag) => {
        const temp = hashtags.filter((element) => element !== tag);
        setHastags(temp);
    };

    const handleAddTags = () => {
        if (hashtag) {
            setHastags([...hashtags, `#${hashtag}`]);
            setHastag('');
            setError('');
            setOpenAddTags(false);
        } else {
            setErrorBar(true);
            setError('Please write a proper name for a hashtag to be added');
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                        text="Min Followers"
                        required={true}
                        error={errors['minFollowers']}
                    />
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={'20px'}
                        mt={2}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'226px'}
                            height={'60px'}
                            borderRadius={'5px'}
                            sx={{
                                background: !upgraded
                                    ? '#EAEAEA'
                                    : 'transparent',
                                border: '1px solid #EAEAEA',
                            }}
                        >
                            {!upgraded ? (
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        fontFamily: 'Red Hat Display',
                                    }}
                                >
                                    10,000+
                                </Typography>
                            ) : (
                                <InputBase
                                    fullWidth
                                    sx={{
                                        ml: 2,
                                        flex: 1,
                                        color: '#808080',
                                        fontSize: '14px',
                                        lineHeight: '21px',
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield', // Firefox
                                        },
                                        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                                            {
                                                WebkitAppearance: 'none', // Chrome, Safari, Edge
                                                margin: 0, // Remove margin in Firefox
                                            },
                                    }}
                                    type={'number'}
                                    inputProps={{
                                        'aria-label': 'Title',
                                        style: {
                                            textAlign: 'center',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            fontFamily: 'Red Hat Display',
                                        },
                                        max: 100000, // Limit input to 10000
                                    }}
                                    onInput={(e) => {
                                        if (e.target.value > 100000) {
                                            e.target.value = 100000;
                                        }
                                    }}
                                    required
                                    error={!!errors['minFollowers']}
                                    helperText={
                                        errors['minFollowers']
                                            ? errors['minFollowers'].message
                                            : ''
                                    }
                                    {...register('minFollowers', {
                                        valueAsNumber: true,
                                        max: {
                                            value: 100000,
                                            message:
                                                'Maximum value allowed is 10,000',
                                        },
                                    })}
                                />
                            )}
                        </Box>
                        {!upgraded && (
                            <Box
                                ml="10px"
                                gap="5px"
                                display="flex"
                                padding="7px 15px"
                                borderRadius="22.5px"
                                cursor="pointer"
                            >
                                <img src={LockIcon} alt="" />
                                <Typography
                                    sx={{
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                    }}
                                >
                                    Upgrade to unlock
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Box mt={5}>
                        <WrapperStandardTextField lg={12} xl={12} sm={12}>
                            <SubHeaderText
                                customstyle={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    fontFamily: 'Red Hat Display',
                                }}
                                text="Hashtags"
                                required={true}
                                error={errors['hashtags']}
                            />
                            <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                    border: '1px solid #E9EDF4',
                                    padding: '10px 20px',
                                    position: 'relative',
                                    marginTop: '10px',
                                    borderRadius: '5px',
                                }}
                            >
                                {hashtags.length ? (
                                    <>
                                        {hashtags.map((tag) => (
                                            <Chip
                                                sx={{
                                                    borderRadius: '5px',
                                                    height: '41px',
                                                }}
                                                label={tag}
                                                onDelete={() =>
                                                    handleTagDelete(tag)
                                                }
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <Chip
                                            label="DXBFashion"
                                            sx={{
                                                height: '41px',
                                                borderRadius: '5px',
                                                background: '#E2E2E2',
                                            }}
                                        />
                                        <Chip
                                            label="DubaiMall"
                                            sx={{
                                                height: '41px',
                                                borderRadius: '5px',
                                                background: '#E2E2E2',
                                            }}
                                        />
                                    </>
                                )}
                                <Button
                                    variant={'contained'}
                                    sx={{
                                        backgroundColor: '#272727',
                                        textTransform: 'none',
                                        position: 'absolute',
                                        right: '20px',
                                        '&:hover': {
                                            backgroundColor: '#272727',
                                        },
                                    }}
                                    onClick={() => setOpenAddTags(true)}
                                    startIcon={<AddIcon />}
                                >
                                    Add Tag
                                </Button>
                            </Stack>
                        </WrapperStandardTextField>
                    </Box>
                    <Modal
                        open={openAddTags}
                        onClose={() => setOpenAddTags(false)}
                        sx={{ display: 'flex' }}
                    >
                        <Box
                            sx={{
                                margin: '0 auto',
                                justifySelf: 'center',
                                alignSelf: 'center',
                                backgroundColor: 'white',
                                width: { md: '50%', xs: '80%' },
                                padding: '1rem',
                                position: 'relative',
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ marginBottom: '1rem' }}
                            >
                                Add A New Tag
                            </Typography>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <TextField
                                    label="Hashtag"
                                    value={hashtag}
                                    onChange={(e) => setHastag(e.target.value)}
                                    required
                                    sx={{ width: '80%' }}
                                />
                                <Button
                                    variant={'contained'}
                                    sx={{
                                        backgroundColor: '#272727',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#272727',
                                        },
                                    }}
                                    onClick={handleAddTags}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Grid item xs={12} sm={10} mt={5}>
                        <SubHeaderText
                            text="Description (Max 250 Characters)"
                            color="#000000"
                            customstyle={{
                                fontWeight: 700,
                                fontSize: '18px',
                                fontFamily: 'Red Hat Display',
                            }}
                            error={errors['description']}
                            required={true}
                        />
                        <StyledTextarea
                            required
                            minRows={5}
                            maxLength={250}
                            placeholder="e.g “Enjoy 25% off with my referral code, valid up till 20th March 2025”"
                            {...register('description')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} mt={5}>
                        <ImageUpload
                            image={image}
                            setImage={setImage}
                            uploading={uploading}
                            setUploading={setUploading}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SubHeaderText
                        text="Content Category *"
                        color="#000000"
                        customstyle={{
                            fontWeight: 700,
                            fontSize: '18px',
                            fontFamily: 'Red Hat Display',
                        }}
                        error={errors['contentCategory']}
                    />
                    <CustomSelect
                        defaultValue={formData?.contentCategory}
                        multi
                        onChange={onChangeHandler}
                        placeholder="Select Categories"
                        options={validationService.ContentCategoryOptions}
                        name="contentCategory"
                        getValues={getValues}
                        height={'60px'}
                    />
                </Grid>

                <WrapperStandardTextField md={12} lg={12}>
                    <LoadingButton
                        variant="contained"
                        disabled={uploading}
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#272727',
                            border: '1px solid #272727',
                            textTransform: 'capitalize',
                            mt: '20px',
                            mr: '20px',
                            borderRadius: '5px',
                            padding: '0.5rem 3.7rem',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                            },
                        }}
                        onClick={() => setIndex((prev) => prev - 1)}
                    >
                        Back
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        disabled={uploading}
                        type="submit"
                        sx={{
                            backgroundColor: '#272727',
                            color: '#FFFFFF',
                            textTransform: 'capitalize',
                            mt: '20px',
                            borderRadius: '5px',
                            padding: '0.5rem 3.7rem',
                            '&:hover': {
                                backgroundColor: '#272727',
                            },
                        }}
                    >
                        Review & Submit
                    </LoadingButton>
                </WrapperStandardTextField>
            </Grid>
        </>
    );
};

export default CampaignStep3;
