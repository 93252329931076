import React from "react";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";

const ColumnChart = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex];
            if (value === 0) {
              return "$" + value;
            } else {
              return value + "M";
            }
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        border: {
          dash: [4, 4],
          display: false,
        },
        grid: {
          tickBorderDash: [2, 3],
          drawTicks: true,
          tickColor: "#fff",
        },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            if (value === 0) {
              return "$" + value;
            } else {
              return value + "M";
            }
          },
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Box mt={2}>
      <Bar data={data} options={options} height="100px" />
    </Box>
  );
};

export default ColumnChart;
