import React, { useEffect, useState } from 'react';
import { MainMenu } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    superAdminItems,
    managerItems,
    merchantItems,
    financialItems,
    systemAnalystItems,
    merchantAdminItems,
    billingManagerMerchantItems,
    tabLinks,
    campaignManagerMerchantItems,
} from '../../services/sidebarLinksService';
import {
    isSuperAdmin,
    isMerchant,
    isManager,
    isSystemAnalyst,
    isFinanceManager,
    isAdminManagerM,
    isCampaignManagerM,
    isBillingManagerM,
    isReportAnalystM,
} from '../../services/roleCheckService';

export default function SideMenu({ setMenu = null }) {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedPath, setSelectedPath] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const path = window.location.pathname;
    const searchParams = window.location.search;
    const url = searchParams ? `${path}${searchParams}` : path;

    useEffect(() => {
        const matchedLink = tabLinks.find((link) => link.pathname === url);

        if (matchedLink) {
            setSelectedKeys([matchedLink.key]);
        } else {
            console.error('No matching pathname found for current URL.');
        }
    }, [url]);

    const onSelect = (event) => {
        setSelectedKeys(event.selectedKeys);
        setSelectedPath(event.keyPath);
    };

    const onDeselect = (event) => {
        setSelectedKeys(event.selectedKeys);
        setSelectedPath(event.keyPath);
    };

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.at(-1);
        if (latestOpenKey) {
            if (latestOpenKey.includes('-')) {
                setOpenKeys([keys[0], latestOpenKey]);
            } else {
                setOpenKeys([latestOpenKey]);
            }
        } else {
            setOpenKeys([]);
        }
    };

    const getItemsByUserRole = () => {
        if (isSuperAdmin(user?.user)) {
            return superAdminItems;
        } else if (isMerchant(user?.user)) {
            return merchantItems;
        } else if (isManager(user?.user)) {
            return managerItems;
        } else if (isFinanceManager(user?.user)) {
            return financialItems;
        } else if (isSystemAnalyst(user?.user)) {
            return systemAnalystItems;
        } else if (isAdminManagerM(user?.user)) {
            return merchantAdminItems;
        } else if (isCampaignManagerM(user?.user)) {
            return campaignManagerMerchantItems;
        } else if (isBillingManagerM(user?.user)) {
            return billingManagerMerchantItems;
        } else if (isReportAnalystM(user?.user)) {
            return systemAnalystItems;
        } else {
            return [];
        }
    };

    const items = getItemsByUserRole();

    const onClick = (e) => {
        switch (e.key) {
            case 'sub1':
                navigate('/');
                setOpenKeys([]);
                setMenu && setMenu(false);
                break;
            case 'instagram':
                navigate('/campaigns/instagram');
                setMenu && setMenu(false);
                break;
            case 'snapchat':
                navigate('/campaigns/snapchat');
                setMenu && setMenu(false);
                break;
            case 'tiktok':
                navigate('/campaigns/tiktok');
                setMenu && setMenu(false);
                break;
            case '5':
                navigate('/user-management-module');
                setMenu && setMenu(false);
                break;
            case '6':
                navigate('/create-merchant-account');
                setMenu && setMenu(false);
                break;
            case '7':
                navigate('/waitlist-users');
                setMenu && setMenu(false);
                break;
            case '8':
                navigate('/all-documents');
                setMenu && setMenu(false);
                break;
            // case "10":
            //   navigate("/create-influencer-account");
            //   setMenu && setMenu(false);
            //   break;
            case '9':
                navigate('/influencer-records');
                setMenu && setMenu(false);
                break;
            case '11':
                navigate('/create-new-campaign');
                setMenu && setMenu(false);
                break;
            case '28':
                navigate('/view-campaigns');
                setMenu && setMenu(false);
                break;
            case '12':
                navigate('/campaign-records');
                setMenu && setMenu(false);
                break;
            case '13':
                navigate('/manage-payments');
                setMenu && setMenu(false);
                break;
            case '14':
                navigate('/payment-withdraw-requests');
                setMenu && setMenu(false);
                break;
            case 'sub6':
                setOpenKeys([]);
                window.open(
                    'https://following.freshdesk.com/support/home',
                    '_blank',
                );
                setMenu && setMenu(false);
                break;
            // case "16":
            //   navigate("/reporting-monitor");
            //   setMenu && setMenu(false);
            //   break;
            // case "17":
            //   navigate("/reporting-campaigns");
            //   setMenu && setMenu(false);
            //   break;
            case 'sub7':
                navigate('/reports');
                setOpenKeys([]);
                setMenu && setMenu(false);
                break;
            case '19':
                navigate('/settings');
                setMenu && setMenu(false);
                break;
            case '20':
                navigate('/scoring-metrics');
                setMenu && setMenu(false);
                break;
            case '21':
                navigate('/manage-payments?tab=mp');
                setMenu && setMenu(false);
                break;
            case '22':
                navigate('/manage-payments?tab=ar');
                setMenu && setMenu(false);
                break;
            case '23':
                navigate('/manage-payments?tab=mth');
                setMenu && setMenu(false);
                break;
            case '24':
                navigate('/manage-payments?tab=ab');
                setMenu && setMenu(false);
                break;
            case '25':
                navigate('/payment-withdraw-requests?tab=ad');
                setMenu && setMenu(false);
                break;
            case '26':
                navigate('/payment-withdraw-requests?tab=ppc');
                setMenu && setMenu(false);
                break;
            case '27':
                navigate('/manage-payments?tab=in');
                setMenu && setMenu(false);
                break;
            default:
                // Handle other keys or do nothing
                setMenu && setMenu(false);
                break;
        }
    };

    return (
        <MainMenu
            onClick={onClick}
            style={{
                width: '18.75rem',
                border: 'none',
                height: '90vh',
                overflowX: 'hidden',
                overflowY: 'auto',
                background: '#f1f1f1',
            }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
            onDeselect={onDeselect}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
        />
    );
}
