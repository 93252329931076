import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import Loader from '../Loader';

const LocationSearch = ({ index, locations, setLocations }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Replace with your Google Maps API key
        libraries: ['places'],
    });

    if (!isLoaded) return <Loader loading={isLoaded} />;
    return (
        <PlacesAutocomplete
            index={index}
            locations={locations}
            setLocations={setLocations}
        />
    );
};

const PlacesAutocomplete = ({ index, locations, setLocations }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'AE' }, // Restrict results to UAE
        },
        debounce: 300,
        initialValue: locations[index].address || '',
    });
    useEffect(() => {
        if (locations[index] && locations[index].address) {
            setValue(locations[index].address, false);
        }
    }, [index, locations, setValue]);

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            const updatedLocations = [...locations];
            updatedLocations[index] = {
                id: locations[index].id,
                address,
                lat,
                lng,
            };
            setLocations(updatedLocations);
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    return (
        <Box>
            <Autocomplete
                sx={{
                    width: '533px',
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#AFAFAF', // Change to your desired color
                        },
                    },
                }}
                freeSolo
                disableClearable
                clearOnEscape={false}
                clearOnBlur={false}
                options={data.map((place) => place.description)}
                onInputChange={(event, newValue) => {
                    setValue(newValue);
                }}
                onChange={(event, newValue) => {
                    handleSelect(newValue);
                }}
                inputValue={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        placeholder="Where will influencer visit?"
                    />
                )}
            />
        </Box>
    );
};

export default LocationSearch;
