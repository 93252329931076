import { Box, Grid } from "@mui/material";
import SubHeaderText from "../Text/SubHeaderText";
const PaymentBox = ({ label = "label", value = null }) => {
  return (
    <Grid xs="auto">
      <Box
        sx={{
          height: "100px",
          borderRadius: "7px",
          backgroundColor: "#fff",
          color: "#272727",
          fontSize: "14px",
          padding: "17px 20px 26px 17px",
          border: "1px solid #E7E5E5",
        }}
      >
        <SubHeaderText text={label} color="#212529" />
        <SubHeaderText
          text={value}
          color="#212529"
          customstyle={{
            fontSize: "22px",
            mt: "16px",
            fontWeight: 600,
          }}
        />
      </Box>
    </Grid>
  );
};

export default PaymentBox;
