import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Dropdown } from "antd";
import * as React from "react";
import { FaRegEdit } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import InfoLabel from "../Text/InfoLabel";
import SubHeaderText from "../Text/SubHeaderText";
import { useNavigate, useParams } from "react-router-dom";
import dev from "../../services/axios-client";
import FormSubmitDialog from "../Popups/formSubmitDialog";
import WarningDialog from "../Popups/WarningDialog";
import Loader from "../Loader";
import DocumentPopup from "../ViewDocument";
import { Image } from "antd";
import UserDetailDialog from "./UserDetailDialog";

function MerchantDetails() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  let { merchantId } = useParams();
  const [users, setUsers] = React.useState([]);
  const [prevUsers, setPrevUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [navi, setNavi] = React.useState(false);
  const [merchant, setMerchant] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState({ open: false });
  const [merchantData, setMerchantData] = React.useState({});
  const [popupIsOpen, setPopupIsOpen] = React.useState(false);
  const [addressEdit, setAddressEdit] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState({});
  const [userDetailModal, setUserDetailModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({ open: false });
  const [personalDetailEdit, setPersonalDetailEdit] = React.useState(false);
  const [paymentDetailEdit, setPaymentDetailEdit] = React.useState(false);

  const toggleUserDetailModal = () => setUserDetailModal(!userDetailModal);

  const handleViewIconClick = (data) => {
    setSelectedDocument(data);
    setPopupIsOpen(true);
  };

  const handleUsers = (newUsers) => {
    setUsers([...newUsers]);
  };

  const closePopup = () => {
    setSelectedDocument({});
    setPopupIsOpen(false);
  };

  const handleClose = (flag) => {
    if (flag) {
      handleMerchantDelete(merchant.id);
    }
    setModal({ ...modal, open: false });
  };
  const handleResponseClose = () => {
    setResponseModal({ open: false });
    if (navi) navigate("/user-management-module");
  };

  const handleOpen = (id) => {
    setNavi(true);
    setModal({
      open: true,
      title: "Are you sure to delete this Merchant Record?",
      cancelBtn: "Cancel",
      submitBtn: "Delete",
    });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const getRoles = () => {
    dev
      .get(`/roles`, {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        setRoles(
          response.data.roles.map((data) => {
            return { label: data.name, value: data.id };
          })
        );
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    getRoles();
    //eslint-disable-next-line
  }, []);
  React.useEffect(
    () => {
      setLoading(true);
      dev
        .get(`/merchant/${merchantId}`, {
          headers: {
            token: user.token,
          },
        })
        .then((response) => {
          setLoading(false);
          setMerchant(response.data.data);
          setMerchantData(response.data.data);
          handleUsers(response.data.data.merchantUsers);
          setPrevUsers(response.data.data.merchantUsers);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    },
    //eslint-disable-next-line
    []
  );

  const onPersonalDetailEdit = async () => {
    try {
      const response = await dev.put(
        `/merchant/basic/${merchantId}`,
        {
          name: merchantData.name ? merchantData.name : merchant.name,
          contactNumber: merchantData.contactNumber
            ? merchantData.contactNumber
            : merchant.contactNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: user.token,
          },
        }
      );
      if (response) {
        setPersonalDetailEdit(false);
        setResponseModal({
          open: true,
          title: response.data.message,
        });
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const onPaymentDetailEdit = async () => {
    try {
      const response = await dev.put(
        `/merchant/rates/${merchantId}`,
        {
          setupFees: Number(
            merchantData.setupFees ? merchantData.setupFees : merchant.setupFees
          ),
          annualFees: Number(
            merchantData.annualFees
              ? merchantData.annualFees
              : merchant.annualFees
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: user.token,
          },
        }
      );
      if (response) {
        setPaymentDetailEdit(false);
        setResponseModal({
          open: true,
          title: response.data.message,
        });
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };
  const onAddressEdit = async () => {
    try {
      const response = await dev.put(
        `/merchant/address/${merchantId}`,
        {
          country: merchantData?.country
            ? merchantData?.country
            : merchant.country,
          city: merchantData?.city ? merchantData?.city : merchant.city,
          area: merchantData?.area ? merchantData?.area : merchant.area,
          latitude: merchantData?.latitude
            ? merchantData?.latitude
            : merchant.latitude,
          longitude: merchantData?.longitude
            ? merchantData?.longitude
            : merchant.longitude,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: user.token,
          },
        }
      );
      if (response) {
        setAddressEdit(false);
        setResponseModal({
          open: true,
          title: response.data.message,
        });
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  const DocumentUpload = async (e) => {
    const { name, files } = e.target;
    setMerchantData((prevInputData) => ({
      ...prevInputData,
      [name]: files[0],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMerchantData((prevInputData) => ({
      ...prevInputData,
      [name]: value,
    }));
  };

  const docTypeToLink = {};
  const documents = merchant.documents;
  documents?.forEach((document) => {
    docTypeToLink[document.docType] = {
      link: document.docLink,
      id: document.id,
      docName: document.docName,
    };
  });

  const handleMerchantDelete = (id) => {
    setLoading(true);
    dev
      .delete(`/merchant/${id}`, {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setResponseModal({
            open: true,
            title: "Merchant deleted successfully",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setResponseModal({
          error: true,
          open: true,
          title: "Failed to delete merchant",
        });
      });
  };

  const handleDocumentImage = (e, document) => {
    console.log({ e, document });
  };
  const handleDeleteDocument = async (doc, index) => {
    setNavi(false);
    setLoading(true);
    await dev
      .delete(`/documents/${doc.id}`, {
        data: { docName: doc.docName },
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setMerchant((prevMerchant) => {
            const updatedDocuments = [...prevMerchant.documents];
            updatedDocuments.splice(index, 1);
            return { ...prevMerchant, documents: updatedDocuments };
          });

          setResponseModal({
            open: true,
            title: "Document deleted successfully",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setResponseModal({
          error: true,
          open: true,
          title: "Failed to delete document",
        });
      });
  };

  return (
    <>
      <Grid container sx={{ backgroundColor: "#FFFFFF", height: "100%" }}>
        <FormSubmitDialog modal={responseModal} onClose={handleResponseClose} />
        <WarningDialog modal={modal} onClose={handleClose} />
        <UserDetailDialog
          users={users}
          roles={roles}
          prevUsers={prevUsers}
          modal={userDetailModal}
          handleUsers={handleUsers}
          toggleModal={toggleUserDetailModal}
        />
        {popupIsOpen && selectedDocument && (
          <DocumentPopup
            isOpen={popupIsOpen}
            onClose={closePopup}
            documentUrl={selectedDocument}
          />
        )}
        <Loader loading={loading} />
        <Grid
          xs={12}
          sx={{
            mb: "8px",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
          >
            <Box>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                startIcon={<KeyboardBackspaceIcon />}
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#272727",
                  borderRadius: "5px",
                  fontFamily: "Gilroy",
                  border: "1px solid #272727",
                  padding: "10px 20px 10px 20px",
                  "&:hover": {
                    borderColor: "#272727",
                    backgroundColor: "inherit",
                  },
                }}
              >
                Back
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => handleOpen(merchant.id)}
                variant="contained"
                color="error"
                sx={{
                  fontWeight: 500,
                  color: "#FFFFFF",
                  fontSize: "16px",
                  backgroundColor: "#E94E51",
                  fontFamily: "IBM Plex Sans",
                  textTransform: "capitalize",
                  padding: "8px 20px 8px 20px",
                }}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          sx={{
            backgroundColor: "#FFFFFF",
            mt: "20px",
            padding: "22px 20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px 0px #0000001A",
          }}
        >
          <Grid container>
            <Grid xs={12} md={2}>
              <Box sx={{ cursor: "pointer" }} component={"label"}>
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={DocumentUpload}
                  name="merchantLogo"
                />
                <Avatar
                  alt={merchant.name}
                  src={docTypeToLink?.Logo?.link}
                  sx={{ width: "100px", height: "100px" }}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={10} pt="10px">
              <Grid container direction="row">
                <Grid display={"flex"} gap={"30px"}>
                  <Box display={"flex"}>
                    <h3
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        color: "#272727",
                        fontSize: "22px",
                        lineHeight: "22px",
                        fontFamily: "'IBM Plex Sans'",
                      }}
                    >
                      Role:
                    </h3>
                    <p
                      style={{
                        fontWeight: 400,
                        color: "#272727",
                        fontSize: "22px",
                        lineHeight: "22px",
                        margin: "0 0 0 4px",
                        fontFamily: "'IBM Plex Sans'",
                      }}
                    >
                      Admin
                    </p>
                  </Box>
                  <Divider
                    orientation="vertical"
                    style={{ height: "25px", background: "#E7E5E5" }}
                  />
                  <Box display={"flex"}>
                    <h3
                      style={{
                        fontSize: "22px",
                        lineHeight: "22px",
                        fontFamily: "'IBM Plex Sans'",
                        color: "#272727",
                        fontWeight: 600,
                        margin: 0,
                      }}
                    >
                      Contact Name:
                    </h3>
                    <p
                      style={{
                        fontSize: "22px",
                        lineHeight: "22px",
                        fontFamily: "'IBM Plex Sans'",
                        color: "#272727",
                        fontWeight: 400,
                        margin: "0 0 0 4px",
                      }}
                    >
                      {merchant.name}
                    </p>
                  </Box>
                </Grid>
                <Grid container direction="row" md={12} mt={3}>
                  <h3
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#272727",
                      lineHeight: "22px",
                      fontFamily: "'IBM Plex Sans'",
                    }}
                  >
                    Address:
                  </h3>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "22px",
                      color: "#272727",
                      lineHeight: "22px",
                      margin: "0 0 0 4px",
                      fontFamily: "'IBM Plex Sans'",
                    }}
                  >
                    {merchant.address}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* detail card */}
          <Grid
            xs={12}
            sx={{
              mt: "20px",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              border: "1px solid #E9EDF4",
              padding: "8px 15px 15px 15px",
            }}
          >
            <Grid container direction="row" justifyContent="space-between">
              <h3
                style={{
                  fontSize: 22,
                  fontWeight: 600,
                  fontFamily: "'IBM Plex Sans'",
                }}
              >
                Personal Details
              </h3>
              <Box>
                <Button
                  sx={{
                    fontSize: "16px",
                    marginTop: "14px",
                    fontWeight: 500,
                    color: "#8D8D8D",
                    borderRadius: "5px",
                    textTransform: "initial",
                    border: "1px solid #8D8D8D",
                    fontFamily: "'IBM Plex Sans'",
                    backgroundColor: "transparent",
                    padding: "5px 10px 5px 10px",
                    "&:hover": {
                      color: "inherit",
                      backgroundColor: "inherit",
                    },
                  }}
                >
                  {personalDetailEdit ? (
                    <label onClick={onPersonalDetailEdit}>Save</label>
                  ) : (
                    <div onClick={() => setPersonalDetailEdit(true)}>
                      <FaRegEdit style={{ marginRight: "3px" }} /> Edit
                    </div>
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid md={12} container direction="row">
              <Grid md={4} direction={"column"}>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontFamily: "'IBM Plex Sans'",
                    color: "#272727",
                    fontWeight: 400,
                    margin: "2px 0 7px 0",
                  }}
                >
                  Full Name:
                </p>
                <InfoLabel
                  showLabel={false}
                  value={merchantData.name}
                  name="name"
                  onChange={personalDetailEdit ? handleInputChange : null}
                />
              </Grid>

              <Grid md={4} direction={"column"}>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontFamily: "'IBM Plex Sans'",
                    color: "#272727",
                    fontWeight: 400,
                    margin: "2px 0 7px 0",
                  }}
                >
                  Contact Person Number:
                </p>
                <InfoLabel
                  showLabel={false}
                  value={merchantData.contactNumber}
                  name="contactNumber"
                  onChange={personalDetailEdit ? handleInputChange : null}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Address card */}
          <Grid
            xs={12}
            sx={{
              mt: "20px",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              border: "1px solid #E9EDF4",
              padding: "8px 15px 15px 15px",
            }}
          >
            <Grid container direction="row" justifyContent="space-between">
              <h3
                style={{
                  fontSize: 22,
                  fontWeight: 600,
                  fontFamily: "'IBM Plex Sans'",
                }}
              >
                Address
              </h3>
              <Box>
                <Button
                  // onClick={handleEditClick}
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#8D8D8D",
                    marginTop: "14px",
                    borderRadius: "5px",
                    textTransform: "initial",
                    border: "1px solid #8D8D8D",
                    fontFamily: "'IBM Plex Sans'",
                    backgroundColor: "transparent",
                    padding: "5px 10px 5px 10px",
                    // "&:hover": {
                    //   backgroundColor: edit ? "#8a7fff" : "#f9f9f9", // Change the hover background color
                    //   color: edit ? "#282F53" : "#282F53", // Optionally, you can change the text color on hover
                    // },
                  }}
                >
                  {addressEdit ? (
                    <label onClick={onAddressEdit}>Save</label>
                  ) : (
                    <div onClick={() => setAddressEdit(true)}>
                      <FaRegEdit style={{ marginRight: "3px" }} /> Edit
                    </div>
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid md={12} container direction="row">
              <Grid md={4} direction={"column"}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#272727",
                    lineHeight: "22px",
                    margin: "2px 0 7px 0",
                    fontFamily: "'IBM Plex Sans'",
                  }}
                >
                  Country:
                </p>
                <InfoLabel
                  showLabel={false}
                  value={merchantData.country}
                  name="country"
                  onChange={addressEdit ? handleInputChange : null}
                />
              </Grid>
              <Grid md={4} direction={"column"}>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontFamily: "'IBM Plex Sans'",
                    color: "#272727",
                    fontWeight: 400,
                    margin: "2px 0 7px 0",
                  }}
                >
                  City:
                </p>
                <InfoLabel
                  showLabel={false}
                  value={merchantData.city}
                  name="city"
                  onChange={addressEdit ? handleInputChange : null}
                />
              </Grid>
              <Grid md={4} direction={"column"}>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontFamily: "'IBM Plex Sans'",
                    color: "#272727",
                    fontWeight: 400,
                    margin: "2px 0 7px 0",
                  }}
                >
                  Area:
                </p>
                <InfoLabel
                  showLabel={false}
                  value={merchantData.area}
                  name="area"
                  onChange={addressEdit ? handleInputChange : null}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Payment and detail card */}
          <Grid container direction="row" style={{ gap: "14px" }}>
            {/* left card */}
            <Grid
              xs={12}
              md={4}
              sx={{
                mt: "20px",
                borderRadius: "10px",
                backgroundColor: "#FCFCFC",
                border: "1px solid #E9EDF4",
                padding: "8px 15px 15px 15px",
              }}
            >
              <Grid container direction="row" justifyContent="space-between">
                <h3
                  style={{
                    fontSize: 22,
                    fontWeight: 600,
                    fontFamily: "'IBM Plex Sans'",
                  }}
                >
                  Payment Rates
                </h3>
                <Box>
                  <Button
                    sx={{
                      fontSize: "16px",
                      marginTop: "14px",
                      fontWeight: 500,
                      color: "#8D8D8D",
                      borderRadius: "5px",
                      textTransform: "initial",
                      border: "1px solid #8D8D8D",
                      padding: "5px 10px 5px 10px",
                      fontFamily: "'IBM Plex Sans'",
                      backgroundColor: "transparent",
                    }}
                  >
                    {paymentDetailEdit ? (
                      <label onClick={onPaymentDetailEdit}>Save</label>
                    ) : (
                      <div onClick={() => setPaymentDetailEdit(true)}>
                        <FaRegEdit style={{ marginRight: "3px" }} /> Edit
                      </div>
                    )}
                  </Button>
                </Box>
              </Grid>
              <Grid
                md={12}
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid md={7} direction={"column"}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#272727",
                      lineHeight: "22px",
                      margin: "2px 0 7px 0",
                      fontFamily: "'IBM Plex Sans'",
                    }}
                  >
                    Setup Fees:
                  </p>
                  <InfoLabel
                    fontSize="18px"
                    renderSuffix
                    showLabel={false}
                    value={merchantData.setupFees}
                    name="setupFees"
                    suffix={"AED"}
                    onChange={paymentDetailEdit ? handleInputChange : null}
                  />
                </Grid>
                <Grid md={5} direction={"column"}>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#272727",
                      lineHeight: "22px",
                      margin: "2px 0 7px 0",
                      fontFamily: "'IBM Plex Sans'",
                    }}
                  >
                    Annual Fees:
                  </p>
                  <InfoLabel
                    fontSize="18px"
                    renderSuffix
                    showLabel={false}
                    suffix={"AED"}
                    value={merchantData.annualFees}
                    name="annualFees"
                    onChange={paymentDetailEdit ? handleInputChange : null}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* right card */}
            <Grid
              xs={12}
              md={7.8}
              sx={{
                mt: "20px",
                borderRadius: "10px",
                backgroundColor: "#FCFCFC",
                border: "1px solid #E9EDF4",
                padding: "8px 15px 15px 15px",
              }}
            >
              <Grid container direction="row" justifyContent="space-between">
                <h3
                  style={{
                    fontSize: 22,
                    fontWeight: 600,
                    fontFamily: "'IBM Plex Sans'",
                  }}
                >
                  Details of Users
                </h3>
                <Box>
                  <Button
                    onClick={toggleUserDetailModal}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#8D8D8D",
                      marginTop: "14px",
                      borderRadius: "5px",
                      textTransform: "initial",
                      border: "1px solid #8D8D8D",
                      fontFamily: "'IBM Plex Sans'",
                      backgroundColor: "transparent",
                      padding: "5px 10px 5px 10px",
                    }}
                  >
                    <FaRegEdit style={{ marginRight: "3px" }} /> Edit
                  </Button>
                </Box>
              </Grid>
              <Grid md={12} container direction="row" flexWrap="nowrap">
                {users.length > 0 ? (
                  <>
                    <Grid md={5} direction={"column"}>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#272727",
                          lineHeight: "22px",
                          margin: "2px 0 7px 0",
                          fontFamily: "'IBM Plex Sans'",
                        }}
                      >
                        Email:
                      </p>
                      <Tooltip title={users[0]?.email}>
                        <h3
                          style={{
                            margin: 0,
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#272727",
                            lineHeight: "22px",
                            wordWrap: "break-word",
                            fontFamily: "'IBM Plex Sans'",
                          }}
                        >
                          {users[0]?.email ?? ""}
                        </h3>
                      </Tooltip>
                    </Grid>
                    <Grid md={2} direction={"column"}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "'IBM Plex Sans'",
                          color: "#272727",
                          fontWeight: 400,
                          margin: "2px 0 7px 0",
                        }}
                      >
                        Password:
                      </p>
                      <h3
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "#272727",
                          lineHeight: "22px",
                          fontFamily: "'IBM Plex Sans'",
                        }}
                      >
                        *******
                      </h3>
                    </Grid>
                    <Grid md={3} direction={"column"}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "'IBM Plex Sans'",
                          color: "#272727",
                          fontWeight: 400,
                          margin: "2px 0 7px 0",
                        }}
                      >
                        Account Role:
                      </p>
                      <h3
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "#272727",
                          lineHeight: "22px",
                          fontFamily: "'IBM Plex Sans'",
                        }}
                      >
                        {roles.find((rol) => rol.value === users[0]?.roleId)
                          ?.label ?? ""}
                      </h3>
                    </Grid>
                    <Grid md={3} direction={"column"}>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "'IBM Plex Sans'",
                          color: "#272727",
                          fontWeight: 400,
                          margin: "2px 0 7px 0",
                        }}
                      >
                        No of Selected Users:
                      </p>
                      <h3
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          display: "flex",
                          fontSize: "18px",
                          color: "#272727",
                          lineHeight: "22px",
                          justifyContent: "center",
                          fontFamily: "'IBM Plex Sans'",
                        }}
                      >
                        {users.length ?? 0}
                      </h3>
                    </Grid>
                  </>
                ) : (
                  <Typography>
                    There is no user against this merchant. please edit to add
                    new user
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={12}
            sx={{
              mt: "20px",
              mb: "20px",
            }}
          >
            <p
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#272727",
                lineHeight: "22px",
                margin: "2px 0 7px 0",
                fontFamily: "'IBM Plex Sans'",
              }}
            >
              Documents:
            </p>
          </Grid>
          {/* Document card */}
          <Grid
            container
            direction="row"
            style={{ justifyContent: "space-between", gap: 20 }}
          >
            {/* left card */}
            {merchant?.documents?.map((document, index) => (
              <Grid
                key={index}
                xs={12}
                md={3.5}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#FCFCFC",
                  border: "1px solid #E9EDF4",
                  padding: "8px 15px 15px 15px",
                }}
              >
                <Grid container direction="row" justifyContent="space-between">
                  <h3
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily: "'IBM Plex Sans'",
                    }}
                  >
                    {document?.docType}
                  </h3>
                  <Box
                    sx={{
                      marginTop: "16px",
                      color: "#8d8d8d",
                    }}
                  >
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "0",
                            label: (
                              <label
                                htmlFor="documentlogo"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <input
                                  type="file"
                                  name="myfile"
                                  id="documentlogo"
                                  onChange={(e) =>
                                    handleDocumentImage(e, document)
                                  }
                                  style={{
                                    display: "none",
                                  }}
                                />
                                Change
                              </label>
                            ),
                          },
                          {
                            key: "1",
                            label: (
                              <a
                                href={document?.docLink}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <Box sx={{ cursor: "pointer" }}>
                                  <SubHeaderText text="Download" />
                                </Box>
                              </a>
                            ),
                            // onClick: () =>
                          },
                          // {
                          //   key: "3",
                          //   label: "Delete",
                          //   onClick: () =>
                          //     handleDeleteDocument(document, index),
                          // },
                        ],
                      }}
                      trigger={["click"]}
                      placement="bottomRight"
                      overlayClassName="overlap"
                    >
                      <BsThreeDotsVertical />
                    </Dropdown>
                  </Box>
                </Grid>
                <Grid md={12}>
                  <Grid md={7} direction={"column"}>
                    <Grid container direction="row">
                      <Image
                        src={
                          document?.docLink
                            ? document?.docLink
                            : "https://s3-alpha-sig.figma.com/img/af17/6817/489f0d870d823c711a0dc0541c17579a?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XebvAOT-6ZwQdtX2e30Ftu-osL09NHnYtI-1NR1FaIF-YM-4iLhBW9FbCgfNDjzUnwe4Mzj6N93-j2tLIa4Le0tOq1A97jrcoCMEgQX7meGSFlIsa4A24lumqfnxQTk2HgOxEmD~pzNYbU5PZwfe0kgcmRfqWvEApdVDJNfVxgMV8nkFJb3U8hNlcTloXUPH88qt7JDSjAAYWc4cb~sMsgv~P-dJ0lKYZPcGSXAaxF0MVS9XiAzmGXhfj98IfImXtc0teT5IU4cqzdqShULbi1LRP6bsf7-26Be2eXNXZS-Vbk-ji9XuXBSLeBL93sHTGkRETzwy-oHE~rTKG38IGQ__"
                        }
                        alt="file image"
                        style={{ width: "40px", height: "40px" }}
                        preview={false}
                      />
                    </Grid>
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        color: "#272727",
                        lineHeight: "22px",
                        margin: "30px 0 7px 0",
                        fontFamily: "'IBM Plex Sans'",
                      }}
                    >
                      {document.docName ?? "dummyname.png"}
                    </h3>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#8D8D8D",
                        fontFamily: "'IBM Plex Sans'",
                      }}
                    >
                      {document?.size}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MerchantDetails;
