import {
    SUPER_ADMIN,
    MERCHANT_COORDINATOR,
    INFLUENCER_MANAGER,
    FINANCIAL_ADMINISTRATOR,
    SYSTEM_ANALYST,
    ADMIN_MANAGER_MERCHANT,
    CAMPAIGN_MANAGER_MERCHANT,
    BILLING_MANAGER_MERCHANT,
    REPORT_ANALYST_MERCHANT,
} from '../constants';

const hasRole = (user, targetRole) => {
    if (!user || !user.role || !user.role.name) {
        return false;
    }
    const roleName = user.role.name.toLowerCase();

    return roleName === targetRole;
};

export const isSuperAdmin = (user) => hasRole(user, SUPER_ADMIN);
export const isMerchant = (user) => hasRole(user, MERCHANT_COORDINATOR);
export const isManager = (user) => hasRole(user, INFLUENCER_MANAGER);
export const isFinanceManager = (user) =>
    hasRole(user, FINANCIAL_ADMINISTRATOR);
export const isSystemAnalyst = (user) => hasRole(user, SYSTEM_ANALYST);
export const isAdminManagerM = (user) => hasRole(user, ADMIN_MANAGER_MERCHANT);
export const isCampaignManagerM = (user) =>
    hasRole(user, CAMPAIGN_MANAGER_MERCHANT);
export const isBillingManagerM = (user) =>
    hasRole(user, BILLING_MANAGER_MERCHANT);
export const isReportAnalystM = (user) =>
    hasRole(user, REPORT_ANALYST_MERCHANT);

export const superAdminLinks = [
    '/',
    '/user-management-module',
    '/all-documents',
    '/create-merchant-account',
    '/waitlist-users',
    '/create-influencer-account',
    '/influencer-records',
    '/influencer-profile',
    '/influencer-records-history',
    '/influencer-records-activity',
    '/content-approval-detail',
    '/scoring-metrics',
    '/create-new-campaign',
    '/view-campaigns',
    '/view-campaigns-details',
    '/approve-campaign',
    '/campaign-records',
    '/campaign-details',
    '/campaign-influencers',
    '/manage-payments',
    '/payment-withdraw-requests',
    '/support-manage',
    '/reports',
    '/settings',
    '/campaigns/instagram',
    '/campaigns/snapchat',
    '/campaigns/tiktok',
];
export const merchantLinks = [
    '/',
    '/user-management-module',
    '/all-documents',
    '/create-merchant-account',
    '/waitlist-users',
    '/create-new-campaign',
    '/view-campaigns-details',
    '/view-campaigns',
    '/campaign-records',
    '/campaign-details',
    '/campaign-influencers',

    // '/campaigns/instagram',
    '/campaigns/snapchat',
    '/campaigns/tiktok',
];
export const managerLinks = [
    '/',
    'create-influencer-account',
    '/influencer-records',
    '/influencer-profile',
    '/influencer-records-history',
    '/influencer-records-activity',
    '/content-approval-detail',
    '/scoring-metrics',
];
export const financialLinks = [
    '/',
    '/manage-payments',
    '/payment-withdraw-requests',
    '/reports',
];
export const systemAnalystLinks = ['/', '/reports'];

export const merchantAdminLinks = [
    '/',
    '/create-new-campaign',
    '/view-campaigns-details',
    '/view-campaigns',
    '/campaign-records',
    '/campaign-details',
    '/campaign-influencers',

    '/manage-payments',
    '/support-manage',
    '/reports',
    // '/campaigns/instagram',
    '/campaigns/snapchat',
    '/campaigns/tiktok',
];
export const billingManagerMerchantLinks = [
    '/',
    '/manage-payments',
    '/reports',
];

export const campaignManagerMerchantLinks = [
    '/',
    '/create-new-campaign',
    '/view-campaigns-details',
    '/view-campaigns',
    '/campaign-records',
    '/campaign-details',
    '/campaign-influencers',

    '/manage-payments',
    '/support-manage',
    '/reports',
    // '/campaigns/instagram',
    '/campaigns/snapchat',
    '/campaigns/tiktok',
];
