import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default function LineChart() {
    const data = {
        labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        datasets: [
            {
                label: 'Spendings',
                data: [55, 20, 15, 30, 40, 25, 35, 45, 55, 60],
                borderColor: '#EF4444',
                backgroundColor: '#EF4444',
                tension: 0.5,
                pointRadius: 0,
                borderWidth: 3.8,
            },
            {
                label: 'Engagement',
                data: [35, 15, 10, 25, 35, 20, 30, 40, 50, 55],
                borderColor: '#A700FF',
                backgroundColor: '#A700FF',
                tension: 0.5,
                pointRadius: 0,
                borderWidth: 3.8,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Weeks',
                },
                grid: {
                    display: false,
                    border: false,
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Values',
                },
            },
        },
    };
    return <Line data={data} options={options} />;
}
