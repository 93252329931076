import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../../Containers/Layout';
import {
    isSuperAdmin,
    isMerchant,
    isManager,
    isFinanceManager,
    isSystemAnalyst,
    isAdminManagerM,
    isCampaignManagerM,
    isBillingManagerM,
    isReportAnalystM,
    superAdminLinks,
    merchantLinks,
    managerLinks,
    financialLinks,
    systemAnalystLinks,
    merchantAdminLinks,
    billingManagerMerchantLinks,
    campaignManagerMerchantLinks,
} from '../../services/roleCheckService';
import NotAuthorized from '../../Components/NotAuthorized';

export default function PrivateRoutes() {
    const user = JSON.parse(localStorage.getItem('user')) || {};

    const authorizedRoutes = (() => {
        if (isSuperAdmin(user?.user)) {
            return superAdminLinks;
        } else if (isMerchant(user?.user)) {
            return merchantLinks;
        } else if (isManager(user?.user)) {
            return managerLinks;
        } else if (isFinanceManager(user?.user)) {
            return financialLinks;
        } else if (isSystemAnalyst(user?.user)) {
            return systemAnalystLinks;
        } else if (isAdminManagerM(user?.user)) {
            return merchantAdminLinks;
        } else if (isCampaignManagerM(user?.user)) {
            return campaignManagerMerchantLinks;
        } else if (isBillingManagerM(user?.user)) {
            return billingManagerMerchantLinks;
        } else if (isReportAnalystM(user?.user)) {
            return systemAnalystLinks;
        } else {
            return [];
        }
    })();

    const isAuthorized = (pathname) => {
        return authorizedRoutes.some((existingRoute) => {
            // Check if the existing route is the same as the provided route
            if (existingRoute === pathname) {
                return true;
            }
            // Check if the provided pathname is a sub-path of the existing pathname
            if (pathname.startsWith(existingRoute + '/')) {
                return true;
            }
            return false;
        });
    };

    return user?.token ? (
        isAuthorized(window.location.pathname) ? (
            <Layout>
                <Outlet />
            </Layout>
        ) : (
            <NotAuthorized />
        )
    ) : (
        <Navigate to="/login" />
    );
}
