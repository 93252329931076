import * as React from "react";
import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import PaginationWrapper from "../Wrapper/PaginationWrapper";
import Pagination from "../Pagination";
import dev from "../../services/axios-client";

export const PendingPaymentTable = () => {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState({});
  const [isError, setIsError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [responseReceived, setResponseReceived] = React.useState(false);

  const headerColumnStyle = {
    fontSize: "14px",
    lineHeight: "21px",
    textTransform: "uppercase",
    fontWeight: "600",
    fontFamily: "'IBM Plex Sans'",
    color: "#2e3138",
    textAlign: "left",
    marginBottom: "5px",
    padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
  };

  const bodyColumnStyle = {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "400",
    fontFamily: "IBM Plex Sans",
    color: "#212529",
    textAlign: "left",
    padding: { xs: "5px 8px", md: "8px 10px", lg: "10px 16px" },
  };

  React.useEffect(() => {
    const getAllpendingPayments = async () => {
      try {
        let url = `/payments?status=pending&page=${page.page}&per_page=${page.per_page}`;
        const response = await dev.get(url);
        setPage({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setRows([...response.data.data]);
      } catch (error) {
        console.log("Error: ", error.response?.data?.message || error.message);
      }
    };
    getAllpendingPayments();
  }, [page.page]);

  const handlePageChange = (page) => {
    setPage((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleAction = async (endpoint, id, status) => {
    try {
      setResponseReceived(false);

      const response = await dev.put(`/payments/${id}/${endpoint}`, {
        ...(status && { status }),
      });
      setIsError(false);
      setMsg(`Payment is now ${response.data.status}`);
      setResponseReceived(true);
    } catch (error) {
      setIsError(true);
      setMsg(error.response?.data?.message || error.message);
      setResponseReceived(true);
    }
  };

  const onApproveClick = (e, id) => {
    e.preventDefault();
    handleAction("update/status", id, "Approved");
  };

  const onReject = (e, id) => {
    e.preventDefault();
    handleAction("update/reject", id);
  };

  return (
    <>
      {responseReceived && (
        <Grid item xs={12} md={8} mb={3} mt={2}>
          {isError ? (
            <Alert severity="error">{msg}</Alert>
          ) : (
            <Alert severity="success">{msg}</Alert>
          )}
        </Grid>
      )}
      <TableContainer sx={{ paddingX: 0 }}>
        <Table>
          <TableHead
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderTop: "1px solid #E9EDF4",
                borderBottom: "1px solid #E9EDF4",
              },
            }}
          >
            <TableRow>
              <TableCell sx={headerColumnStyle}>influencer name</TableCell>
              <TableCell sx={headerColumnStyle}>campaign name</TableCell>
              <TableCell sx={headerColumnStyle}>received payment</TableCell>
              <TableCell sx={headerColumnStyle}>action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableRow sx={{ height: "20px" }}></TableRow>

            {rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={bodyColumnStyle}>{row.user?.name}</TableCell>
                <TableCell sx={bodyColumnStyle}>{row.campaignName}</TableCell>
                <TableCell sx={{ ...bodyColumnStyle, fontWeight: 700 }}>
                  {row.amount} AED
                </TableCell>
                <TableCell
                  sx={{ ...bodyColumnStyle, display: "flex", gap: "8px" }}
                >
                  <PrimaryBtn
                    bgColor="#01AB3B"
                    text="approve"
                    style={{ paddingX: "2.3rem", fontSize: "14px" }}
                    onClick={(e) => onApproveClick(e, row.id)}
                  />
                  <PrimaryBtn
                    bgColor="#E94E51"
                    text="decline"
                    style={{ paddingX: "2.3rem", fontSize: "14px" }}
                    onClick={(e) => onReject(e, row.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper border={"#E9EDF4"} pt={20} pb={210} mt={22}>
        <Pagination
          currentPage={page?.page}
          pageSize={page?.per_page}
          totalUsers={page?.total}
          onPageChange={handlePageChange}
        />
      </PaginationWrapper>
    </>
  );
};
