import * as React from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import { useSpring, animated } from '@react-spring/web';
import {
    Box,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    InputLabel,
    FormControl,
    OutlinedInput,
    debounce,
    InputAdornment,
} from '@mui/material';
import filterIcon from '../../../assets/Union.png';
import search_light from '../../../assets/Search_light.png';
import downloadIcon from '../../../assets/download-icon-dark.png';
import CustomSelect from '../../Input/CustomSelect';
import { useForm } from 'react-hook-form';
import validationService from '../../../services/validationService';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';
import dev from '../../../services/axios-client';

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
const flattenObject = (obj, parentKey = '') => {
    let flatObj = {};

    Object.keys(obj).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        const value = obj[key];

        if (Array.isArray(value)) {
            // Flatten array: iterate through array and flatten each object inside it
            value.forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                    Object.assign(
                        flatObj,
                        flattenObject(item, `${fullKey}[${index}]`),
                    );
                } else {
                    flatObj[`${fullKey}[${index}]`] = item;
                }
            });
        } else if (typeof value === 'object' && value !== null) {
            // Recursively flatten object
            Object.assign(flatObj, flattenObject(value, fullKey));
        } else {
            // Primitive value
            flatObj[fullKey] = value;
        }
    });

    return flatObj;
};

const convertArrayOfObjectsToCSV = (array) => {
    // Flatten all objects in the array
    const flatArray = array.map((item) => flattenObject(item));

    // Extract headers (keys from the first object)
    const headers = Object.keys(flatArray[0]);

    // Create rows by mapping over the flattened array
    const rows = flatArray.map((obj) =>
        headers
            .map((header) => {
                const value = obj[header];
                return typeof value === 'object' && value !== null
                    ? JSON.stringify(value)
                    : value;
            })
            .join(','),
    );

    return [headers.join(','), ...rows].join('\n');
};

export default function SpringPopper({
    openFilter,
    setOpenFilter,
    handleSearch,
    formData,
    setFormData,
    handleSubmit,
    allInfluencers = [],
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [donwloading, setDownLoading] = React.useState(false);

    const user = JSON.parse(localStorage.getItem('user'));

    const handleDownload = async (page = 1) => {
        setDownLoading(true);
        await dev
            .get(`/admin/getAllInfluencers?page=${page}&getAll=1`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data) {
                    const data = response.data.data;
                    const csvData = convertArrayOfObjectsToCSV(data);
                    downloadCSV(csvData, 'influencers.csv');
                }
            })
            .catch((error) => {
                console.log(error);
                setDownLoading(false);
            });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenFilter((previousOpen) => !previousOpen);
    };

    const canBeOpen = openFilter && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;

    return (
        <div>
            <Box display={'flex'} gap={'20px'}>
                <Box>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={'text'}
                        onChange={debounce(handleSearch, 500)}
                        sx={{
                            width: '242px',
                            height: '46px',
                            border: '1px solid #E9EDF4',
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <img
                                    alt=""
                                    src={search_light}
                                    style={{ cursor: 'pointer' }}
                                />
                            </InputAdornment>
                        }
                        placeholder="search"
                    />
                </Box>
                <Box
                    onClick={handleClick}
                    border={`1px solid ${openFilter ? '#D4FF02' : '#E2E3E5'}`}
                    width={46}
                    height={46}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    borderRadius={'5px'}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <img alt="" src={filterIcon} />
                </Box>
                <Box
                    border={`1px solid #E2E3E5`}
                    width={46}
                    height={46}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    borderRadius={'5px'}
                    sx={{
                        cursor: 'pointer',
                        pointerEvents:
                            allInfluencers.length > 0 ? 'auto' : 'none',
                    }}
                    onClick={() =>
                        allInfluencers.length > 0 ? handleDownload() : null
                    }
                >
                    <img alt="" src={downloadIcon} />
                </Box>
            </Box>
            <Popper id={id} open={openFilter} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <Box
                            sx={{
                                mt: 2,
                                mr: 4,
                                border: 1,
                                p: 1,
                                bgcolor: 'background.paper',
                            }}
                        >
                            <FilterComponent
                                formData={formData}
                                setFormData={setFormData}
                                handleSubmit={handleSubmit}
                            />
                        </Box>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

const FilterComponent = ({ setFormData, formData, handleSubmit }) => {
    // Define state for the form fields

    // Update state for each form field
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission

    // Reset the form
    const handleReset = () => {
        setFormData({
            followersFrom: '',
            followersTo: '',
            influencerAge: '',
            gender: '',
            influencerUaeLicense: '',
            contentCategory: '',
            platform: '',
        });
    };

    return (
        <Box sx={{ p: 2, width: '564px', mx: 'auto', height: '668px' }}>
            <Box
                sx={{
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <h2>Filter</h2>
            </Box>

            {/* Form starts here */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>Followers From</InputLabel>
                    <TextField
                        label="Followers From"
                        fullWidth
                        name="followersFrom"
                        value={formData.followersFrom}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>Followers To</InputLabel>
                    <TextField
                        label="Followers To"
                        fullWidth
                        name="followersTo"
                        value={formData.followersTo}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>Influencer Age</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="influencerAge"
                            value={formData.influencerAge}
                            onChange={handleChange}
                            placeholder="Select age"
                        >
                            {validationService.ageOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>Gender</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            placeholder="Select gender"
                        >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="both">Both</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>
                        Influencer UAE License
                    </InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="influencerUaeLicense"
                            value={formData.influencerUaeLicense}
                            onChange={handleChange}
                        >
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel sx={{ mb: 2 }}>Content Category</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="contentCategory"
                            value={formData.contentCategory}
                            onChange={handleChange}
                            placeholder="Select category"
                        >
                            {validationService.ContentCategoryOptions.map(
                                (option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 2 }}>Platform</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="platform"
                            value={formData.platform}
                            onChange={handleChange}
                            placeholder="Select platform"
                        >
                            <MenuItem value="instagram">Instagram</MenuItem>
                            <MenuItem value="snapchat">Snapchat</MenuItem>
                            <MenuItem value="tiktok">Tiktok</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Box
                sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <PrimaryBtn
                    text="Reset"
                    onClick={handleReset}
                    bgColor="#F9F9F9"
                    color="#000000"
                    style={{
                        width: '132.5px',
                        height: '40px',
                        border: '1px solid #F9F9F9',
                    }}
                />
                <PrimaryBtn
                    text="Apply Now"
                    color="#000000"
                    type="submit"
                    onClick={handleSubmit}
                    bgColor="#D4FF02"
                    style={{ width: '132.5px', height: '40px' }}
                />
            </Box>
        </Box>
    );
};
