import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
} from '@mui/material';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import avatar_img from '../../assets/avatar_img.png';
import delete_icon from '../../assets/delete_icon.png';
import view_icon from '../../assets/view_icon.png';
import download_icon from '../../assets/download_icon.png';
import doc_img from '../../assets/doc_img.png';
import PrimaryBtn from '../CustomButton/PrimaryBtn';
import HeaderText from '../Text/HeaderText';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import dev from '../../services/axios-client';
import Pagination from '../Pagination';
import PaginationWrapper from '../Wrapper/PaginationWrapper';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import InfluencerRequets from './Requests';
import ActiveInfluencers from './ActiveInfluencers';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ContentApproval from './ContentApproval/ContentApproval';
import StatusCards from './StatusCards';
import SpringPopper from './Requests/filters';
import { useForm } from 'react-hook-form';

function createData(id, name, document, type, action_btns) {
    return { id, name, document, type, action_btns };
}

const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
const convertArrayOfObjectsToCSV = (array) => {
    const header = Object.keys(array[0]).join(',');
    const rows = array.map((obj) => Object.values(obj).join(','));
    return [header, ...rows].join('\n');
};

const activeHeader = {
    cursor: 'pointer',
    padding: '10px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
    border: '1px solid #E9EDF4',
    borderRadius: '5px',
    backgroundColor: '#D4FF02',
};

const nonActiveHeader = {
    cursor: 'pointer',
    padding: '10px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#74829C',
    textAlign: 'center',
    fontFamily: 'Red Hat Display',
    borderRadius: '5px',
};

function InfluencerRecord() {
    const [active, setActive] = React.useState(0);
    const [, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState('');
    const [openFilter, setOpenFilter] = React.useState(false);
    const [allInfluencers, setAllInfluencers] = React.useState(false);
    const [refetchInfluencers, setRefetchInfluencers] = React.useState(false);
    const [formData, setFormData] = React.useState({
        followersFrom: '',
        followersTo: '',
        influencerAge: '',
        gender: '',
        influencerUaeLicense: '',
        contentCategory: '',
        platform: '',
    });

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    React.useEffect(() => {
        if (queryParams.get('active')) {
            setActive(Number(queryParams.get('active')));
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setRefetchInfluencers(true);
    };

    const handleSearch = (e) => setSearch(e.target.value);
    const handleDownload = () => {
        const csvData = convertArrayOfObjectsToCSV(allInfluencers);
        downloadCSV(csvData, 'influencers.csv');
    };

    return (
        <Grid sx={{ height: '100%' }}>
            <HeaderWrapper>
                <HeaderText
                    text="All Influencers"
                    color="#000000"
                    style={{
                        fontFamily: 'Red Hat Display',
                        fontSize: '32px',
                        fontWeight: 700,
                    }}
                />
            </HeaderWrapper>
            <StatusCards />
            <Grid
                xs={12}
                sx={{
                    backgroundColor: '#FFFFFF',
                    mt: '20px',
                    padding: ['30px', '0px'],
                    borderRadius: '7px',
                }}
            >
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'}>
                        <Typography
                            width={'81px'}
                            sx={active === 0 ? activeHeader : nonActiveHeader}
                            onClick={() => {
                                setActive(0);
                                setSearchParams({
                                    active: 0,
                                });
                            }}
                        >
                            All
                        </Typography>
                        <Typography
                            sx={active === 1 ? activeHeader : nonActiveHeader}
                            width={'168px'}
                            onClick={() => {
                                setActive(1);
                                setSearchParams({
                                    active: 1,
                                });
                            }}
                        >
                            Signup Requests
                        </Typography>
                        <Typography
                            sx={active === 2 ? activeHeader : nonActiveHeader}
                            width={'168px'}
                            onClick={() => {
                                setActive(2);
                                setSearchParams({
                                    active: 2,
                                });
                            }}
                        >
                            Content Approvals
                        </Typography>
                    </Box>
                    {active === 0 && (
                        <SpringPopper
                            openFilter={openFilter}
                            formData={formData}
                            setFormData={setFormData}
                            setOpenFilter={setOpenFilter}
                            handleSearch={handleSearch}
                            handleSubmit={handleSubmit}
                            handleDownload={handleDownload}
                            allInfluencers={allInfluencers}
                        />
                    )}
                </Box>

                {active === 0 && (
                    <InfluencerRequets
                        search={search}
                        formData={formData}
                        setAllInfluencers={setAllInfluencers}
                        refetchInfluencers={refetchInfluencers}
                        setRefetchInfluencers={setRefetchInfluencers}
                    />
                )}
                {active === 1 && <ActiveInfluencers />}
                {active === 2 && <ContentApproval />}
            </Grid>
        </Grid>
    );
}

export default InfluencerRecord;
