import dashboard_icon from '../assets/da.png';
import user_icon from '../assets/user_management.png';
import campaign_icon from '../assets/campaign_management.png';
import payment_icon from '../assets/payment_management.png';
import support_icon from '../assets/support_management.png';
import report_icon from '../assets/report_management.png';
import setting_icon from '../assets/setting_icon.png';

const IconStyle = {
    width: '47px',
    height: '47px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

const getItem = (label, key, icon, children, type, onTitleClick) => {
    return {
        key,
        icon,
        children,
        label,
        type,
        onTitleClick,
    };
};

export const superAdminItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Users',
        'sub2',
        <img alt="" src={user_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">All Merchants</span>,
                'sub2-sub1',
                <span className="circle"></span>,
                [
                    getItem('Create Merchant', '6'),
                    getItem('View Merchants', '5'),
                    getItem('Waitlist Users', '7'),
                ],
            ),
            getItem(
                <span className="sub-child-title">Influencer Record</span>,
                'sub2-sub2',
                <span className="circle"></span>,
                [
                    // getItem("Create Influencer", "10", null),
                    getItem('View Influencers', '9', null),
                    getItem('Scoring Metrics', '20', null),
                ],
            ),
        ],
    ),
    getItem(
        'Campaigns',
        'sub4',
        <img alt="" src={campaign_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem('Create Campaign', '11', null),
            getItem('View Campaigns', '28', null),
            getItem('Monitor Campaign', '12', null),
            getItem(
                <span className="platform">Instagram</span>,
                'instagram',
                <div style={IconStyle}>
                    <img src="/InstagramIcon.png" alt="insta" />
                </div>,
            ),
            // getItem(
            //     <span className="platform">Snapchat</span>,
            //     'snapchat',
            //     <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
            //         <img src="/SnapchatIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">TikTok</span>,
            //     'tiktok',
            //     <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
            //         <img src="/TikTokIcon.png" alt="insta" />
            //     </div>,
            // ),
        ],
    ),
    getItem(
        'Payments',
        'sub5',
        <img alt="" src={payment_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">Manage Payments</span>,
                'sub5-sub1',
                <span className="circle"></span>,
                [
                    getItem('Influencer Payouts', '13'),
                    getItem('Merchant Payments', '21'),
                    getItem('Approve/Reject Requests', '22'),
                    getItem('Monitor Transaction History', '23'),
                    getItem('All Brands', '24'),
                ],
            ),
            getItem(
                <span className="sub-child-title">Influencer Withdrawal</span>,
                'sub5-sub2',
                <span className="circle"></span>,
                [
                    getItem('View Requests', '14', null),
                    getItem('Approved/Declined Requests', '25', null),
                    getItem('Pending Payment Confirmation', '26', null),
                ],
            ),
        ],
    ),

    getItem(
        <span style={{ fontSize: '16px' }}>Support</span>,
        'sub6',
        <img alt="" src={support_icon} style={{ cursor: 'pointer' }} />,
    ),
    getItem(
        <span style={{ fontSize: '16px' }}>Reports</span>,
        'sub7',
        <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
    ),
    // getItem(
    //   "Settings",
    //   "sub8",
    //   <img alt="" src={setting_icon} style={{ cursor: "pointer" }} />,
    //   [getItem("System Parameters", "19", null)]
    // ),
];

export const merchantItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Users',
        'sub2',
        <img alt="" src={user_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">All Merchants</span>,
                'sub2-sub1',
                <span className="circle"></span>,
                [
                    getItem('Create Merchant', '6'),
                    getItem('View Merchants', '5'),
                    getItem('Waitlist Users', '7'),
                ],
            ),
        ],
    ),

    getItem(
        'Campaigns',
        'sub4',
        <img alt="" src={campaign_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem('Create Campaign', '11', null),
            getItem('View Campaigns', '28', null),
            getItem('Monitor Campaign', '12', null),
            // getItem(
            //     <span className="platform">Instagram</span>,
            //     'instagram',
            //     <div style={IconStyle}>
            //         <img src="/InstagramIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">Snapchat</span>,
            //     'snapchat',
            //     <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
            //         <img src="/SnapchatIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">TikTok</span>,
            //     'tiktok',
            //     <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
            //         <img src="/TikTokIcon.png" alt="insta" />
            //     </div>,
            // ),
        ],
    ),
];

export const managerItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Users',
        'sub2',
        <img alt="" src={user_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">Influencer Record</span>,
                'sub2-sub2',
                <span className="circle"></span>,
                [
                    // getItem("Create Influencer", "10", null),
                    getItem('View Influencers', '9', null),
                    getItem('Scoring Metrics', '20', null),
                ],
            ),
        ],
    ),
];

export const financialItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Payments',
        'sub5',
        <img alt="" src={payment_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">Manage Payments</span>,
                'sub5-sub1',
                <span className="circle"></span>,
                [
                    getItem('Influencer Payouts', '13'),
                    getItem('Merchant Payments', '21'),
                    getItem('Approve/Reject Requests', '22'),
                    getItem('Monitor Transaction History', '23'),
                    getItem('All Brands', '24'),
                ],
            ),
            getItem(
                <span className="sub-child-title">Influencer Withdrawal</span>,
                'sub5-sub2',
                <span className="circle"></span>,
                [
                    getItem('View Requests', '14', null),
                    getItem('Approved/Declined Requests', '25', null),
                    getItem('Pending Payment Confirmation', '26', null),
                ],
            ),
        ],
    ),

    getItem(
        <span style={{ fontSize: '16px' }}>Reports</span>,
        'sub7',
        <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
    ),
];

export const systemAnalystItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        <span style={{ fontSize: '16px' }}>Reports</span>,
        'sub7',
        <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
    ),
];

const user = JSON.parse(localStorage.getItem('user'));
const isMerchantId19 = user?.user?.merchant?.id === 19;
export const merchantAdminItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Campaigns',
        'sub4',
        <img alt="" src={campaign_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem('Create Campaign', '11', null),
            getItem('View Campaigns', '28', null),
            getItem('Monitor Campaign', '12', null),
            // getItem(
            //     <span className="platform">Instagram</span>,
            //     'instagram',
            //     <div style={IconStyle}>
            //         <img src="/InstagramIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">Snapchat</span>,
            //     'snapchat',
            //     <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
            //         <img src="/SnapchatIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">TikTok</span>,
            //     'tiktok',
            //     <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
            //         <img src="/TikTokIcon.png" alt="insta" />
            //     </div>,
            // ),
        ],
    ),
    isMerchantId19
        ? null
        : getItem(
              'Payments',
              'sub5',
              <img alt="" src={payment_icon} style={{ cursor: 'pointer' }} />,
              [
                  getItem(
                      <span className="sub-child-title">Manage Payments</span>,
                      'sub5-sub1',
                      <span className="circle"></span>,
                      [getItem('Invoices', '27')],
                  ),
              ],
          ),
    getItem(
        <span style={{ fontSize: '16px' }}>Support</span>,
        'sub6',
        <img alt="" src={support_icon} style={{ cursor: 'pointer' }} />,
    ),
    isMerchantId19
        ? null
        : getItem(
              <span style={{ fontSize: '16px' }}>Reports</span>,
              'sub7',
              <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
          ),
];

export const billingManagerMerchantItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Payments',
        'sub5',
        <img alt="" src={payment_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">Manage Payments</span>,
                'sub5-sub1',
                <span className="circle"></span>,
                [getItem('Invoices', '27')],
            ),
        ],
    ),

    getItem(
        <span style={{ fontSize: '16px' }}>Reports</span>,
        'sub7',
        <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
    ),
];

export const campaignManagerMerchantItems = [
    getItem(
        <span style={{ fontSize: '16px' }}>Dashboard</span>,
        'sub1',
        <img alt="" src={dashboard_icon} style={{ cursor: 'pointer' }} />,
    ),

    getItem(
        'Campaigns',
        'sub4',
        <img alt="" src={campaign_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem('Create Campaign', '11', null),
            getItem('View Campaigns', '28', null),
            getItem('Monitor Campaign', '12', null),
            // getItem(
            //     <span className="platform">Instagram</span>,
            //     'instagram',
            //     <div style={IconStyle}>
            //         <img src="/InstagramIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">Snapchat</span>,
            //     'snapchat',
            //     <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
            //         <img src="/SnapchatIcon.png" alt="insta" />
            //     </div>,
            // ),
            // getItem(
            //     <span className="platform">TikTok</span>,
            //     'tiktok',
            //     <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
            //         <img src="/TikTokIcon.png" alt="insta" />
            //     </div>,
            // ),
        ],
    ),
    getItem(
        'Payments',
        'sub5',
        <img alt="" src={payment_icon} style={{ cursor: 'pointer' }} />,
        [
            getItem(
                <span className="sub-child-title">Manage Payments</span>,
                'sub5-sub1',
                <span className="circle"></span>,
                [getItem('Invoices', '27')],
            ),
        ],
    ),
    getItem(
        <span style={{ fontSize: '16px' }}>Support</span>,
        'sub6',
        <img alt="" src={support_icon} style={{ cursor: 'pointer' }} />,
    ),
    getItem(
        <span style={{ fontSize: '16px' }}>Reports</span>,
        'sub7',
        <img alt="" src={report_icon} style={{ cursor: 'pointer' }} />,
    ),
];

export const tabLinks = [
    { pathname: '/manage-payments', key: '13' },
    { pathname: '/payment-withdraw-requests', key: '14' },
    { pathname: '/manage-payments?tab=mp', key: '21' },
    { pathname: '/manage-payments?tab=ar', key: '22' },
    { pathname: '/manage-payments?tab=mth', key: '23' },
    { pathname: '/manage-payments?tab=ab', key: '24' },
    { pathname: '/payment-withdraw-requests?tab=ad', key: '25' },
    { pathname: '/payment-withdraw-requests?tab=ppc', key: '26' },
    { pathname: '/manage-payments?tab=in', key: '27' },
];
