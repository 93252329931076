import { Button, Typography } from '@mui/material';

const PrimaryBtn = ({
    text = 'text',
    bgColor = '#272727',
    color = '#FFFFFF',
    onHoverBg = null,
    startIcon = null,
    onClick = null,
    fullWidth = null,
    style = {},
    fontStyle = {},
}) => {
    const hoverBgColor = onHoverBg || bgColor;

    return (
        <Button
            fullWidth={fullWidth}
            variant="contained"
            onClick={onClick}
            startIcon={startIcon}
            sx={{
                backgroundColor: bgColor,
                fontSize: '13px',
                color: color,
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: hoverBgColor,
                },
                ...style,
            }}
        >
            <Typography sx={fontStyle}>{text}</Typography>
        </Button>
    );
};

export default PrimaryBtn;
