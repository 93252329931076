import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dev from '../../services/axios-client';
import Pagination from '../Pagination';

const convertDate = (isoString) => {
    const date = new Date(isoString);

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const day = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const dayOfMonth = date.getUTCDate();
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    const formattedDate = `${day} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}`;

    return formattedDate;
};

const CampaignTable = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState({});
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        getAllCampaigns();
        //eslint-disable-next-line
    }, []);

    const getAllCampaigns = async (pageNumber = 1) => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/dashboard/table?page=${pageNumber}&per_page=${page?.per_page}`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setRows(response.data.data.recentCampaigns);
                    setPage(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handlePageChange = async (page) => {
        await getAllCampaigns(page);
    };

    return (
        <Grid xs={12}>
            <Paper sx={{ borderRadius: 0, overflowX: 'auto' }}>
                <Table
                    sx={{
                        minHeight: '115px',

                        'thead tr': {
                            borderBottom: '10px solid rgb(241, 241, 241)',
                        },
                        'tbody tr': {
                            borderBottom: '5px solid #D7D7D7',
                            ':last-child': {
                                borderBottom: 'none',
                            },
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Campaign
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                S-Date
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                E-Date
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                    wordBreak: 'break-word',
                                }}
                            >
                                Impressions
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Clicks
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Spend
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Avg-Engagement
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100px', // Adjust this height as needed
                                        }}
                                    >
                                        <CircularProgress
                                            sx={{ color: '#6c5ffc' }}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <>
                            {rows.length > 0 ? (
                                <TableBody>
                                    {rows.map((campaign) => (
                                        <TableRow key={campaign.id}>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                    color: '#007AFF',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        `/campaign-details/${campaign?.id}`,
                                                    )
                                                }
                                            >
                                                <Tooltip
                                                    title={campaign?.title}
                                                >
                                                    {campaign?.title.length > 12
                                                        ? `${campaign?.title.slice(
                                                              0,
                                                              20,
                                                          )}...`
                                                        : campaign?.title}
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                }}
                                            >
                                                {convertDate(
                                                    campaign.createdAt,
                                                ) || 'Thu Mar 05 2020 22:46'}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                }}
                                            >
                                                {convertDate(campaign.endsIn) ||
                                                    'Thu Mar 05 2020 22:46'}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                }}
                                            >
                                                {campaign.impressions || 0}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                }}
                                            >
                                                {campaign.clicks || 0}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                    color: '#34C759',
                                                }}
                                            >
                                                {campaign.spend || 0}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                }}
                                            >
                                                {campaign.engagement || 0}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderInline:
                                                        '1px solid #F2F2F7',
                                                    color: campaign?.isActive
                                                        ? '#34C759'
                                                        : 'red',
                                                }}
                                            >
                                                {campaign?.isActive
                                                    ? 'Live'
                                                    : 'Ended'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100px', // Adjust this height as needed
                                                }}
                                            >
                                                Currently you have no campaigns
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </>
                    )}
                </Table>
            </Paper>
            {rows.length > 0 && (
                <Grid xs={12} md={12} lg={12} mt="20px">
                    <Pagination
                        style
                        currentPage={page?.page}
                        pageSize={page?.per_page}
                        totalUsers={page?.total}
                        onPageChange={handlePageChange}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default CampaignTable;
