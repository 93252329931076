import * as React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  InputBase,
  Alert,
} from "@mui/material";
import CustomizedInputsStyled from "../Text/StandardTextField";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import { useLocation } from "react-router-dom";
import { enGBShortDateFormat } from "../../services/helpers";
import dev from "../../services/axios-client";
import UploadFileModal from "./UploadFileModal";

const ShowPaymentRequest = () => {
  const loc = useLocation();
  const payment = loc.state?.item;

  const [requestData, setRequestData] = React.useState(payment);
  const [isError, setIsError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [responseReceived, setResponseReceived] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const isPaymentTransferred = requestData.status === "Transferred";
  const isPaymentRejected = requestData.status === "Rejected";
  const isPaymentApproved = requestData.status === "Approved";
  const isPaymentReceived = requestData.status === "Received";

  const shouldRenderMarkReceivedButton =
    isPaymentTransferred && !isPaymentRejected;
  const shouldRenderRejectButton =
    !isPaymentTransferred && !isPaymentRejected && !isPaymentReceived;

  const shouldRenderAttachTransferSlipButton =
    isPaymentApproved && !requestData.receipt;
  const shouldRenderMarkAsTransferredButton =
    isPaymentApproved && !isPaymentRejected;

  const handleAction = async (endpoint, status) => {
    try {
      setResponseReceived(false);

      const response = await dev.put(
        `/payments/${requestData.id}/${endpoint}`,
        {
          ...(status && { status }),
        }
      );
      setIsError(false);
      setMsg(`Payment is now ${response.data.status}`);
      setResponseReceived(true);
      setRequestData(response.data);
    } catch (error) {
      setIsError(true);
      setMsg(error.response?.data?.message || error.message);
      setResponseReceived(true);
    }
  };

  const onTransferClick = (e) => {
    e.preventDefault();
    handleAction("mark/transferred");
  };

  const onReceiveClick = (e) => {
    e.preventDefault();
    handleAction("mark/received");
  };

  const onReject = (e) => {
    e.preventDefault();
    handleAction("update/reject");
  };

  const onApproveClick = (e) => {
    e.preventDefault();
    handleAction("update/status", "Approved");
  };

  return (
    <Container maxWidth="xl">
      <UploadFileModal
        setModal={setModal}
        modal={modal}
        requestData={requestData}
        setMsg={setMsg}
        setIsError={setIsError}
        setResponseReceived={setResponseReceived}
        setRequestData={setRequestData}
      />
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "#272727",
            marginBottom: "1.5rem",
          }}
        >
          Influencer Withdrawal Details - ID #{requestData.id}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "#282F53",
            marginBottom: "2rem",
          }}
        >
          Withdrawal Amount = AED {requestData.amount}
        </Typography>
      </Box>
      <Grid container spacing={2} alignItems="center">
        {responseReceived && (
          <Grid item xs={12} md={8} mb={3}>
            {isError ? (
              <Alert severity="error">{msg}</Alert>
            ) : (
              <Alert severity="success">{msg}</Alert>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <CustomizedInputsStyled
            color="#272727"
            label="Payout Method"
            error={null}
          >
            <InputBase
              fullWidth
              sx={{ ml: 2, flex: 1 }}
              name="payoutMethod"
              id="payout_method"
              value={requestData.payout_method || "Bank Account"}
              disabled
            />
          </CustomizedInputsStyled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedInputsStyled
            color="#272727"
            label="Request Initiation Date"
            error={null}
          >
            <InputBase
              fullWidth
              sx={{ ml: 2, flex: 1 }}
              name="req_init_date"
              id="req_init_date"
              value={enGBShortDateFormat(requestData.createdAt)}
              disabled
            />
          </CustomizedInputsStyled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedInputsStyled
            color="#272727"
            label="Bank Account Number"
            error={null}
          >
            <InputBase
              fullWidth
              sx={{ ml: 2, flex: 1 }}
              name="accountNo"
              id="bank_accont_no"
              value={requestData.accountNo}
              disabled
            />
          </CustomizedInputsStyled>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isPaymentTransferred && (
            <CustomizedInputsStyled
              color="#272727"
              label="Marked As Transferred On"
              error={null}
            >
              <InputBase
                fullWidth
                sx={{ ml: 2, flex: 1 }}
                name="transfer_marked_date"
                id="transfer_marked_date"
                value={requestData.transferred_marked_on}
                disabled
              />
            </CustomizedInputsStyled>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedInputsStyled
            color="#272727"
            label="Influencer Name"
            error={null}
          >
            <InputBase
              fullWidth
              sx={{ ml: 2, flex: 1 }}
              name="influencer_name"
              id="influencer_name"
              value={requestData.user?.name}
              disabled
            />
          </CustomizedInputsStyled>
        </Grid>

        <Grid item xs={12}>
          {shouldRenderAttachTransferSlipButton && (
            <PrimaryBtn
              bgColor="#868686"
              text="Attach Transfer Slip"
              style={{
                marginRight: "5px",
                marginBottom: "5px",
              }}
              onClick={() => setModal(true)}
            />
          )}
          {shouldRenderMarkAsTransferredButton && (
            <PrimaryBtn
              text="Mark as Transferred"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              onClick={(e) => onTransferClick(e)}
            />
          )}
          {shouldRenderRejectButton && (
            <PrimaryBtn
              bgColor="#FF4321"
              text="Reject"
              style={{
                marginRight: "5px",
                marginBottom: "5px",
              }}
              onClick={(e) => onReject(e)}
            />
          )}
          {shouldRenderMarkReceivedButton && (
            <PrimaryBtn
              text="Mark Received"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              onClick={(e) => onReceiveClick(e)}
            />
          )}
          {isPaymentRejected && (
            <PrimaryBtn
              text="Approve"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              onClick={(e) => onApproveClick(e)}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShowPaymentRequest;
