import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
} from '@mui/material';
import validationService from '../../services/validationService';
import dev from '../../services/axios-client';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CalenderIcon from '../../assets/Calendar_light.png';
import Loader from '../Loader';

const PendingCampaigns = ({ getAllCampaigns, tabKey }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [pending, setPending] = useState([]);

    let isMerchantUser = false;

    useEffect(() => {
        if (tabKey === 0) getPendingCampaigns();
        //eslint-disable-next-line
    }, [tabKey]);

    const getPendingCampaigns = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                user?.user?.merchant
                    ? `/campaign/pending/${user?.user?.merchant?.id}?page=${page}&per_page=1000`
                    : `/campaign/pending?page=${page}&per_page=1000`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setPending(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    if (user?.user?.merchant) isMerchantUser = true;

    const handleDetails = (campaign) => {
        navigate(`/approve-campaign/${campaign.id}`);
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="start"
                columns={12}
                gap={3}
            >
                <Loader loading={loading} />

                {pending.map((campaign, index) => (
                    <Grid xs="auto" key={index}>
                        <Card
                            sx={{
                                width: ['100%', '376px'],
                                height: '397px',
                                borderRadius: '12px',
                            }}
                        >
                            <CardContent>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems={'center'}
                                    height={'169px'}
                                    mb={1}
                                    sx={{
                                        background:
                                            'linear-gradient(111.47deg, #313131 13.55%, #141414 89.64%)',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Red Hat Display',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '24px',
                                            fontWeight: 400,
                                            width: '247px',
                                            color: '#EFEFEF',
                                        }}
                                    >
                                        {campaign.title}
                                    </Typography>
                                </Box>
                                <Box
                                    mt={'20px'}
                                    gap="5px"
                                    width={'178px'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                        background: '#EDFFE8',
                                    }}
                                >
                                    <img
                                        alt=""
                                        width={16}
                                        height={16}
                                        src={CalenderIcon}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Red Hat Display',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            color: '#0D9C00',
                                        }}
                                    >
                                        Submitted on:{' '}
                                        {validationService.formatDate(
                                            campaign.createdAt,
                                        )}
                                    </Typography>
                                </Box>
                                <Typography
                                    color="#000000"
                                    sx={{
                                        mt: '30px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Campaign Premise
                                    <Typography
                                        color="#000000"
                                        sx={{
                                            fontFamily: 'Red Hat Display',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        Instore
                                    </Typography>
                                </Typography>
                                <Typography
                                    color="#000000"
                                    sx={{
                                        mt: '20px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontFamily: 'Red Hat Display',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Platform(s)
                                    <Typography
                                        color="#000000"
                                        sx={{
                                            mb: 1,
                                            fontFamily: 'Red Hat Display',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {campaign?.platforms?.join(',')}
                                    </Typography>
                                </Typography>

                                <Box
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'baseline'}
                                >
                                    <Button
                                        onClick={() => handleDetails(campaign)}
                                        sx={{
                                            mt: '33px',
                                            width: '332px',
                                            height: '33px',
                                            fonFamily: 'Red Hat Display',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            background: '#D4FF02',
                                            borderRadius: '10.06px',
                                            color: '#000000',
                                            border: '1px solid white',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                            ':hover': {
                                                border: '1px solid white',
                                                color: '#000000',
                                                background: '#D4FF02',
                                            },
                                        }}
                                    >
                                        Check & Approve
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default PendingCampaigns;
