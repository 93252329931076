import React, { useState } from 'react';
import {
    Container,
    Box,
    List,
    ListItem,
    ListItemText,
    FormControl,
    InputLabel,
    useTheme,
    useMediaQuery,
    Select,
    MenuItem,
} from '@mui/material';
import 'chart.js/auto';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ListItemIcon } from '@mui/material';
import PlatformOverview from './PlatformOverview';
import PlatformReachSummary from './PlatformReachSummary';

const IconStyle = {
    width: '47px',
    height: '47px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

const InstagramIcon = (
    <div style={IconStyle}>
        <img src="/InstagramIcon.png" alt="insta" />
    </div>
);
const SnapchatIcon = (
    <div style={{ ...IconStyle, backgroundColor: '#FFFC00' }}>
        <img src="/SnapchatIcon.png" alt="insta" />
    </div>
);
const TikTokIcon = (
    <div style={{ ...IconStyle, backgroundColor: '#000000' }}>
        <img src="/TikTokIcon.png" alt="insta" />
    </div>
);

const platforms = [
    {
        name: 'Instagram',
        icon: InstagramIcon,
    },
    {
        name: 'Snapchat',
        icon: SnapchatIcon,
    },
    {
        name: 'TikTok',
        icon: TikTokIcon,
    },
];

const CampaignGoalSummary = () => {
    const [selectedPlatform, setSelectedPlatform] = useState('Instagram');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePlatformClick = (platform) => {
        setSelectedPlatform(platform);
    };

    const handleSelectChange = (event) => {
        setSelectedPlatform(event.target.value);
        handlePlatformClick(event.target.value);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                mt: '20px',
                gap: '20px',
                paddingInline: '0px',
                justifyContent: ['space-around', 'space-between'],
                flexDirection: ['column', null, 'row'],
            }}
        >
            <Box sx={{ minWidth: '280px' }}>
                {isMobile ? (
                    <FormControl fullWidth mt="20px">
                        <InputLabel id="platform-select-label">
                            Platform
                        </InputLabel>
                        <Select
                            labelId="platform-select-label"
                            value={selectedPlatform}
                            label="Platform"
                            onChange={handleSelectChange}
                        >
                            {platforms.map((platform) => (
                                <MenuItem
                                    key={platform.name}
                                    value={platform.name}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {platform.icon}
                                        <Box sx={{ marginLeft: '10px' }}>
                                            {platform.name}
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <List>
                        {platforms.map((platform) => (
                            <ListItem
                                button
                                key={platform.name}
                                selected={selectedPlatform === platform.name}
                                onClick={() =>
                                    handlePlatformClick(platform.name)
                                }
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    '& .MuiListItemText-root': {
                                        transition: 'transform 0.3s',
                                        transform: 'translateX(10px)',
                                    },
                                    '&.Mui-selected': {
                                        background: 'none',
                                        '&:hover': {
                                            background: 'none',
                                        },
                                    },
                                }}
                            >
                                <ListItemIcon>{platform.icon}</ListItemIcon>
                                <ListItemText
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        lineHeight: '25.2px',
                                        textAlign: 'left',
                                    }}
                                    primary={platform.name}
                                />
                                <ArrowDropUpIcon
                                    sx={{
                                        color: '#7A8699',
                                        width: '24px',
                                        height: '24px',
                                        transition: 'transform 0.3s',
                                        transform:
                                            selectedPlatform === platform.name
                                                ? 'rotate(90deg)'
                                                : 'rotate(0deg)',
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: ['space-around', 'space-between'],
                    flexDirection: ['column', null, 'row'],
                    gap: '40px',
                }}
            >
                <Box>
                    <PlatformOverview selectedPlatform={selectedPlatform} />
                </Box>
                <Box>
                    <PlatformReachSummary selectedPlatform={selectedPlatform} />
                </Box>
            </Box>
        </Box>
    );
};

export default CampaignGoalSummary;
