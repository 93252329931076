import { styled } from "styled-components";

export const Card = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 11px 60px 0px rgba(81, 86, 84, 0.1);
  background-color: ${(props) => props.backgroundColor || "#fff"};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: 700;
  font-family: IBM Plex Sans
  line-height: 18px;
  color: #A0AEC0;
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  font-family: IBM Plex Sans
  line-height: 25px;
  color: #2D3748;
  margin: 0
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
`;
