import React, { useEffect, useState } from "react";
import HeaderText from "../../Text/HeaderText";
import { useNavigate, useParams } from "react-router-dom";
import avatar_img from "../../../assets/avatar_img.png";
import TableHead from "@mui/material/TableHead";
import PrimaryBtn from "../../CustomButton/PrimaryBtn";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CampaignPreviewCard from "./CampaignPreview";
import HeaderWrapper from "../../Wrapper/HeaderWrapper";
import dev from "../../../services/axios-client";
import FormRejectionDialog from "../../Popups/formRejectionDialog";
import FormSubmitDialog from "../../Popups/formSubmitDialog";
import Loader from "../../Loader";

const fontStyle = {
  fontFamily: "IBM Plex Sans",
  fontSize: "22px",
  fontWeight: 600,
  lineHeight: "22px",
  color: "#272727",
};

const gridValueWrapperStyle = {
  display: "flex",
  height: "54px",
  flexDirection: "column",
  justifyContent: "space-between",
};

const gridValueStyle = {
  fontFamily: "IBM Plex Sans",
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: "22px",
  color: "#272727",
};

const activeHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#6c5ffc",
  textAlign: "center",
  border: "1px solid #E9EDF4",
  borderBottom: "1px solid white !important",
  borderRadius: "5px 5px 0px 0px",
};

const nonActiveHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#74829C",
  textAlign: "center",
};

export default function ContentApprovalDetail() {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [active, setActive] = useState("instagram");
  const [loading, setLoading] = useState(false);
  const [contentDetail, setContentDetail] = useState(null);
  const [formModal, setFormModal] = useState({ open: false });
  const [disaprovalReason, setDisaprovalReason] = useState(true);

  const [responseModal, setResponseModal] = useState({ open: false });

  const user = JSON.parse(localStorage.getItem("user"));

  console.log({ user });

  useEffect(
    () => {
      if (campaignId) {
        setLoading(true);
        dev
          .get(`/admin/singleCampaign/withPosts?campaignId=${campaignId}`, {
            headers: {
              token: user.token,
            },
          })
          .then((response) => {
            setLoading(false);
            const parsedData = {
              ...response?.data?.data,
              eligibilities: JSON.parse(response?.data?.data?.eligibilities),
              platforms: JSON.parse(response?.data?.data?.platforms),
            };

            setContentDetail(parsedData);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    },
    //eslint-disable-next-line
    [campaignId]
  );

  const handleClickOpen = (value) => {
    setFormModal({
      open: true,
      title: "Send Declined Reason",
      error: true,
    });
  };

  const handleFormClose = (reason) => {
    if (!reason) setDisaprovalReason(false);
    else {
      setDisaprovalReason(true);
      declinedContent({
        campaignId: parseInt(campaignId),
        influencerId: parseInt(contentDetail?.campaignPosts[0]?.influencerId),
        contentId: contentDetail?.campaignPosts[0]?.id,
        reason: reason,
      });
      setFormModal({ open: false });
    }
  };

  const declinedContent = async (data) => {
    await dev
      .post(`/admin/content/decline`, data, {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response) {
          setResponseModal({
            open: true,
            error: false,
            title: response?.data?.message,
          });
        }
      })
      .catch((error) => {
        setResponseModal({
          open: true,
          error: true,
          title: error?.data?.errorr,
        });
      });
  };

  const approveContent = async () => {
    await dev
      .post(
        `/admin/content/approve`,
        {
          campaignId: campaignId,
          influencerId: contentDetail?.campaignPosts[0]?.influencerId,
          contentId: contentDetail?.campaignPosts[0]?.id,
        },
        {
          headers: {
            token: user.token,
          },
        }
      )
      .then((response) => {
        if (response) {
          setResponseModal({
            open: true,
            error: false,
            title: response?.data?.message,
          });
        }
      })
      .catch((error) => {
        setResponseModal({
          open: true,
          error: true,
          title: error?.data?.errorr,
        });
      });
  };

  const handleResponseClose = () => {
    setResponseModal({ open: false });
  };

  return (
    <Grid container sx={{ minHeight: "auto" }}>
      <Loader loading={loading} />
      <FormRejectionDialog
        hasReason={disaprovalReason}
        modal={formModal}
        onClose={handleFormClose}
      />
      <FormSubmitDialog modal={responseModal} onClose={handleResponseClose} />
      <HeaderWrapper>
        <HeaderText
          text="Influencer Management"
          style={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "42px",
          }}
        />
      </HeaderWrapper>
      <Box>
        <Button
          variant="outlined"
          onClick={() => navigate("/influencer-records?active=2")}
          startIcon={<KeyboardBackspaceIcon />}
          sx={{
            mt: { xs: 0, sm: "20px" },
            width: { xs: "100px", md: "auto" },
            height: { xs: "40px", md: "auto" },
            marginLeft: { xs: "10px", md: 0 },
            fontWeight: 600,
            fontSize: "16px",
            color: "#272727",
            borderRadius: "5px",
            fontFamily: "Gilroy",
            border: "1px solid #272727",
            padding: "10px 20px 10px 20px",
            "&:hover": {
              borderColor: "#272727",
              backgroundColor: "inherit",
            },
          }}
        >
          Back
        </Button>
      </Box>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#FFFFFF",
          mt: "20px",
          height: "auto",
          borderRadius: "7px",
          boxShadow: "0px -2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A",
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          margin={"20px 40px 20px 20px"}
        >
          <Box display="flex" gap={"20px"} alignItems={"center"}>
            <Avatar
              src={avatar_img || "/avatar.jpg"}
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              style={{
                width: "110px",
                height: "110px",
                boxShadow: "0px -2px 4px 0px #0000001A",
              }}
            />
            <Box display="flex" flexDirection="column" gap={"10px"}>
              <Typography
                sx={{
                  ...fontStyle,
                  fontWeight: 600,
                }}
              >
                Influencer Name
              </Typography>
              <Typography
                sx={{
                  ...fontStyle,
                  fontWeight: 400,
                }}
              >
                InfluencerID:#123456
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} mt={"30px"} gap={"10px"}>
            <PrimaryBtn
              text="Declined"
              onClick={() => handleClickOpen(0)}
              bgColor="#F9F9F9"
              color="#8D8D8D"
              style={{
                width: "132.5px",
                height: "40px",
                border: "1px solid #8D8D8D",
              }}
            />
            <PrimaryBtn
              text="Approve"
              onClick={approveContent}
              bgColor="#272727"
              style={{ width: "132.5px", height: "40px" }}
            />
          </Box>
        </Box>
        <Grid
          sx={12}
          display="flex"
          flexDirection="column"
          gap="10px"
          width={"95%"}
          margin={"30px"}
        >
          <Grid
            gap="25px"
            display={"flex"}
            padding={"24px 15px"}
            flexDirection={"column"}
            backgroundColor="#FCFCFC"
            borderRadius={"10px"}
            border={"1px solid #E9EDF4"}
          >
            <Typography
              sx={{
                ...fontStyle,
                fontWeight: "bold",
                lineHeight: "28.6px",
                color: "#272727",
              }}
            >
              Campaign Details
            </Typography>
            {contentDetail?.platforms.map((platform, platformIndex) => (
              <Grid key={platformIndex} container direction="row">
                <Grid xs={3} sx={gridValueWrapperStyle}>
                  <Typography>Platform Name</Typography>
                  <Typography sx={gridValueStyle}>
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </Typography>
                </Grid>
                {contentDetail?.eligibilities
                  ?.filter((eligibility) => eligibility.platform === platform)
                  ?.map(({ content, influencers }, contentIndex) => (
                    <Grid xs={3} sx={gridValueWrapperStyle} key={contentIndex}>
                      <Typography>
                        {content.charAt(0).toUpperCase() + content.slice(1)}
                      </Typography>
                      <Typography sx={gridValueStyle}>{influencers}</Typography>
                    </Grid>
                  )) || []}
              </Grid>
            ))}
          </Grid>
          <Grid sx={12}>
            <Typography
              sx={{
                ...fontStyle,
                fontWeight: "bold",
                lineHeight: "28.6px",
                color: "#272727",
              }}
            >
              Platforms
            </Typography>
            <Grid
              xs={12}
              sx={{
                backgroundColor: "#FFFFFF",
                mt: "20px",
                borderRadius: "7px",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "1px solid #E9EDF4",
                      },
                      display: "flex",
                    }}
                  >
                    <TableRow>
                      {contentDetail?.platforms.map((platform, index) => (
                        <TableCell
                          width={"120px"}
                          sx={
                            active === platform ? activeHeader : nonActiveHeader
                          }
                          onClick={() => setActive(platform)}
                        >
                          {platform.charAt(0).toUpperCase() + platform.slice(1)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
              {active === "instagram" && (
                <CampaignPreviewCard
                  posts={
                    contentDetail?.campaignPosts?.filter(
                      (post) => post.platform === "instagram"
                    ) || []
                  }
                />
              )}
              {active === "snapchat" && (
                <CampaignPreviewCard
                  posts={
                    contentDetail?.campaignPosts?.filter(
                      (post) => post.platform === "snapchat"
                    ) || []
                  }
                />
              )}
              {active === "tiktok" && (
                <CampaignPreviewCard
                  posts={
                    contentDetail?.campaignPosts?.filter(
                      (post) => post.platform === "tiktok"
                    ) || []
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
