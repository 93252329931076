import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Divider,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { Card, CardBody, CardHeading } from '../Dashboard/cards/dataCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dev from '../../services/axios-client';
import ActivityChart from './ActivityChart';

export default function ActivityCard() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activity, setActivity] = useState({});
    const [activityTimeRange, setActivityTimeRange] = useState('monthly');

    const user = JSON.parse(localStorage.getItem('user'));

    const getInstagramData = async () => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/platform/social-summary?platform=instagram&filter=${activityTimeRange}`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setActivity(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    useEffect(() => {
        getInstagramData();
    }, [activityTimeRange]);

    return (
        <Card padding="30px 20px" height="322px">
            <CardHeading>
                <Box
                    display={'flex'}
                    justifyContent="space-between"
                    alignItems={'center'}
                    height={'17px'}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 500,
                            letterSpacing: '-0.15399999916553497px',
                        }}
                    >
                        Activity
                    </Typography>
                    <Select
                        IconComponent={(props) => (
                            <KeyboardArrowUpIcon
                                sx={{
                                    fontSize: '16px',
                                    transition: 'transform 1s',
                                    transform: open
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                }}
                                {...props}
                            />
                        )}
                        sx={{
                            width: '100px',
                            height: '47.52px',
                            borderRadius: '1px',
                            fontSize: '12px',
                            textAlign: 'right',
                            backgroundColor: 'transparent',
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 0,
                            },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                },
                        }}
                        value={activityTimeRange}
                        onChange={(e) => setActivityTimeRange(e.target.value)}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                    >
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                </Box>
            </CardHeading>
            <Divider />

            <CardBody>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '210px', // Adjust this height as needed
                        }}
                    >
                        <CircularProgress sx={{ color: '#6c5ffc' }} />
                    </Box>
                ) : (
                    <Box minHeight="210px">
                        <ActivityChart activity={activity} />
                    </Box>
                )}
            </CardBody>
        </Card>
    );
}
