import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Loader from '../../Loader';
import HeaderText from '../../Text/HeaderText';
import HeaderWrapper from '../../Wrapper/HeaderWrapper';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryBtn from '../../CustomButton/PrimaryBtn';
import dev from '../../../services/axios-client';
import FormRejectionDialog from '../../Popups/formRejectionDialog';
import FormSubmitDialog from '../../Popups/formSubmitDialog';
import WrapperStandardTextField from '../../Wrapper/WrapperStandardTextField';
import PreLabel from '../../Text/PreLabel';
import SubHeaderText from '../../Text/SubHeaderText';
import CustomSelect from '../../Input/CustomSelect';
import validationService from '../../../services/validationService';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

const fontStyle = {
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: "'IBM Plex Sans'",
    color: '#272727',
    fontWeight: 400,
    margin: '2px 0 7px 0',
};

const accounts = {
    BARTER: 1,
    NANO: 2,
    MICRO: 3,
    GEMA: 4,
};

export default function RequestDetail() {
    const navigate = useNavigate();
    let { influencerId } = useParams();
    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);
    const [influencer, setInfluencer] = useState(null);
    // const [requiredFields, setRequiredFields] = useState([]);
    const [formModal, setFormModal] = useState({ open: false });
    const [responseModal, setResponseModal] = useState({ open: false });
    const [disaprovalReason, setDisaprovalReason] = useState(true);

    const { register, setValue, handleSubmit, getValues, reset } = useForm();

    const user = JSON.parse(localStorage.getItem('user'));

    // function validateFormData(formData, requiredFields) {
    //     let errors = {};
    //     // Define the required fields

    //     // Check for missing required fields
    //     requiredFields.forEach((field) => {
    //         if (!formData[field]) {
    //             errors[field] = { message: `${field} is required` };
    //         }
    //         if (formData[field] instanceof FileList) {
    //             if (formData[field].length < 1) {
    //                 errors[field] = { message: `${field} is required` };
    //             }
    //         }
    //     });

    //     return errors;
    // }

    // useEffect(() => {
    //     setRequiredFields([
    //         'age',
    //         'gender',
    //         'influencerContentCategory',
    //         'accountsize',
    //     ]);
    // }, []);

    const getInfluencer = () => {
        setLoading(true);
        dev.get(`/admin/getAllInfluencers/${influencerId}`, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                setLoading(false);
                setInfluencer(response?.data?.data);

                response?.data?.data?.influencerStats.forEach((d) => {
                    setValue(
                        `${d?.id}gender`,
                        d?.insights?.profile_details?.gender,
                    );
                    setValue(`${d?.id}account`, accounts[d?.influencer_tier]);
                });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(
        () => {
            if (influencerId) getInfluencer();
        },
        //eslint-disable-next-line
        [influencerId],
    );

    const handleClickOpen = (value) => {
        setFormModal({
            open: true,
            title: 'Send Disapproval Reason',
            error: true,
            email: influencer.email,
            id: influencer.id,
        });
    };

    const handleFormClose = (reason) => {
        if (!reason) setDisaprovalReason(false);
        else {
            setDisaprovalReason(true);

            rejectUser({
                email: formModal.email,
                disapprovalReason: reason,
                id: formModal.id,
            });
            setFormModal({ open: false });
        }
    };

    const rejectUser = async (data) => {
        await dev
            .put(`/admin/disapproveUser`, data, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const ApproveUser = async () => {
        await dev
            .put(
                `/admin/approveUser`,
                { email: influencer.email, id: influencer.id },
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response) {
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.userUpdated
                            ? 'User Approved'
                            : '',
                    });
                }
            })
            .catch((error) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: error?.data?.errorr,
                });
            });
    };

    const handleResponseClose = () => {
        setResponseModal({ open: false });
        if (responseModal.action === 'update') getInfluencer();
    };

    const onChangeHandler = (e, name, influencerInsight) => {
        // console.log(name);
        const { [name]: tmp, ...rest } = errors;
        setErrors(rest);

        setValue(
            name,
            name === `${influencerInsight?.id}age` ||
                name === `${influencerInsight?.id}influencerContentCategory`
                ? e
                : e?.value,
        );
    };

    const handleFormChange = (event) => {
        if (event?.target?.name) {
            const { [event.target.name]: tmp, ...rest } = errors;
            setErrors(rest);
        }
    };

    const onSubmit = (data, influencerInsight) => {
        setLoading(true);
        // const validatedFormData = validateFormData(data, requiredFields);
        // setErrors(validatedFormData);

        // if (Object.entries(validatedFormData).length > 0) {

        //     setResponseModal({
        //         error: true,
        //         open: true,
        //         title: `Enter required fields`,
        //     });
        //     return;
        // }
        const hasCategory = data.hasOwnProperty(
            `${influencerInsight?.id}influencerContentCategory`,
        );
        const hasAge = data.hasOwnProperty(`${influencerInsight?.id}age`);

        const prevValues = influencer?.influencerStats.find(
            (influ) => influ.id === influencerInsight?.id,
        );
        const previousAges = prevValues?.insights?.profile_details?.age_group;
        const previousCategories =
            prevValues?.insights?.profile_details?.category;
        const payload = {
            influencerContentCategory: hasCategory
                ? data[`${influencerInsight?.id}influencerContentCategory`].map(
                      (a) => a?.value,
                  )
                : previousCategories,
            age: hasAge
                ? data[`${influencerInsight?.id}age`].map((a) => a?.label)
                : previousAges,
            gender: data[`${influencerInsight?.id}gender`],
            account: data[`${influencerInsight?.id}account`],
            insightId: influencerInsight?.id,
            influencerId,
        };

        dev.put(`/api/v1/insights/social-media`, payload, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                setLoading(false);
                if (response)
                    setResponseModal({
                        open: true,
                        error: false,
                        title: response?.data?.message,
                        action: 'update',
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleFormSubmit = (influencerInsight) => {
        return handleSubmit((data) => onSubmit(data, influencerInsight));
    };

    return (
        <Grid container sx={{ height: '100%' }}>
            <FormRejectionDialog
                modal={formModal}
                onClose={handleFormClose}
                hasReason={disaprovalReason}
            />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <Loader loading={loading} />
            <HeaderWrapper xs={12}>
                <HeaderText text="Influencer Management" />
            </HeaderWrapper>
            <Grid
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <Button
                    variant="outlined"
                    onClick={() => navigate('/influencer-records?active=0')}
                    startIcon={<KeyboardBackspaceIcon />}
                    sx={{
                        mt: { xs: 0, sm: '20px' },
                        width: { xs: '100px', md: 'auto' },
                        height: { xs: '40px', md: 'auto' },
                        marginLeft: { xs: '10px', md: 0 },
                        fontWeight: 600,
                        fontSize: '16px',
                        color: '#272727',
                        borderRadius: '5px',
                        fontFamily: 'Gilroy',
                        border: '1px solid #272727',
                        padding: '10px 20px 10px 20px',
                        '&:hover': {
                            borderColor: '#272727',
                            backgroundColor: 'inherit',
                        },
                    }}
                >
                    Back
                </Button>
                <Box
                    display={'flex'}
                    gap={'10px'}
                    sx={{
                        width: { xs: '150px', md: 'auto' },
                        marginRight: { xs: '10px', md: 0 },
                    }}
                >
                    <PrimaryBtn
                        text="Approve"
                        onClick={ApproveUser}
                        bgColor="#01AB3B"
                        style={{
                            width: '132.5px',
                            height: '40px',
                            border: '1px solid #8D8D8D',
                        }}
                    />
                    <PrimaryBtn
                        text="Reject"
                        onClick={() => handleClickOpen(0)}
                        bgColor="#E94E51"
                        style={{ width: '132.5px', height: '40px' }}
                    />
                </Box>
            </Grid>
            <Grid
                xs={12}
                sx={{
                    backgroundColor: '#FFFFFF',
                    mt: '20px',
                    padding: '22px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    boxShadow:
                        '0px -2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A',
                }}
            >
                {influencer?.influencerStats
                    ?.filter((insight) => insight.platform !== 'instagram')
                    ?.map((influencerInsight, index) => (
                        <Grid xs={12} key={index}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems={'center'}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: 600,
                                        fontFamily: "'IBM Plex Sans'",
                                    }}
                                >
                                    {influencerInsight.platform[0].toUpperCase() +
                                        influencerInsight.platform.slice(1)}
                                </Typography>
                            </Grid>
                            <form
                                onInput={handleFormChange}
                                id="user-form"
                                noValidate
                                autoComplete="on"
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={['column', null, 'row']}
                                >
                                    <WrapperStandardTextField
                                        style={{
                                            paddingRight: '10px',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <SubHeaderText
                                            text="Influencer age "
                                            color="#282F53"
                                            customstyle={{
                                                fontWeight: 600,
                                            }}
                                            error={errors['age']}
                                        />
                                        <CustomSelect
                                            multi
                                            defaultValue={influencerInsight?.insights?.profile_details?.age_group.map(
                                                (v) => ({
                                                    value: v,
                                                    label: v,
                                                }),
                                            )}
                                            onChange={(e) =>
                                                onChangeHandler(
                                                    e,
                                                    `${influencerInsight?.id}age`,
                                                    influencerInsight,
                                                )
                                            }
                                            placeholder="Select Age"
                                            options={
                                                validationService.ageOptions
                                            }
                                            name={`${influencerInsight?.id}age`}
                                        />
                                    </WrapperStandardTextField>
                                    <WrapperStandardTextField
                                        style={{
                                            paddingRight: '0px',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <SubHeaderText
                                            text="Influencer gender "
                                            color="#282F53"
                                            customstyle={{
                                                fontWeight: 600,
                                            }}
                                            error={errors['gender']}
                                        />
                                        <CustomSelect
                                            onChange={onChangeHandler}
                                            placeholder="Select Gender"
                                            options={
                                                validationService.genderOptions
                                            }
                                            name={`${influencerInsight?.id}gender`}
                                            getValues={getValues}
                                        />
                                    </WrapperStandardTextField>
                                </Box>
                                <Box
                                    display={'flex'}
                                    flexDirection={['column', null, 'row']}
                                >
                                    <WrapperStandardTextField
                                        lg={12}
                                        style={{
                                            paddingRight: '10px',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <SubHeaderText
                                            customstyle={{ fontWeight: 600 }}
                                            text="Influencer Content Category "
                                            color="#282F53"
                                            error={
                                                errors[
                                                    'influencerContentCategory'
                                                ]
                                            }
                                        />
                                        <CustomSelect
                                            multi
                                            defaultValue={influencerInsight?.insights?.profile_details?.category.map(
                                                (v) => ({
                                                    value: v,
                                                    label: v,
                                                }),
                                            )}
                                            onChange={(e) =>
                                                onChangeHandler(
                                                    e,
                                                    `${influencerInsight?.id}influencerContentCategory`,
                                                    influencerInsight,
                                                )
                                            }
                                            placeholder="Select Content Category"
                                            options={
                                                validationService.ContentCategoryOptions
                                            }
                                            name={`${influencerInsight?.id}influencerContentCategory`}
                                        />
                                    </WrapperStandardTextField>
                                    <WrapperStandardTextField
                                        lg={12}
                                        style={{
                                            paddingRight: '0px',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <SubHeaderText
                                            customstyle={{ fontWeight: 600 }}
                                            text="Account size "
                                            color="#282F53"
                                            error={errors['account']}
                                        />
                                        <CustomSelect
                                            onChange={onChangeHandler}
                                            placeholder="Select Account Size"
                                            options={[
                                                { label: 'Barter', value: 1 },
                                                { label: 'Nano', value: 2 },
                                                { label: 'Micro', value: 3 },
                                                { label: 'Gema', value: 4 },
                                            ]}
                                            name={`${influencerInsight?.id}account`}
                                            getValues={getValues}
                                        />
                                    </WrapperStandardTextField>
                                </Box>
                                <WrapperStandardTextField md={12} lg={12}>
                                    <LoadingButton
                                        variant="contained"
                                        type="button"
                                        onClick={handleFormSubmit(
                                            influencerInsight,
                                        )}
                                        sx={{
                                            backgroundColor: '#272727',
                                            color: '#FFFFFF',
                                            textTransform: 'capitalize',
                                            mt: '30px',
                                            padding: '0.5rem 3.7rem',
                                            '&:hover': {
                                                backgroundColor: '#272727',
                                            },
                                        }}
                                    >
                                        Update
                                    </LoadingButton>
                                </WrapperStandardTextField>
                            </form>
                        </Grid>
                    ))}
                <Grid
                    xs={12}
                    sx={{
                        maxHeight: '600px',
                        borderRadius: '10px',
                        backgroundColor: '#FCFCFC',
                        border: '1px solid #E9EDF4',
                        padding: '20px 20px 40px 20px', // Adjust padding according to screen size
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Typography
                            style={{
                                fontSize: '22px',
                                fontWeight: 600,
                                fontFamily: "'IBM Plex Sans'",
                            }}
                        >
                            Influencer Details
                        </Typography>
                    </Grid>
                    <Grid container direction="row" mt="20px">
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            >
                                Name
                            </p>
                            <p
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}
                            >
                                {influencer?.name}
                            </p>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            >
                                @Username
                            </p>
                            <p
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}
                            >
                                {influencer?.username}
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={1}
                            md={1}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            >
                                Location
                            </p>
                            <p
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}
                            >
                                {influencer?.city}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" mt={'10px'}>
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={4}
                            xl={4}
                            direction={'column'}
                        >
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            >
                                Email
                            </p>
                            <p
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                }}
                            >
                                {influencer?.email}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                {influencer?.influencerStats?.map((stats) => (
                    <Grid
                        xs={12}
                        sx={{
                            maxHeight: '600px',
                            borderRadius: '10px',
                            backgroundColor: '#FCFCFC',
                            border: '1px solid #E9EDF4',
                            padding: '20px 20px 40px 20px', // Adjust padding according to screen size
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography
                                style={{
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    fontFamily: "'IBM Plex Sans'",
                                }}
                            >
                                {stats?.platform[0].toUpperCase() +
                                    stats.platform.slice(1)}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" mt="20px">
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                xl={4}
                                direction={'column'}
                            >
                                <p
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                    }}
                                >
                                    @Username
                                </p>
                                <p
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {stats?.handle}
                                </p>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={1}
                                md={1}
                                lg={4}
                                xl={4}
                                direction={'column'}
                            >
                                <p
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                    }}
                                >
                                    Location
                                </p>
                                <p
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {influencer?.location}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
