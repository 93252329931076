import React from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables, ChartDataLabels);

const data = {
    labels: ['a', 'b', 'c', 'd'],
    datasets: [
        {
            label: '',
            backgroundColor: ['#D4FF02', '#F80022', '#F84C02', '#5100F3'],
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 10,
            barPercentage: 0.6,
            data: [65, 59, 180, 81],
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            display: true,
            color: '#828282',
            anchor: 'end',
            align: 'start',
            offset: -20,
            font: {
                weight: 600,
                size: 12,
                family: 'Manrope',
            },
        },
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                border: false,
            },
        },

        y: {
            display: false,
            beginAtZero: true,
            grid: {
                display: false,
                border: false,
            },
            ticks: {
                stepSize: 100,
            },
        },
    },
};

export default function PerformanceChart() {
    return <Bar data={data} options={options} />;
}
