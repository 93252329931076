import * as React from "react";
import { Grid } from "@mui/material";

export default function HeaderWrapper({ children, sx }) {
  return (
    <Grid
      xs={12}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: { xs: "10px", sm: "0px" },
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
}
