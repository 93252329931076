import { styled } from "styled-components";

export const Card = styled.div`
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.backgroundColor || "#fff"};
  padding: ${(props) => props.padding || "2rem 1.5rem"};
`;

export const CardHeading = styled.div`
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  font-family: IBM Plex Sans;
  color: "#2D3748 !important";
  margin-bottom: 8px;
`;

export const CardBody = styled.div`
  padding: 0.5rem 0;
  height: ${(props) => props.height || "auto"};
  overflow: auto;

  @media (min-width: 900px) and (max-width: 1024px) {
    height: ${(props) => (props.height ? "9rem" : "auto")};
  }

  @media (min-width: 1025px) and (max-width: 1450px) {
    height: ${(props) => (props.height ? "9.5rem" : "auto")};
  }
`;
