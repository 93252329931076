import {
    Box,
    Container,
    Divider,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Card, CardBody, CardHeading } from '../Dashboard/cards/dataCard';
import ActivityChart from './ActivityChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LineChart from './LineChart';
import AreaChart from './AreaChart';
import UserInfo from '../Instagram/UserInfo';
import LineChart2 from './LineChart2';
import SouthIcon from '@mui/icons-material/South';
import HeaderWrapper from '../Wrapper/HeaderWrapper';
import HeaderText from '../Text/HeaderText';

export default function Snapchat() {
    const [activityTimeRange, setActivityTimeRange] = useState('This Month');
    const [timeRange, setTimeRange] = useState('This Month');
    const [activityOpen, setActivityOpen] = useState(false);
    const [open, setOpen] = useState(false);
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeaderWrapper>
                        <HeaderText
                            text="Snapchat Details"
                            color="#272727"
                            style={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: 700,
                                fontSize: '24px',
                                lineHeight: '36px',
                                letterSpacing: '-0.03em',
                            }}
                        />
                    </HeaderWrapper>
                </Grid>
                {/* Activity Chart */}
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="30px 20px" height="322px">
                        <CardHeading>
                            <Box
                                display={'flex'}
                                justifyContent="space-between"
                                alignItems={'center'}
                                height={'17px'}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '17.4px',
                                        fontWeight: 600,
                                        color: '#05004E',
                                    }}
                                >
                                    Activity
                                </Typography>
                                <Select
                                    IconComponent={(props) => (
                                        <KeyboardArrowUpIcon
                                            sx={{
                                                fontSize: '25px',
                                                transition: 'transform 1s',
                                                transform: activityOpen
                                                    ? 'rotate(90deg)'
                                                    : 'rotate(0deg)',
                                            }}
                                            {...props}
                                        />
                                    )}
                                    sx={{
                                        width: '100px',
                                        height: '47.52px',
                                        borderRadius: '1px',
                                        fontSize: '12px',
                                        textAlign: 'right',
                                        backgroundColor: 'transparent',
                                        '.MuiMenu-root': {
                                            fontFamily: 'Plus Jakarta Sans',
                                        },
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: 0,
                                        },
                                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                                border: 0,
                                            },
                                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                border: 0,
                                            },
                                    }}
                                    value={activityTimeRange}
                                    onChange={(e) =>
                                        setActivityTimeRange(e.target.value)
                                    }
                                    onOpen={() => setActivityOpen(true)}
                                    onClose={() => setActivityOpen(false)}
                                >
                                    <MenuItem value="This Week">Week</MenuItem>
                                    <MenuItem value="This Month">
                                        Month
                                    </MenuItem>
                                    <MenuItem value="This Year">Year</MenuItem>
                                </Select>
                            </Box>
                        </CardHeading>

                        <CardBody>
                            <Grid height={'270px'}>
                                <ActivityChart />
                            </Grid>
                            <Box
                                display={'flex'}
                                gap="40px"
                                justifyContent={'center'}
                                mt="10px"
                            >
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9.6px',
                                            height: '9.6px',
                                            backgroundColor: '#0095FF',
                                            borderRadius: '50%',
                                            border: '1px solid #0095FF',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Open Sans',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Sanp view
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9.6px',
                                            height: '9.6px',
                                            backgroundColor: '#1B59F8',
                                            borderRadius: '50%',
                                            border: '1px solid #1B59F8',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Open Sans',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Replay rate
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9.6px',
                                            height: '9.6px',
                                            backgroundColor: '#D3F26A',
                                            borderRadius: '50%',
                                            border: '1px solid #D3F26A',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Open Sans',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Average view
                                    </Typography>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
                {/* Audience Engagement Chart */}
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="30px 20px">
                        <CardHeading>Visitor Insights</CardHeading>
                        <CardBody>
                            <LineChart />
                            <Box
                                display={'flex'}
                                gap="100px"
                                justifyContent={'center'}
                                mt="10px"
                            >
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9.6px',
                                            height: '9.6px',
                                            backgroundColor: '#A700FF',
                                            borderRadius: '20%',
                                            border: '1px solid #A700FF',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Epilogue',
                                            fontSize: '10.79px',
                                            fontWeight: 500,
                                            color: '#464E5F',
                                        }}
                                    >
                                        Completion rates
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9.6px',
                                            height: '9.6px',
                                            backgroundColor: '#EF4444',
                                            borderRadius: '20%',
                                            border: '1px solid #EF4444',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Epilogue',
                                            fontSize: '10.79px',
                                            fontWeight: 500,
                                            color: '#464E5F',
                                        }}
                                    >
                                        Completion rates
                                    </Typography>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
                {/* Ad Details */}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="30px 20px">
                        <CardHeading>Demographic Breakdown</CardHeading>
                        <CardBody>
                            <AreaChart />
                            <Divider
                                sx={{
                                    height: '1px',
                                    width: '368px',
                                    background: '#EDF2F6',
                                    marginY: 2,
                                }}
                            />
                            <Grid display={'flex'} gap={'30px'}>
                                <BodyCard
                                    name="Viewers"
                                    value="1234"
                                    bgColor="#0080DA"
                                    justifyContent="center"
                                />
                                <Divider
                                    sx={{
                                        height: '24px',
                                        width: '1px',
                                        background: '#BDC9D3',
                                    }}
                                />
                                <BodyCard
                                    name="Engagement Times"
                                    value="2 min 40 minutes"
                                    bgColor="#05C283"
                                />
                            </Grid>
                        </CardBody>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginBottom: 2 }}>
                    <Card padding="10px 20px">
                        <CardHeading>
                            <Grid
                                display="flex"
                                justifyContent="space-between"
                                alignItems={'center'}
                            >
                                <Grid
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap="8px"
                                >
                                    <Typography
                                        sx={{
                                            fonFamily: 'Plus Jakarta Sans',
                                            fontSize: '17px',
                                            fontWeight: 600,
                                            color: '#000000',
                                        }}
                                    >
                                        Screenshot of post
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Plus Jakarta Sans',
                                            fontSize: '20px',
                                            fontWeight: 500,
                                            color: '#000000',
                                        }}
                                    >
                                        2.568
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#737B8B',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            letterSpacing: '0.5px',
                                        }}
                                    >
                                        <SouthIcon
                                            sx={{
                                                color: '#F2383A',
                                                fontSize: '12px',
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                color: '#F2383A',
                                            }}
                                        >
                                            2.1%
                                        </Typography>
                                        vs last week
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '13px',
                                            fontWeight: 400,
                                            color: '#000000',
                                            opacity: 0.5,
                                        }}
                                    >
                                        Sales from 1-6 Dec, 2020
                                    </Typography>
                                </Grid>

                                <Select
                                    IconComponent={(props) => (
                                        <KeyboardArrowUpIcon
                                            sx={{
                                                fontSize: '16px',
                                                transition: 'transform 1s',
                                                transform: open
                                                    ? 'rotate(180deg)'
                                                    : 'rotate(0deg)',
                                            }}
                                            {...props}
                                        />
                                    )}
                                    sx={{
                                        width: '140px',
                                        height: '47.52px',
                                        borderRadius: '12px',
                                        fontSize: '12px',
                                        color: 'white',
                                        backgroundColor: '#007AFF',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '12px',
                                        },
                                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderRadius: '12px',
                                            },
                                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderRadius: '12px',
                                            },
                                        '.MuiSvgIcon-root ': {
                                            fill: 'white !important',
                                        },
                                    }}
                                    value={timeRange}
                                    onChange={(e) =>
                                        setTimeRange(e.target.value)
                                    }
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                >
                                    <MenuItem value="This Week">
                                        This Week
                                    </MenuItem>
                                    <MenuItem value="This Month">
                                        This Month
                                    </MenuItem>
                                    <MenuItem value="This Year">
                                        This Year
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </CardHeading>
                        <CardBody>
                            <LineChart2 />
                            <Box display={'flex'} gap="50px" mt="10px">
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9px',
                                            height: '9px',
                                            backgroundColor: '#5A6ACF',
                                            borderRadius: '50%',
                                            border: '1px solid #5A6ACF',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Last 6 days
                                    </Typography>
                                </Box>
                                <Box
                                    display={'flex'}
                                    gap={'5px'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            width: '9px',
                                            height: '9px',
                                            backgroundColor: '#D8D9DB',
                                            borderRadius: '50%',
                                            border: '1px solid #D8D9DB',
                                        }}
                                    ></Typography>
                                    <Typography
                                        sx={{
                                            fonFamily: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#121212',
                                        }}
                                    >
                                        Last week
                                    </Typography>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={5}>
                    <UserInfo />
                </Grid>
            </Grid>
        </Box>
    );
}

const BodyCard = ({ name, value, bgColor, justifyContent }) => {
    return (
        <Grid display={'flex'} gap="10px" justifyContent={justifyContent}>
            <Typography
                sx={{
                    width: '19.85px',
                    height: '6.4px',
                    mt: '5px',
                    backgroundColor: bgColor,
                    borderRadius: '10.24px',
                    borderBlock: `1px solid ${bgColor}`,
                }}
            ></Typography>
            <Grid item xs={6} sm={3} width="90px">
                <Typography
                    sx={{
                        fonFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#7A8699',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    sx={{
                        fonFamily: 'Poppins',
                        fontSize: '12.8px',
                        fontWeight: 700,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};
