import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default function LineChart({
    spendings,
    engagement,
    others,
    performanceData = [],
}) {
    const labels =
        performanceData.length && performanceData?.map((d) => d?.week);
    const spendingsData =
        performanceData.length && performanceData?.map((d) => d?.spendings);
    const othersData =
        performanceData.length && performanceData?.map((d) => d?.others);
    const engagementData =
        performanceData.length && performanceData?.map((d) => d?.engagement);
    const data = {
        labels,
        datasets: [
            {
                label: 'Spendings',
                data: spendings ? spendingsData : [],
                borderColor: '#007AFF',
                backgroundColor: '#007AFF',
                hidden: !spendings,
                tension: 0.4,
                pointRadius: 0,
                borderWidth: 8,
            },
            {
                label: 'Engagement',
                data: engagement ? engagementData : [],
                borderColor: '#D3F26A',
                backgroundColor: '#D3F26A',
                hidden: !engagement,
                tension: 0.4,
                pointRadius: 0,
                borderWidth: 8,
            },
            {
                label: 'Others',
                data: others ? othersData : [],
                borderColor: 'grey',
                backgroundColor: 'grey',
                hidden: !others,
                tension: 0.4,
                pointRadius: 0,
                borderWidth: 8,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Weeks',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Values',
                },
            },
        },
    };
    return <Line data={data} options={options} />;
}
