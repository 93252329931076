import { styled } from "styled-components";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const StyledLinearProgressWrapper = styled.div`
  position: relative;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  height: 10px;
  border-radius: 5px;

  &.${linearProgressClasses.colorPrimary} {
    background-color: #E2E8F0;
  }

  .${linearProgressClasses.bar} {
    border-radius: 5px;
    background-color: #D4FF02;
  }
`;

export const PercentageValue = styled.span`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 14px;
  color: #272727;
  font-family: IBM Plex Sans;
`;
