import styled from 'styled-components';
import { Menu } from 'antd';
const fontFamily = 'IBM Plex Sans';

export const MainMenu = styled(Menu)`
    font-family: ${fontFamily};
    .ant-menu-submenu-open > .ant-menu-sub > .ant-menu-submenu {
        background-color: #0e3228 !important;
    }

    .ant-menu-submenu-open > .ant-menu-sub {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .ant-menu-item {
        height: 60px;
    }
    .ant-menu-item:hover {
        background-color: white !important;
        color: black !important;
    }
    .ant-menu-title-content > .platform {
        color: white !important;
    }

    .ant-menu-item-selected > .ant-menu-title-content > .platform {
        color: black !important;
    }
    .ant-menu-item-active > .ant-menu-title-content > .platform {
        color: black !important;
    }

    .ant-menu-item-group-list
        > .ant-menu-submenu
        > .ant-menu-sub
        > .ant-menu-submenu
        > .ant-menu-submenu-title
        > .ant-menu-submenu-arrow {
        display: none;
    }
    .ant-menu-item-group-list
        > .ant-menu-submenu
        > .ant-menu-sub
        > .ant-menu-submenu
        > .ant-menu-submenu-title {
        height: 26px;
        color: white !important;
    }

    .ant-menu-item-group-list
        > .ant-menu-submenu
        > .ant-menu-sub
        > .ant-menu-submenu {
        background: unset !important;
        color: white !important;
    }

    .ant-menu-sub {
        background: white !important;
    }

    .ant-menu-item-selected {
        background-color: white !important;
        color: #6c5ffc !important;
    }

    .ant-menu-item-group-title {
        font-size: 12px !important;
        font-weight: 700 !important;
        padding-left: 24px;
    }

    .ant-menu-item {
        font-size: 13.3px;
        color: #74829c;
    }

    .ant-menu-submenu-title {
        font-size: 16px;
        font-weight: 500 !important;
        color: #74829c;
    }
    .ant-menu-submenu > .ant-menu-submenu-title {
        height: 60px;
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: #8d8d8d !important;
    }

    .ant-menu-item.ant-menu-item-selected,
    .ant-menu-submenu-title.ant-menu-submenu-open {
        font-size: 15px;
        color: #6c5ffc;
    }
    .ant-menu-submenu-open,
    .ant-menu-sub {
        background-color: #0e3228 !important;
    }

    .ant-menu-submenu-open > .ant-menu-submenu-title {
        color: white !important;
    }
    .ant-menu-submenu-open > .ant-menu-submenu-title > img {
        filter: brightness(0) invert(1);
    }

    .ant-menu-submenu-open > .ant-menu-submenu-title > .sub-child-title {
        color: #a4f400 !important;
    }

    .ant-menu-submenu-open
        > .ant-menu-submenu-title
        > .ant-menu-item-icon.circle {
        min-width: 8px;
        height: 8px;
        background-color: #a4f400;
        border-radius: 50%;
        border: 1px solid #0e3228;
    }

    .ant-menu-item-only-child {
        padding-left: 24px !important;
        border-radius: unset !important;
        margin-left: 50px;
        color: white !important;
        height: 26px;
    }
    .ant-menu-item-selected {
        color: black !important;
    }
    .ant-menu-submenu-open
        > .ant-menu-sub
        > .ant-menu-submenu
        > .ant-menu-submenu-title
        > .sub-child-title {
        color: white;
    }
    .ant-menu .ant-menu-submenu-arrow {
        color: white;
    }
`;
