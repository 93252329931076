import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import WarningIcon from "../../assets/warning_icon.png";
import dev from "../../services/axios-client";

const InvoiceDeleteModal = ({
  showModal,
  onClose,
  invoiceID,
  getAllInvoices,
}) => {
  const handleDeleteInvoice = async () => {
    try {
      const response = await dev.delete(
        `/admin/invoice/delete?id=${invoiceID}`
      );
      onClose();
      getAllInvoices();
      console.log(response.data?.data?.message);
    } catch (error) {
      onClose();
      alert(error.response?.data?.message || error.message);
    }
  };
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          width: { sm: "22%", xs: "90%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            borderRadius: "12px",
            paddingTop: "2rem",
          }}
        >
          <img src={WarningIcon} alt="warning icon" height="50" width="50" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            borderRadius: "10px",
            margin: "16px 30px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mt: "30px",
                color: "#272727",
              }}
            >
              Are You sure you want to delete this invoice?
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} sx={{ gap: "10px", m: "30px 40px" }}>
          <PrimaryBtn
            text="No, Cancel"
            fullWidth={true}
            bgColor="white"
            color="#303038"
            onClick={onClose}
            style={{
              border: "1px solid #E9EDF4",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "500",
            }}
          />
          <PrimaryBtn
            text="Yes, Delete"
            fullWidth={true}
            bgColor="#D92D20"
            onClick={handleDeleteInvoice}
            style={{
              fontSize: "16px",
              fontWeight: "500",
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default InvoiceDeleteModal;
