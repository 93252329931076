import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import exportFromJSON from 'export-from-json';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    InputBase,
} from '@mui/material';
import WrapperStandardTextField from '../Wrapper/WrapperStandardTextField';
import SubHeaderText from '../Text/SubHeaderText';
import CustomSelect from '../Input/CustomSelect';
import CustomizedInputsStyled from '../Text/StandardTextField';
import dev from '../../services/axios-client';
import styled from 'styled-components';
import { GetCity, GetState } from 'react-country-state-city';
import Loader from '../Loader';

const StyledDateInput = styled.input`
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    &:hover {
        border-color: #0056b3;
    }

    &::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
    }
`;

const Reports = () => {
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState('');
    const [response, setResponse] = useState(null);
    const [adminMerchants, setAdminMerchants] = useState([]);
    const [data, setData] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));

    const {
        register,
        watch,
        setValue,
        formState: { errors },
        reset,
        getValues,
        handleSubmit,
    } = useForm();

    console.log({ errors });

    const reportFields = user?.user?.merchant
        ? {
              'Influencer Performance Report': [
                  { name: 'startDate', label: 'Start Date', type: 'date' },
                  { name: 'endDate', label: 'End Date', type: 'date' },
                  {
                      name: 'influencer',
                      label: 'Influencer',
                      type: 'select',
                      options: response?.influencers.map((influencer) => ({
                          value: influencer?.id,
                          label: influencer?.name,
                      })),
                  },
                  {
                      name: 'campaignId',
                      label: 'Campaign',
                      type: 'select',
                      options: response?.campaigns.map((campaign) => ({
                          value: campaign?.id,
                          label: campaign?.title,
                      })),
                  },
                  {
                      name: 'metrics',
                      label: 'Engagement Metrics',
                      type: 'select',
                      options: [
                          { value: 'likes', label: 'Likes' },
                          { value: 'comments', label: 'Comments' },
                          { value: 'shares', label: 'Shares' },
                      ],
                  },
                  {
                      name: 'contentType',
                      label: 'Content Type',
                      type: 'select',
                      options: [
                          { value: 'posts', label: 'Posts' },
                          { value: 'stories', label: 'Stories' },
                          { value: 'videos', label: 'Videos' },
                      ],
                  },
              ],
          }
        : {
              'Brand Engagement Report': [
                  {
                      name: 'merchantId',
                      label: 'Merchant',
                      type: 'select',
                      options: adminMerchants,
                  },
                  {
                      name: 'campaignId',
                      label: 'Campaign',
                      type: 'select',
                      options: response?.campaigns.map((campaign) => ({
                          value: campaign?.id,
                          label: campaign?.title,
                      })),
                  },
                  { name: 'startDate', label: 'Start Date', type: 'date' },
                  { name: 'endDate', label: 'End Date', type: 'date' },
                  {
                      name: 'city',
                      label: 'City',
                      type: 'select',
                      options: cities,
                  },
                  {
                      name: 'metrics',
                      label: 'Engagement Metrics',
                      type: 'select',
                      options: [
                          { value: 'likes', label: 'Likes' },
                          { value: 'comments', label: 'Comments' },
                          { value: 'shares', label: 'Shares' },
                      ],
                  },
                  {
                      name: 'contentType',
                      label: 'Content Type',
                      type: 'select',
                      options: [
                          { value: 'posts', label: 'Posts' },
                          { value: 'stories', label: 'Stories' },
                          { value: 'videos', label: 'Videos' },
                      ],
                  },
                  {
                      name: 'gender',
                      label: 'Gender',
                      type: 'select',
                      options: [
                          { value: 'female', label: 'Female' },
                          { value: 'male', label: 'Male' },
                          { value: 'all', label: 'All' },
                      ],
                  },
                  {
                      name: 'age',
                      label: 'Age',
                      type: 'number',
                  },
                  {
                      name: 'reach',
                      label: 'Reach',
                      type: 'number',
                  },
                  {
                      name: 'impressions',
                      label: 'Impressions',
                      type: 'number',
                  },
              ],
          };

    const getAllMerchants = (merchantId) => {
        setLoading(true);
        dev.get(
            `/api/v1/report/merchant/influencer-campaigns${
                merchantId ? `?merchantId=${merchantId}` : ''
            }`,
            {
                headers: {
                    token: user.token,
                },
            },
        )
            .then((response) => {
                setResponse(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };
    const getAllMerchantsForAdmin = () => {
        setLoading(true);
        fetchCities();
        dev.get(`/api/v1/report/admin/merchants-list`, {
            headers: {
                token: user.token,
            },
        })
            .then((response) => {
                const merchatns = response.data.data.map((merchant) => ({
                    value: merchant?.id,
                    label: merchant?.name,
                }));
                setAdminMerchants(merchatns);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const fetchCities = () => {
        const UAE_COUNTRY_ID = 231;

        let stateIdsArray = [];
        GetState(UAE_COUNTRY_ID).then((result) => {
            stateIdsArray = result.map((state) => state.id);

            Promise.all(
                stateIdsArray.map((stateId) =>
                    GetCity(UAE_COUNTRY_ID, stateId).then(
                        (citiesOfState) => citiesOfState,
                    ),
                ),
            )
                .then((citiesArrays) => {
                    const allCities = citiesArrays.flat();
                    const formattedCities = allCities
                        .map((city) => ({
                            label: city.name,
                            value: city.name,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    setCities(formattedCities);
                })
                .catch((error) => {
                    console.error('Error fetching cities:', error);
                });
        });
    };

    const renderTableHeader = (data) => {
        const headers = new Set();

        const collectHeaders = (obj) => {
            Object.keys(obj)?.forEach((key) => {
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    collectHeaders(obj[key]);
                } else {
                    headers.add(key);
                }
            });
        };

        data?.forEach((item) => collectHeaders(item));

        return Array.from(headers)?.map((key) => <th key={key}>{key}</th>);
    };

    const renderTableRows = (data) => {
        return data?.map((item, index) => (
            <tr key={index}>
                {Object.keys(item)?.map((key) => (
                    <td key={key}>{renderTableCell(item[key])}</td>
                ))}
            </tr>
        ));
    };

    const renderTableCell = (cellData) => {
        if (typeof cellData === 'object' && !Array.isArray(cellData)) {
            return (
                <table className="inner-table" border="1">
                    <tbody>
                        {Object.keys(cellData).map((subKey) => (
                            <tr key={subKey}>
                                <td>{subKey}</td>
                                <td>{renderTableCell(cellData[subKey])}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (Array.isArray(cellData)) {
            return (
                <ul className="inner-list">
                    {cellData.map((item, index) => (
                        <li key={index}>{renderTableCell(item)}</li>
                    ))}
                </ul>
            );
        } else {
            return cellData;
        }
    };

    const onSubmit = (data) => {
        const influencer = response?.influencers?.find(
            (inf) => inf?.id === data?.influencer,
        );
        const merchant = adminMerchants?.find(
            (mer) => mer?.id === data?.merchantId,
        );
        let url =
            reportType === 'Influencer Performance Report'
                ? `/reports/influencer-performance?startDate=${data?.startDate}&endDate=${data?.endDate}&influencerId=${influencer?.id}&influencerName=${influencer?.name}&campaignId=${data?.campaignId}&merchantId=${user?.user?.merchant?.id}`
                : reportType === 'Brand Engagement Report'
                ? `/api/v1/report/admin/brand-engagement?startDate=${data?.startDate}&endDate=${data?.endDate}&gender=${data?.gender}&merchantName=${merchant?.label}&campaignId=${data?.campaignId}&merchantId=${data?.merchantId}&metrics=${data?.metrics}&contentType=${data?.contentType}&age=${data?.age}&city=${data?.city}&reach=${data?.reach}&impressions=${data?.impressions}`
                : '';
        dev.get(url, { headers: { token: user.token } })
            .then((response) => {
                setData(response?.data?.data);
                const fileName = reportType;
                const data = Array.isArray(response?.data?.data)
                    ? {
                          ...response?.data?.data,
                          ...response?.data?.data?.campaigns[0]?.users[0],
                      }
                    : [
                          {
                              ...response?.data?.data,
                              ...response?.data?.data?.campaigns[0]?.users[0],
                          },
                      ];

                const exportType = exportFromJSON.types.csv;
                exportFromJSON({ data, fileName, exportType });
            })
            .catch((error) => {
                console.log(error);
            });
        // Add logic to generate report
    };

    const handleReportTypeChange = (event) => {
        const { value } = event.target;
        if (value === 'Influencer Performance Report') getAllMerchants();
        if (value === 'Brand Engagement Report') getAllMerchantsForAdmin();
        setReportType(value);
    };

    const onChangeHandler = (e, name) => {
        if (name === 'merchantId') getAllMerchants(e?.value);
        // const { [name]: tmp, ...rest } = errors;
        // setErrors(rest);
        setValue(name, e?.value);
    };
    const renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <CustomizedInputsStyled
                        required={true}
                        label={field.label}
                        // error={errors["country"]}
                        mt="10px"
                    >
                        <InputBase
                            fullWidth
                            sx={{
                                ml: 2,
                                flex: 1,
                                color: '#808080',
                                fontSize: '14px',
                                lineHeight: '21px',
                            }}
                            placeholder={field.label}
                            type={'text'}
                            inputProps={{ 'aria-label': field.name }}
                            {...register(field.name, {
                                required: `${field.name} is required`,
                            })}
                        />
                    </CustomizedInputsStyled>
                );
            case 'number':
                return (
                    <>
                        <CustomizedInputsStyled
                            required={false}
                            label={field.label}
                            error={errors[field.name]}
                            mt="10px"
                        >
                            <InputBase
                                fullWidth
                                sx={{
                                    ml: 2,
                                    flex: 1,
                                    color: '#808080',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                }}
                                placeholder={field.label}
                                type={'number'}
                                inputProps={{ 'aria-label': field.name }}
                                {...register(field.name, {
                                    required: `${field.name} is required`,
                                })}
                            />
                        </CustomizedInputsStyled>
                        {/* {errors[field.name] && (
              <span style={{ color: "red" }}>{errors[field.name].message}</span>
            )} */}
                    </>
                );
            case 'select':
                return (
                    <WrapperStandardTextField>
                        <SubHeaderText
                            customstyle={{ fontWeight: 600 }}
                            text={field.label}
                            required={false}
                            color="#282F53"
                        />
                        <CustomSelect
                            onChange={onChangeHandler}
                            placeholder={field.label}
                            options={field.options}
                            name={field.name}
                            getValues={getValues}
                        />
                    </WrapperStandardTextField>
                );
            case 'date':
                return (
                    <>
                        <SubHeaderText
                            customstyle={{ fontWeight: 600 }}
                            text={field.label}
                            required={false}
                            color="#282F53"
                        />
                        <StyledDateInput
                            {...field}
                            type="date"
                            id="birthday"
                            name={field.name}
                            {...register(field.name)}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Loader loading={loading} />
            <form
                onSubmit={handleSubmit(onSubmit, () => console.log('error'))}
                //onInput={handleFormChange}
                id="user-form"
                noValidate
                autoComplete="on"
            >
                <FormControl fullWidth margin="normal">
                    <InputLabel id="report-type-label">Report Type</InputLabel>
                    <Select
                        labelId="report-type-label"
                        id="report-type"
                        value={reportType}
                        label="Report Type"
                        onChange={handleReportTypeChange}
                    >
                        {Object.keys(reportFields).map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {reportType &&
                    reportFields[reportType]?.map((field) =>
                        renderField(field),
                    )}

                <Button
                    sx={{ mt: '20px' }}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Generate Report
                </Button>
            </form>
            <div className="app-container">
                <h1>Dynamic Table</h1>
                <div className="table-container">
                    <table className="outer-table" border="1">
                        <thead>
                            <tr>{renderTableHeader(data)}</tr>
                        </thead>
                        <tbody>{renderTableRows(data)}</tbody>
                    </table>
                </div>
            </div>
        </Box>
    );
};

export default Reports;
