import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const dataValues = [65, 59, 180, 81, 56, 270, 40, 372, 70, 75, 300, 400];
const peakValue = 400;
const differenceValues = dataValues.map((value) => peakValue - value);
const data = {
    labels: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ],
    datasets: [
        {
            label: '',
            backgroundColor: '#0095FF',
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 0,

            data: dataValues,
        },
        {
            label: '',
            backgroundColor: '#1B59F8',
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 0,

            data: differenceValues,
        },
        {
            label: '',
            backgroundColor: '#D3F26A',
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 0,

            data: differenceValues,
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                border: false,
            },
        },

        y: {
            beginAtZero: true,
            grid: {
                // display: false,
                border: false,
            },
            ticks: {
                stepSize: 100,
            },
        },
    },
};

const ActivityChart = () => {
    return <Bar data={data} options={options} />;
};

export default ActivityChart;
