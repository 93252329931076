import {
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import validationService from '../../services/validationService';
import dev from '../../services/axios-client';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CalenderIcon from '../../assets/Calendar_light.png';
import Loader from '../Loader';
import FormSubmitDialog from '../Popups/formSubmitDialog';
import WarningDialog from '../Popups/WarningDialog';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const CompletedCampaignBox = ({ campaign }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl;
    const [modal, setModal] = useState({ open: false });
    const [loading, setLoading] = useState(false);
    const [responseModal, setResponseModal] = useState({ open: false });
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const handleClick = (event, campaign) => {
        setAnchorEl(event.currentTarget);
        setSelectedCampaign(campaign);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCancelAndDelete = () => {
        setModal({
            open: true,
            title: 'Are you sure you want to delete this campaign?',
            cancelBtn: 'No, Keep It',
            submitBtn: 'Yes, Delete',
        });
    };
    const handleDeleteDocument = async (doc) => {
        setLoading(true);
        await dev
            .delete(`/campaign/${doc.id}`, {
                data: { docName: doc.docName },
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data) {
                    setResponseModal({
                        open: true,
                        title: 'Campaign deleted successfully',
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setResponseModal({
                    error: true,
                    open: true,
                    title: 'Failed to delete campaign',
                });
            });
    };

    const handleDelete = (flag) => {
        if (flag) {
            handleDeleteDocument(selectedCampaign);
        }
        setModal({ ...modal, open: false });
    };

    const handleDetails = () => {
        navigate(`/view-campaigns-details/${selectedCampaign.id}`);
    };

    const handleResponseClose = () => {
        // getAllCampaigns();
        setResponseModal({ open: false });
    };

    return (
        <>
            <Loader loading={loading} />
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
            />
            <WarningDialog modal={modal} onClose={handleDelete} />
            <Card
                sx={{
                    width: ['100%', '376px'],
                    height: '397px',
                    borderRadius: '12px',
                }}
            >
                <CardContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems={'center'}
                        height={'169px'}
                        mb={1}
                        sx={{
                            background:
                                'linear-gradient(111.47deg, #313131 13.55%, #141414 89.64%)',
                            borderRadius: '4px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Red Hat Display',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '24px',
                                fontWeight: 400,
                                width: '247px',
                                color: '#EFEFEF',
                            }}
                        >
                            {campaign.title}
                        </Typography>
                    </Box>
                    <Box
                        mt={'20px'}
                        gap="5px"
                        width={'178px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            background: '#EDFFE8',
                        }}
                    >
                        <img alt="" width={16} height={16} src={CalenderIcon} />
                        <Typography
                            sx={{
                                fontFamily: 'Red Hat Display',
                                fontSize: '12px',
                                fontWeight: 600,
                                color: '#0D9C00',
                            }}
                        >
                            Submitted on:{' '}
                            {validationService.formatDate(campaign.createdAt)}
                        </Typography>
                    </Box>
                    <Typography
                        color="#000000"
                        sx={{
                            mt: '30px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontFamily: 'Red Hat Display',
                            fontSize: '12px',
                            fontWeight: 600,
                        }}
                    >
                        Campaign Premise
                        <Typography
                            color="#000000"
                            sx={{
                                fontFamily: 'Red Hat Display',
                                fontSize: '12px',
                                fontWeight: 400,
                            }}
                        >
                            {campaign?.campaignType}
                        </Typography>
                    </Typography>
                    <Typography
                        color="#000000"
                        sx={{
                            mt: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontFamily: 'Red Hat Display',
                            fontSize: '12px',
                            fontWeight: 600,
                        }}
                    >
                        Platform(s)
                        <Typography
                            color="#000000"
                            sx={{
                                mb: 1,
                                fontFamily: 'Red Hat Display',
                                fontSize: '12px',
                                fontWeight: 400,
                            }}
                        >
                            {campaign?.platforms?.join(',')}
                        </Typography>
                    </Typography>

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'baseline'}
                    >
                        <Button
                            sx={{
                                mt: '30px',
                                width: '111px',
                                height: '33px',
                                fonFamily: 'Red Hat Display',
                                fontSize: '14px',
                                fontWeight: 400,
                                background: '#ECECEC',
                                borderRadius: '10.06px',
                                color: '#000000',
                                border: '1px solid white',
                                textTransform: 'none',
                                cursor: 'inherit',
                                ':hover': {
                                    border: '1px solid white',
                                    color: '#000000',
                                    background: '#ECECEC',
                                },
                            }}
                        >
                            Completed
                        </Button>
                        <div>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, campaign)}
                            >
                                <MoreHorizIcon sx={{ fontSize: '35px' }} />
                            </IconButton>

                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleDetails}>
                                    View Details
                                </MenuItem>
                                <MenuItem onClick={handleCancelAndDelete}>
                                    Cancel & Delete
                                </MenuItem>
                            </Menu>
                        </div>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default CompletedCampaignBox;
