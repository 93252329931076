import { Box, Divider, Grid, Typography } from '@mui/material';
// import { object, string } from "zod";
import { useState } from 'react';
import HeaderText from '../Text/HeaderText';
import { useNavigate } from 'react-router';
// import RecordBox from "./RecordBox";
import PrimaryButton from '../Buttons/PrimaryButton';
import { PastCampaigns } from './PastCampaigns';
import CurrentCampaigns from './CurrentCampaigns';
import PendingCampaigns from './PendingCampaigns';

const ViewCampaigns = () => {
    let isMerchantUser = false;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.user?.merchant) isMerchantUser = true;

    const [tabKey, setTabKey] = useState(isMerchantUser ? 1 : 0);
    const navigate = useNavigate();

    const activeHeader = {
        cursor: 'pointer',

        color: '#000000',
        textAlign: 'center',
        border: '1px solid #E9EDF4',
        borderRadius: '10px',
        backgroundColor: '#D4FF02',
        transition:
            'background-color 0.3s ease, color 0.3s ease, padding 0.3s ease',
    };

    return (
        <Grid container sx={{ backgroundColor: 'white', height: '100%' }}>
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="20px"
                    mb="20px"
                >
                    <HeaderText
                        color="#000000"
                        text={`View All Campaigns`}
                        style={{
                            fontFamily: 'Red Hat Display',
                            fontSize: '32px',
                            fontWeight: 700,
                        }}
                    />
                    <PrimaryButton
                        text={'+ Create New Campaign'}
                        onClick={() => navigate('/create-new-campaign')}
                    />
                </Grid>
                <Divider
                    sx={{
                        width: '100%',
                        marginY: '20px',
                        border: '1px solid #E9EDF4',
                    }}
                />

                <Box
                    sx={{
                        background: '#F3F3F3',
                        borderRadius: '10px',
                        display: 'flex',
                        width: isMerchantUser ? '300px' : '500px',
                    }}
                >
                    {!isMerchantUser && (
                        <Typography
                            onClick={() => setTabKey(0)}
                            sx={tabKey === 0 && activeHeader}
                            style={{
                                width: '72px',
                                animation: 'fadeInRight 0.5s',
                                padding: '10px 50px',
                                fontSize: '22px',
                                lineHeight: '22px',
                                fontWeight: '500',
                                fontFamily: 'Red Hat Display',
                            }}
                        >
                            Pending
                        </Typography>
                    )}

                    <Typography
                        sx={tabKey === 1 && activeHeader}
                        style={{
                            width: '72px',
                            animation: 'fadeInRight 0.5s',
                            padding: '10px 50px',
                            fontSize: '22px',
                            lineHeight: '22px',
                            fontWeight: '500',
                            fontFamily: 'Red Hat Display',
                        }}
                        onClick={() => setTabKey(1)}
                    >
                        Current
                    </Typography>
                    <Typography
                        sx={tabKey === 2 && activeHeader}
                        style={{
                            width: '72px',
                            animation: 'fadeInRight 0.5s',
                            padding: '10px 50px',
                            fontSize: '22px',
                            lineHeight: '22px',
                            fontWeight: '500',
                            fontFamily: 'Red Hat Display',
                        }}
                        onClick={() => setTabKey(2)}
                    >
                        Past
                    </Typography>
                </Box>

                <Grid xs={12}>
                    <Box mt={2}>
                        {tabKey === 0 && <PendingCampaigns tabKey={tabKey} />}
                        {tabKey === 1 && <CurrentCampaigns tabKey={tabKey} />}
                        {tabKey === 2 && <PastCampaigns index={tabKey} />}
                    </Box>
                </Grid>
            </>
        </Grid>
    );
};

export default ViewCampaigns;
