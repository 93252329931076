import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { Container, Typography } from '@mui/material';
import NewMainAuthContainer from '../NewMainAuthContainer';
import PrimaryBtn from '../CustomButton/PrimaryBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import dev from '../../services/axios-client';
import FormSubmitDialog from '../Popups/formSubmitDialog';

export const OtpInput = ({ length = 4, onChange }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        onChange(otp.join(''));
    }, [otp, onChange]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d+$/.test(paste) && paste.length === length) {
            setOtp(paste.split(''));
            inputRefs.current[length - 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '100%',
                gap: 1,
            }}
            onPaste={handlePaste}
        >
            {Array.from({ length }).map((_, index) => (
                <TextField
                    key={index}
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputRef={(ref) => (inputRefs.current[index] = ref)}
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                    }}
                    sx={{ width: 50 }}
                />
            ))}
        </Box>
    );
};

const Otp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [token, setToken] = useState('');
    const [responseModal, setResponseModal] = useState({ open: false });

    const handleResponseClose = () => {
        setResponseModal({
            open: false,
            error: responseModal?.error ? true : false,
        });
        if (responseModal.success)
            navigate('/merchant/reset-password', { state: { token } });
        setOtp('');
    };
    const handleOtp = () => {
        const data = {
            email: location.state.email,
            code: otp,
        };

        if (otp.length !== 6) return alert('Please enter 6 digit otp');

        dev.post('/merchant/verify-reset-password-otp', data)
            .then((res) => {
                if (res) {
                    setToken(res.data.token);
                    setResponseModal({
                        open: true,
                        error: false,
                        title: res?.data?.message,
                        success: true,
                    });
                }
            })
            .catch(({ response }) => {
                setResponseModal({
                    open: true,
                    error: true,
                    title: response?.data?.message,
                });
            });
    };

    return (
        <NewMainAuthContainer>
            <FormSubmitDialog
                modal={responseModal}
                onClose={handleResponseClose}
                text={'OK'}
            />
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontWeight: '500',
                        fontFamily: 'Clash Display',
                        color: '#000000',
                        marginTop: '20px',
                    }}
                >
                    Enter Otp
                </Typography>
                <OtpInput length={6} onChange={(value) => setOtp(value)} />

                <PrimaryBtn
                    text="Verify"
                    fullWidth={true}
                    onClick={handleOtp}
                    style={{
                        backgroundColor: '#01AB3B',
                        mt: '20px',
                    }}
                />
            </Container>
        </NewMainAuthContainer>
    );
};

export default Otp;
