import {
    Box,
    Divider,
    LinearProgress,
    Skeleton,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import dev from '../../../services/axios-client';

const OverViewLabelValueStyle = {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
};

const IconStyle = {
    width: '47px',
    height: '47px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};

const PlatformOverview = ({ selectedPlatform }) => {
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [platformOverview, setPlatformOverview] = useState({});

    console.log({ loading, platformOverview });

    useEffect(() => {
        if (selectedPlatform) {
            getPlatformOverview();
        }
        //eslint-disable-next-line
    }, [selectedPlatform]);

    const getPlatformOverview = async () => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/dashboard/social-summary?platform=${selectedPlatform.toLocaleLowerCase()}&filter=monthly`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setPlatformOverview(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    return (
        <Box
            sx={{
                padding: '40px 40px 30px 20px',
                width: ['80%', '90%', '300px'],
                maxHeight: '372px',
                borderRadius: '12px',
                background: '#FFFFFF',
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Box
                    style={{
                        ...IconStyle,
                        width: '61.22px',
                        height: '61.22px',
                        backgroundColor:
                            selectedPlatform === 'Snapchat'
                                ? '#FFFC00'
                                : selectedPlatform === 'TikTok'
                                ? '#000000'
                                : '#FF285C',
                    }}
                >
                    <img
                        src={`/${selectedPlatform}Icon.png`}
                        alt={selectedPlatform}
                    />
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '16px',
                        fontWeight: 500,
                        ml: '20px',
                    }}
                >
                    {loading ? (
                        <Skeleton width={150} />
                    ) : (
                        `${selectedPlatform} Overview`
                    )}
                </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />

            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                <Typography sx={OverViewLabelValueStyle}>Reach</Typography>
                <Typography
                    sx={{
                        ...OverViewLabelValueStyle,
                        color: '#7A8699',
                    }}
                >
                    {loading ? (
                        <Skeleton width={40} />
                    ) : (
                        <>{platformOverview?.totalReach ?? 0}</>
                    )}
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                <Typography sx={OverViewLabelValueStyle}>Comments</Typography>
                <Typography
                    sx={{
                        ...OverViewLabelValueStyle,
                        color: '#7A8699',
                    }}
                >
                    {loading ? (
                        <Skeleton width={40} />
                    ) : (
                        <>{platformOverview?.totalComments}</>
                    )}
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                <Typography sx={OverViewLabelValueStyle}>Likes</Typography>
                <Typography
                    sx={{
                        ...OverViewLabelValueStyle,
                        color: '#7A8699',
                    }}
                >
                    {loading ? (
                        <Skeleton width={40} />
                    ) : (
                        <>{platformOverview?.totalLikes}</>
                    )}
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                <Typography sx={OverViewLabelValueStyle}>Story View</Typography>
                <Typography
                    sx={{
                        ...OverViewLabelValueStyle,
                        color: '#7A8699',
                    }}
                >
                    {loading ? (
                        <Skeleton width={40} />
                    ) : (
                        <>{platformOverview?.totalViews}</>
                    )}
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                <Typography sx={OverViewLabelValueStyle}>Post Save</Typography>
                <Typography
                    sx={{
                        ...OverViewLabelValueStyle,
                        color: '#7A8699',
                    }}
                >
                    {loading ? (
                        <Skeleton width={40} />
                    ) : (
                        <>{platformOverview?.totalPosts}</>
                    )}
                </Typography>
            </Box>

            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Box
                    style={{
                        ...IconStyle,
                        width: '61.22px',
                        height: '61.22px',
                        backgroundColor:
                            selectedPlatform === 'Snapchat'
                                ? '#FFFC00'
                                : selectedPlatform === 'TikTok'
                                ? '#000000'
                                : '#FF285C',
                    }}
                >
                    <img
                        src={`/${selectedPlatform}Icon.png`}
                        alt={selectedPlatform}
                    />
                </Box>
                <Box
                    display={'flex'}
                    alignItems={'start'}
                    flexDirection={'column'}
                >
                    <Typography>
                        {loading ? <Skeleton width={100} /> : selectedPlatform}
                    </Typography>
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            width={196}
                            height={10}
                            sx={{ mt: '15px', borderRadius: '5px' }}
                        />
                    ) : (
                        <LinearProgress
                            sx={{
                                width: '196px',
                                height: '10px',
                                mt: '15px',
                                borderRadius: '5px',
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor:
                                        selectedPlatform === 'Snapchat'
                                            ? '#FFFC00'
                                            : selectedPlatform === 'TikTok'
                                            ? '#000000'
                                            : '#FF285C',
                                },
                                backgroundColor: '#CDCCC8',
                            }}
                            variant="determinate"
                            value={platformOverview?.percentageChange}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default PlatformOverview;
