import React, { useEffect } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Grid,
    CardContent,
    Card,
    CircularProgress,
} from '@mui/material';
import Verify from '../../assets/detailcampaign/verify.png';
import dev from '../../services/axios-client';
import Pagination from '../Pagination';

const UserInfo = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [rows, setRows] = React.useState([]);
    const [pagination, setPagination] = React.useState({ per_page: 2 });
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        getUserInfo();
        //eslint-disable-next-line
    }, []);

    const getUserInfo = async (page = 1) => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/influencerStats/platform-wise?page=${page}&perPage=${pagination?.per_page}&platform=instagram`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setRows(response.data.data);
                    setPagination({ ...response.data, per_page: 2 });
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handlePageChange = async (page) => {
        await getUserInfo(page);
    };
    return (
        <Grid>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '280px', // Adjust this height as needed
                    }}
                >
                    <CircularProgress sx={{ color: '#6c5ffc' }} />
                </Box>
            ) : (
                <Box>
                    {rows?.map((data, index) => (
                        <UserInforCard key={index} data={data} />
                    ))}
                </Box>
            )}

            <Grid mt="20px">
                <Pagination
                    style
                    currentPage={pagination?.page}
                    pageSize={pagination?.per_page}
                    totalUsers={pagination?.total}
                    onPageChange={handlePageChange}
                />
            </Grid>
        </Grid>
    );
};

export default UserInfo;

const UserInforCard = ({ data }) => {
    return (
        <Grid mt={'20px'}>
            <Card>
                <CardContent>
                    <Grid display={'flex'} lg={8}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Avatar
                                    alt="Orkun Işıtmak"
                                    src="path/to/avatar.jpg"
                                />
                            </Grid>
                            <Grid
                                item
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'start'}
                            >
                                <Typography
                                    sx={{
                                        fonFamily: 'Plus Jakarta Sans',
                                        fontSize: '14.49px',
                                        fontWeight: 600,
                                    }}
                                    textAlign="center"
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    {data?.handle}{' '}
                                    <img
                                        src={Verify}
                                        alt=""
                                        width={12.08}
                                        height={12.08}
                                    />
                                </Typography>
                                <Typography
                                    sx={{
                                        mb: 1,
                                        fonFamily: 'Plus Jakarta Sans',
                                        fontSize: '11.27px',
                                        fontWeight: 600,
                                    }}
                                    color="#00000080"
                                    textAlign="center"
                                >
                                    {data?.city}{' '}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography
                                sx={{
                                    fonFamily: 'Plus Jakarta Sans',
                                    fontSize: '19.29px',
                                    fontWeight: 600,
                                }}
                                textAlign="center"
                            >
                                {data?.followers_count || 0}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                textAlign="center"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '10.47px',
                                    fontWeight: 500,
                                }}
                            >
                                Followers
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        mt={'20px'}
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <BodyCard
                            name="Specific Engagement"
                            value={data?.overall_engagement ?? 0}
                            bgColor="007AFF"
                        />

                        <BodyCard
                            name="Income"
                            value={data?.totalEarnings}
                            bgColor="#FF8723"
                        />

                        <BodyCard
                            name="Expense"
                            value={data?.expense}
                            bgColor="#32D16D"
                        />

                        <BodyCard
                            name="Follower Growth"
                            value={
                                data?.insights?.get_growth_trends
                                    ?.monthly_followers ?? 0
                            }
                            bgColor="#FD5353"
                        />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const BodyCard = ({ bgColor, name, value }) => {
    return (
        <Grid display={'flex'} gap="10px">
            <Typography
                sx={{
                    width: '6.4px',
                    height: '19.85px',
                    mt: '5px',
                    backgroundColor: bgColor,
                    borderRadius: '10.24px',
                    borderBlock: `1px solid ${bgColor}`,
                }}
            ></Typography>
            <Grid item xs={6} sm={3} width="82px">
                <Typography
                    sx={{
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '12.8px',
                        fontWeight: 700,
                    }}
                >
                    {value}
                </Typography>

                <Typography
                    sx={{
                        fonFamily: 'Plus Jakarta Sans',
                        fontSize: '8.96px',
                        fontWeight: 400,
                        color: '#7A8699',
                    }}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
};
