import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const labelStyle = {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20.52px',
    fontWeight: '700',
    lineHeight: '25.85px',
};

export default function TopCard({ background, color, name, value }) {
    return (
        <Card
            sx={{
                background: background,
                color: color,
                minWidth: ['95%', '95%', '230px'],
                minHeight: ['112px', '112px', '92px'],
            }}
        >
            <CardContent>
                <Typography
                    sx={{ ...labelStyle, fontSize: ['26px', '24px', '20px'] }}
                >
                    {value}
                </Typography>
                <Typography
                    sx={{
                        ...labelStyle,
                        fontSize: ['24px', '20px', '16px'],
                    }}
                >
                    {name}
                </Typography>
            </CardContent>
        </Card>
    );
}
