import React from "react";
import { Typography, Container } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

const NotAuthorized = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        textAlign: "center"
      }}
    >
      <BlockIcon sx={{ fontSize: 150, marginBottom: 2 }} color="#272727" />
      <Typography variant="h3" sx={{ marginBottom: 2 }} color="#2D3748">
        Oops! Access Denied
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Sorry, you do not have permission to view this page.
      </Typography>
    </Container>
  );
};

export default NotAuthorized;
