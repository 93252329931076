import {
    Box,
    CircularProgress,
    Container,
    FormControlLabel,
    MenuItem,
    Select,
    Skeleton,
    Switch,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import LineChart from './LineChart';
import dev from '../../services/axios-client';

const IconStyle = {
    width: '43px',
    height: '43px',
    borderRadius: '12px',
    display: 'flex',
    backgroundColor: '#FF285C',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px' /* Adjust font size as needed */,
    fontWeight: 'bold' /* Adjust font weight as needed */,
    textAlign: 'center' /* Center text horizontally */,
    lineHeight: '47px' /* Center text vertically */,
};
const labelStyle = {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20.52px',
    fontWeight: '700',
    lineHeight: '25.85px',
};

const PerformanceGraph = () => {
    const [spendings, setSpendings] = useState(true);
    const [engagement, setEngagement] = useState(false);
    const [others, setOthers] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [timeRange, setTimeRange] = useState(1);

    const user = JSON.parse(localStorage.getItem('user'));

    const getPerformanceData = async () => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/performance-graph?engagement=${Number(
                    engagement,
                )}&spending=${Number(spendings)}&others=${Number(
                    others,
                )}&month=${timeRange}&year=2024`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setData(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    useEffect(() => {
        getPerformanceData();
        //eslint-disable-next-line
    }, [spendings, engagement, others, timeRange]);

    return (
        <>
            <Box
                sx={{
                    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    borderRadius: '19.4px',
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                >
                    <Typography sx={{ ...labelStyle, fontSize: '17.46px' }}>
                        Chart Activity
                    </Typography>
                    <Box display={'flex'} gap="50px">
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={spendings}
                                        onChange={() =>
                                            setSpendings(!spendings)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Spendings"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={engagement}
                                        onChange={() =>
                                            setEngagement(!engagement)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Engagement"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={others}
                                        onChange={() => setOthers(!others)}
                                        color="primary"
                                    />
                                }
                                label="Others"
                            />
                        </Box>
                        <Select
                            sx={{
                                color: 'white',
                                width: '161px',
                                height: '47.52px',
                                borderRadius: '12px',
                                backgroundColor: '#007AFF',
                                fontFamily: 'Plus Jakarta Sans',
                                '.MuiSvgIcon-root ': {
                                    fill: 'white !important',
                                },
                            }}
                            value={timeRange}
                            onChange={(e) => setTimeRange(e.target.value)}
                        >
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>Feburary</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </Box>
                </Box>

                <Box
                    sx={{
                        height: ['250px', '300px', '360px', '600px'], // Fixed height to prevent jerking effect
                    }}
                >
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // Adjust this height as needed
                            }}
                        >
                            <CircularProgress sx={{ color: '#6c5ffc' }} />
                        </Box>
                    ) : (
                        <LineChart
                            spendings={spendings}
                            engagement={engagement}
                            others={others}
                            performanceData={data?.data}
                        />
                    )}
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={['column', null, 'row']}
                    alignItems="center"
                    mt={2}
                >
                    <Box display={'flex'} gap={['10px', '20px']}>
                        <Box display={'flex'} gap={'5px'}>
                            <Typography
                                sx={{
                                    width: '18.43px',
                                    height: '18.43px',
                                    backgroundColor: '#007AFF',
                                    borderRadius: '50%',
                                    border: '1px solid #007AFF',
                                }}
                            ></Typography>
                            <Typography>Spendings</Typography>
                        </Box>
                        <Box display={'flex'} gap={'5px'}>
                            <Typography
                                sx={{
                                    width: '18.43px',
                                    height: '18.43px',
                                    backgroundColor: '#34C759',
                                    borderRadius: '50%',
                                    border: '1px solid #34C759',
                                }}
                            ></Typography>
                            <Typography>Engagement</Typography>
                        </Box>
                        <Box display={'flex'} gap={'5px'}>
                            <Typography
                                sx={{
                                    width: '18.43px',
                                    height: '18.43px',
                                    backgroundColor: '#D7D7D7',
                                    borderRadius: '50%',
                                    border: '1px solid #D7D7D7',
                                }}
                            ></Typography>
                            <Typography>Others</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} gap={['10px', '20px']} mt="20px">
                        <Box display={'flex'} gap="20px" alignItems="center">
                            <div style={IconStyle}>
                                <img src="/InstagramIcon.png" alt="insta" />
                            </div>
                            <Typography>Instagram</Typography>
                        </Box>
                        <Box display={'flex'} gap="20px" alignItems="center">
                            <div
                                style={{
                                    ...IconStyle,
                                    backgroundColor: '#FFFC00',
                                }}
                            >
                                <img src="/SnapchatIcon.png" alt="snap" />
                            </div>
                            <Typography>Snapchat</Typography>
                        </Box>
                        <Box display={'flex'} gap="20px" alignItems="center">
                            <div
                                style={{
                                    ...IconStyle,
                                    backgroundColor: '#000000',
                                }}
                            >
                                <img src="/TikTokIcon.png" alt="tik" />
                            </div>
                            <Typography>TikTok</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PerformanceGraph;
