import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import dev from '../../../services/axios-client';

const PlatformReachSummary = ({ selectedPlatform }) => {
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [reachSummary, setReachSummary] = useState({});
    const [selectedFilter, setSelectedFilter] = useState('Monthly');

    console.log({ loading, reachSummary });

    useEffect(() => {
        if (selectedFilter) {
            getPlatformReachSummary();
        }
        //eslint-disable-next-line
    }, [selectedFilter, selectedPlatform]);

    const getPlatformReachSummary = async () => {
        setLoading(true);
        await dev
            .get(
                `/api/v1/dashboard/social-summary?platform=${selectedPlatform.toLocaleLowerCase()}&filter=${selectedFilter.toLocaleLowerCase()}`,
                {
                    headers: {
                        token: user.token,
                    },
                },
            )
            .then((response) => {
                if (response.data) {
                    setReachSummary(response.data.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };
    // Sample data for the area chart
    const data = {
        labels: [
            'August',
            'September',
            'October',
            'November',
            'December',
            'January',
            'Feburary',
        ],
        datasets: [
            {
                label: 'Reach',
                data: reachSummary?.reachData?.map((d) => d?.reach),
                fill: true,
                backgroundColor: '#386FFE',
                borderColor: '#386FFE',
                lineTension: 0.3,
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'category',
                beginAtZero: true,
                ticks: {
                    fontSize: '9.14px',
                    fontWeight: 700,
                    lineHeight: '11.51px',
                    color: '#7A8699',
                    fontFamily: 'Plus Jakarta Sans',
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                ticks: {
                    fontSize: '9.14px',
                    fontWeight: 400,
                    lineHeight: '11.51px',
                    color: '#7A8699',
                    fontFamily: 'Plus Jakarta Sans',
                },

                grid: {
                    drawBorder: false,
                    // display: false,
                },
                border: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
    };

    return (
        <Box
            sx={{
                padding: 2,
                maxWidth: ['100%', '100%', '467.37px'],
                maxHeight: '371.42px',
                borderRadius: '12px',
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                background: '#FFFFFF',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '11.75px',
                        fontWeight: 700,
                        lineHeight: '26.32px',
                    }}
                >
                    Reach Summary
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        width: '128.7px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        onClick={() => handleFilterClick('Monthly')}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '9.14px',
                            fontWeight: 400,
                            lineHeight: '26.32px',
                            cursor: 'pointer',

                            color:
                                selectedFilter === 'Monthly'
                                    ? 'black'
                                    : '#7A8699',
                        }}
                    >
                        Monthly
                    </Typography>
                    <Typography
                        onClick={() => handleFilterClick('Weekly')}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '9.14px',
                            fontWeight: 400,
                            lineHeight: '26.32px',
                            cursor: 'pointer',
                            color:
                                selectedFilter === 'Weekly'
                                    ? 'black'
                                    : '#7A8699',
                        }}
                    >
                        Weekly
                    </Typography>
                    <Typography
                        onClick={() => handleFilterClick('Daily')}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: '9.14px',
                            fontWeight: 400,
                            lineHeight: '26.32px',
                            cursor: 'pointer',

                            color:
                                selectedFilter === 'Daily'
                                    ? 'black'
                                    : '#7A8699',
                        }}
                    >
                        Daily
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                {loading ? (
                    <Skeleton width={150} height={30} />
                ) : (
                    <Box display={'flex'} gap={'20px'}>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '20.89px',
                                fontWeight: 700,
                                lineHeight: '26.32px',
                            }}
                        >
                            {reachSummary?.totalReach}
                        </Typography>
                        <ArrowDropUpIcon
                            sx={{
                                color: '#007AFF',
                                fontSize: '30px',
                            }}
                        />

                        <Typography
                            color="success"
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '9.14px',
                                fontWeight: 400,
                                lineHeight: '26.32px',
                            }}
                        >
                            {reachSummary?.percentageChange}% from last month
                        </Typography>
                    </Box>
                )}

                <Button
                    color="primary"
                    sx={{
                        width: '120.11px',
                        height: '36.55px',
                        borderRadius: '7.83px',
                        border: '0.65px solid #007AFF',
                        background: '#5100F3',
                        color: '#FFFFFF',
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '10.44px',
                        fontWeight: 600,
                        ':hover': {
                            border: '0.65px solid #007AFF',
                            background: '#5100F3',
                            color: '#FFFFFF',
                        },
                    }}
                    onClick={() => console.log('working')}
                >
                    Download CSV
                </Button>
            </Box>
            <Box
                sx={{
                    width: { xs: 'auto', md: 'auto', lg: '450.6px' },
                    height: { xs: 'auto', md: 'auto', lg: '224.8px' },
                }}
            >
                <Line data={data} options={options} />
            </Box>
        </Box>
    );
};

export default PlatformReachSummary;
