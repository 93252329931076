import {
    Box,
    Container,
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableHead,
} from '@mui/material';
import * as React from 'react';
import StatCard from '../../Components/Dashboard/cards/stats';
import NewCampaignIcon from '../../assets/dashboard/new-campaign-icon.png';
import ReportsIcon from '../../assets/dashboard/reports-icon.png';
import supportIcon from '../../assets/dashboard/support-icon.png';
import settingsIcon from '../../assets/dashboard/settings-icon.png';
import ColumnChart from '../../Components/Dashboard/charts/ColumnChart';
import LineChart from '../../Components/Dashboard/charts/LineChart';
import youtubeIcon from '../../assets/yt_icon.png';
import linkedinIcon from '../../assets/in_icon.png';
import tiktokIcon from '../../assets/tt_icon.png';
import instagramIcon from '../../assets/ig_icon.png';
import snapchatIcon from '../../assets/d-sc_icon.png';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler,
} from 'chart.js';
import {
    Card,
    CardBody,
    CardHeading,
} from '../../Components/Dashboard/cards/dataCard';
import { BodyTableCell, HeadTableCell } from '../../Components/Dashboard/table';
import {
    PercentageValue,
    StyledLinearProgress,
    StyledLinearProgressWrapper,
} from '../../Components/Dashboard/progressBar';
import CustomTimeline from '../../Components/Dashboard/timeLine';
import dev from '../../services/axios-client';
import {
    generateColumnChartData,
    generateLineChartData,
    getCardsData,
    getNotificationsData,
    getRecentCampaignsData,
} from '../../services/dashboardService';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import 'chart.js/auto';

function MerchantDashboard() {
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    const user = JSON.parse(localStorage.getItem('user'));
    const isMerchantId19 = user?.user?.merchant?.id === 19;

    const cardsData = getCardsData(data);
    const recentCampaignsData = getRecentCampaignsData(data);
    const lineChartData = generateLineChartData(data);
    const columnChartdata = generateColumnChartData(data);
    const notificationsData = getNotificationsData(data);

    const _renderPlatformIcons = (platforms) => {
        return platforms.map((platform, index) => {
            let icon;
            switch (platform) {
                case 'linkedin':
                    icon = (
                        <img
                            key="linkedin"
                            src={linkedinIcon}
                            alt="LinkedIn"
                            height="17"
                            width="17"
                        />
                    );
                    break;
                case 'snapchat':
                    icon = (
                        <img
                            key="snapchat"
                            src={snapchatIcon}
                            alt="Snapchat"
                            height="16"
                            width="16"
                        />
                    );
                    break;
                case 'tiktok':
                    icon = (
                        <img
                            key="tiktok"
                            src={tiktokIcon}
                            alt="TikTok"
                            height="17"
                            width="15"
                        />
                    );
                    break;
                case 'youtube':
                    icon = (
                        <img
                            key="youtube"
                            src={youtubeIcon}
                            alt="YouTube"
                            height="17"
                            width="17"
                        />
                    );
                    break;
                case 'instagram':
                    icon = (
                        <img
                            key="instagram"
                            src={instagramIcon}
                            alt="Instagram"
                            height="17"
                            width="17"
                        />
                    );
                    break;
                default:
                    icon = null;
            }

            if (index < platforms.length - 1) {
                icon = (
                    <>
                        {icon}
                        <span style={{ marginLeft: '5px' }} />
                    </>
                );
            }
            return icon;
        });
    };

    const _renderProgress = (value) => {
        return (
            <StyledLinearProgressWrapper>
                <StyledLinearProgress variant="determinate" value={value} />
                <PercentageValue>{value}%</PercentageValue>
            </StyledLinearProgressWrapper>
        );
    };

    React.useEffect(() => {
        const getDashboardData = async () => {
            try {
                let url = '/merchant/dashboard/data';
                const response = await dev.get(url, {
                    headers: {
                        token: user.token,
                    },
                });
                setData(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.log(
                    'Error: ',
                    error.response?.data?.message || error.message,
                );
                setIsLoading(false);
            }
        };
        getDashboardData();
    }, []);

    return (
        <Container maxWidth="xl">
            {isLoading ? (
                <Loader loading={isLoading} />
            ) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                component="h5"
                                sx={{ fontWeight: '700', textAlign: 'center' }}
                                gutterBottom
                            >
                                Dashboard
                            </Typography>
                        </Grid>

                        <Grid item container spacing={2} xs={12} sm={8} mb={3}>
                            <Grid item xs={3}>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                    }}
                                    to="/create-new-campaign"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        flexGrow={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            src={NewCampaignIcon}
                                            alt="campaign icon"
                                            height="70"
                                            width="70"
                                        />
                                        <Typography
                                            variant="body"
                                            fontWeight="600"
                                            mt={1}
                                        >
                                            Campaigns
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            {!isMerchantId19 && (
                                <Grid item xs={3} sx={{ cursor: 'pointer' }}>
                                    <Link
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                        }}
                                        to="/reports"
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            flexGrow={1}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <img
                                                src={ReportsIcon}
                                                alt="campaign icon"
                                                height="70"
                                                width="70"
                                            />
                                            <Typography
                                                variant="body"
                                                fontWeight="600"
                                                mt={1}
                                            >
                                                Reports
                                            </Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                            )}
                            <Grid item xs={3} sx={{ cursor: 'pointer' }}>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://following.freshdesk.com/support/home"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        flexGrow={1}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            src={supportIcon}
                                            alt="campaign icon"
                                            height="70"
                                            width="70"
                                        />
                                        <Typography
                                            variant="body"
                                            fontWeight="600"
                                            mt={1}
                                        >
                                            Support
                                        </Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            {/* <Grid item xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={settingsIcon}
                      alt="campaign icon"
                      height="70"
                      width="70"
                    />
                    <Typography variant="body" fontWeight="600" mt={1}>
                      Settings
                    </Typography>
                  </Box>
                </Grid> */}
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            xs={12}
                            marginBottom={2}
                        >
                            {cardsData.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={3}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <StatCard
                                        title={item.title}
                                        value={item.value}
                                        icon={item.icon}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item container spacing={2} xs={12}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={7}
                                sx={{ marginBottom: 2 }}
                            >
                                <Card
                                    padding="20px 20px"
                                    sx={{ height: '318px' }}
                                >
                                    <CardHeading>Recent Campaigns</CardHeading>
                                    <CardBody>
                                        <Table aria-label="recent campaigns table">
                                            <TableHead>
                                                <TableRow>
                                                    <HeadTableCell>
                                                        campaign name
                                                    </HeadTableCell>
                                                    <HeadTableCell>
                                                        platforms
                                                    </HeadTableCell>
                                                    <HeadTableCell>
                                                        budget
                                                    </HeadTableCell>
                                                    <HeadTableCell>
                                                        completion
                                                    </HeadTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {recentCampaignsData.map(
                                                    (row) => (
                                                        <TableRow
                                                            key={row.name}
                                                        >
                                                            <BodyTableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {row.name}
                                                            </BodyTableCell>
                                                            <BodyTableCell>
                                                                {_renderPlatformIcons(
                                                                    row.platforms,
                                                                )}
                                                            </BodyTableCell>
                                                            <BodyTableCell>
                                                                {row.budget ||
                                                                    'Not set'}
                                                            </BodyTableCell>
                                                            <BodyTableCell>
                                                                {_renderProgress(
                                                                    row.completion,
                                                                )}
                                                            </BodyTableCell>
                                                        </TableRow>
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={5}
                                sx={{ marginBottom: 2 }}
                            >
                                <Card padding="30px 20px">
                                    <CardHeading>Engagement</CardHeading>
                                    <CardBody>
                                        {<LineChart data={lineChartData} />}
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            xs={12}
                            marginBottom={2}
                        >
                            <Grid item xs={12} md={7} sx={{ marginBottom: 2 }}>
                                <Card>
                                    <CardHeading>Reach by City</CardHeading>
                                    <CardBody height="18rem" overflow="auto">
                                        <ColumnChart data={columnChartdata} />
                                    </CardBody>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={5} sx={{ marginBottom: 2 }}>
                                <Card>
                                    <CardHeading>Notifications</CardHeading>
                                    <CardBody height="18rem" overflow="auto">
                                        <CustomTimeline
                                            events={notificationsData}
                                        />
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Container>
    );
}

export default MerchantDashboard;
