import { Grid, Tooltip } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dev from "../../../services/axios-client";
import Pagination from "../../Pagination";
import PaginationWrapper from "../../Wrapper/PaginationWrapper";
import Loader from "../../Loader";
import HeaderWrapper from "../../Wrapper/HeaderWrapper";
import HeaderText from "../../Text/HeaderText";
import SubHeaderText from "../../Text/SubHeaderText";
import { Divider } from "antd";

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const bodyColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  fontFamily: "'IBM Plex Sans'",
  color: "#212529",
  textAlign: "left",
  padding: { xs: "5px 8px", md: "8px 10px", lg: "10px 16px" },
};

function Scoring() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getScoringMetrics();
  }, []);

  const getScoringMetrics = async (page = 1) => {
    setLoading(true);
    await dev
      .get(`/admin/influencers/matrics?page=${page}&per_page=10`, {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data) {
          setLoading(false);
          setRows(response.data.data);
          setPage(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const handlePageChange = async (page) => {
    await getScoringMetrics(page);
  };

  return (
    <>
      <Grid container sx={{ height: "100%", display: "flex" }}>
        <Loader loading={loading} />
        <HeaderWrapper>
          <HeaderText
            text="Influencer Management"
            style={{
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "42px",
            }}
          />
        </HeaderWrapper>
        <SubHeaderText
          customstyle={{
            mt: "30px",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#282F53",
          }}
          text="Scoring metrics"
        />
        <Grid
          xs={12}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <TableContainer sx={{ mt: "30px", mb: "30px" }}>
            <Table>
              <TableHead
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "1px solid #E9EDF4",
                    borderTop: "1px solid #E9EDF4",
                  },
                }}
              >
                <TableRow>
                  <TableCell sx={headerColumnStyle}>Influencer Name</TableCell>
                  <TableCell sx={headerColumnStyle}>User name</TableCell>
                  <TableCell sx={headerColumnStyle}>no of posts</TableCell>
                  <TableCell sx={headerColumnStyle}>score</TableCell>
                  <TableCell sx={headerColumnStyle}>avg likes</TableCell>
                  <TableCell sx={headerColumnStyle}>avg views</TableCell>
                  <TableCell sx={headerColumnStyle}>Engagement rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                {rows?.map((row, index) => (
                  <TableRow key={row.index}>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      {row.name}
                    </TableCell>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      <Tooltip title={row.username}>
                        {row.username.length > 12
                          ? `${row.username.slice(0, 12)}...`
                          : row.username}
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      {row.influencerStat ? row.influencerStat.posts : 0}
                    </TableCell>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      {row.influencerStat ? row.influencerStat.score : 0}
                    </TableCell>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      {row.influencerStat ? row.influencerStat.likes : 0}
                    </TableCell>
                    <TableCell sx={{ ...bodyColumnStyle, maxWidth: "150px" }}>
                      {row.influencerStat ? row.influencerStat.views : 0}
                    </TableCell>
                    <TableCell sx={bodyColumnStyle}>
                      {row.influencerStat
                        ? row.influencerStat.engagementRate
                        : 0}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <PaginationWrapper border={"#E9EDF4"} pt={20} pb={210}>
            <Pagination
              currentPage={page?.page}
              pageSize={page?.per_page}
              totalUsers={page?.total}
              onPageChange={handlePageChange}
            />
          </PaginationWrapper>
        </Grid>
      </Grid>
    </>
  );
}

export default Scoring;
