import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import dev from '../../services/axios-client';
import Loader from '../Loader';

const StatusCards = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const [topCards, setTopCards] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const fetchTopCardsData = async (page = 1) => {
        setLoading(true);
        await dev
            .get(`/api/v1/admin/influencer/cards`, {
                headers: {
                    token: user.token,
                },
            })
            .then((response) => {
                if (response.data) {
                    setTopCards(response.data);
                }
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };
    React.useEffect(() => {
        fetchTopCardsData();
    }, []);

    console.log(topCards);
    return (
        <Box sx={{ mt: 3, padding: ['30px', '0px'] }}>
            <Loader loading={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ['center', 'start'],
                            alignItems: 'center',
                            backgroundColor: '#F1F1F1',
                            borderRadius: '8px',
                            border: '2px solid transparent',
                            width: ['auto', '335px'],
                            height: ['auto', '151px'],
                        }}
                    >
                        <Box sx={{ p: 3 }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '42px',
                                    lineHeight: '52.92px',
                                }}
                            >
                                {topCards?.influencersCount}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    letterSpacing: '-2%',
                                }}
                            >
                                Total Influencers
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ['center', 'start'],
                            alignItems: 'center',
                            backgroundColor: '#F1F1F1',
                            borderRadius: '8px',
                            border: '2px solid transparent',
                            width: ['auto', '335px'],
                            height: ['auto', '151px'],
                        }}
                    >
                        <Box sx={{ p: 3 }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '42px',
                                    lineHeight: '52.92px',
                                }}
                            >
                                {topCards?.pendingInfluencersCount}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    letterSpacing: '-2%',
                                }}
                            >
                                Signup Requests Pending
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ['center', 'start'],
                            alignItems: 'center',
                            backgroundColor: '#F1F1F1',
                            borderRadius: '8px',
                            border: '2px solid transparent',
                            width: ['auto', '335px'],
                            height: ['auto', '151px'],
                        }}
                    >
                        <Box sx={{ p: 3 }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '42px',
                                    lineHeight: '52.92px',
                                }}
                            >
                                {topCards?.contentApprovalPendingCount}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    letterSpacing: '-2%',
                                }}
                            >
                                Content Approvals Pending
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default StatusCards;
