import {
  Box,
  Grid,
  styled,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  Paper,
  tableClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import HeaderText from "../Text/HeaderText";
import dev from "../../services/axios-client";
import Loader from "../Loader";
import ApproveRejectRequestsTable from "./ApproveRejectRequests";
import ViewRequestTable from "./ViewRequest";
import share_icon from "../../assets/share_icon.png";
import "./table.css";
import { PendingPaymentTable } from "./PendingPaymentConfirmationTable";
import { useLocation, useNavigate } from "react-router-dom";

const headerColumnStyle = {
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "uppercase",
  fontWeight: "500",
  fontFamily: "'IBM Plex Sans'",
  color: "#2e3138",
  textAlign: "left",
  marginBottom: "5px",
  padding: { xs: "8px 8px", md: "10px 10px", lg: "16px 16px" },
};

const activeHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#272727",
  textAlign: "center",
  border: "1px solid #E9EDF4",
  borderRadius: "5px 5px 0px 0px",
  borderCollapse: "null",
};

const nonActiveHeader = {
  cursor: "pointer",
  padding: "19px 22px",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "500",
  color: "#74829C",
  textAlign: "center",
};

const PaymentWithdrawRequest = () => {
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(0);
  const [rows, setRows] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const tab = urlParams.get("tab");
  const navigate = useNavigate();

  useEffect(() => {
    const tabToActiveIndex = {
      ad: 1,
      ppc: 2,
    };
  
    setActive(tabToActiveIndex[tab] || 0);
  }, [location, tab]);

  return (
    <Grid container sx={{ height: "100%" }}>
      <Loader loading={loading} />
      <Grid item xs={12}>
        <HeaderText text="Influencer Withdrawal Request" />
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#FFFFFF",
          mt: "20px",
          padding: "30px",
          borderRadius: "7px",
          boxShadow: "0px 8px 24px rgba(168, 180, 208, 0.1)",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "1px solid #E9EDF4",
                },
              }}
            >
              <TableRow>
                <TableCell
                  sx={active === 0 ? activeHeader : nonActiveHeader}
                  style={{ width: "140px" }}
                  onClick={() => navigate("/payment-withdraw-requests")}
                >
                  View Requests
                </TableCell>
                <TableCell
                  sx={active === 1 ? activeHeader : nonActiveHeader}
                  style={{ width: "280px" }}
                  onClick={() => navigate("/payment-withdraw-requests?tab=ad")}
                >
                  Approved / Declined Requests
                </TableCell>
                <TableCell
                  sx={active === 2 ? activeHeader : nonActiveHeader}
                  style={{ width: "290px" }}
                  onClick={() => navigate("/payment-withdraw-requests?tab=ppc")}
                >
                  Pending Payment Confirmation
                </TableCell>
                <TableCell sx={{ ...headerColumnStyle }}></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        {active === 0 && <ViewRequestTable />}
        {active === 1 && <ApproveRejectRequestsTable />}
        {active === 2 && <PendingPaymentTable />}
      </Grid>
    </Grid>
  );
};

export default PaymentWithdrawRequest;
