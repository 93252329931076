import { useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import HeaderText from "../Text/HeaderText";
import corss_icon from "../../assets/cross.png";
import plus_icon from "../../assets/plus.png";
import PrimaryBtn from "../CustomButton/PrimaryBtn";
import dev from "../../services/axios-client";
import FormSubmitDialog from "../Popups/formSubmitDialog";

const inputStyle = {
  width: "100%",
  height: "69.04px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "4px",
  border: "1px solid #E9EDF4",
  justifyContent: "space-between",
};

const inputContainer = {
  gap: "10px",
  width: "471px",
  height: "69.04px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const UserDetailDialog = ({
  modal,
  toggleModal,
  roles,
  users,
  prevUsers,
  handleUsers,
}) => {
  let { merchantId } = useParams();
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [responseModal, setResponseModal] = useState({ open: false });

  const addUser = () => {
    handleUsers([...users, { email: "", password: "", roleId: null }]);
  };

  console.log(prevUsers, users);

  const deleteUser = (index, user) => {
    setRemove({ loading: true, removeIndex: index });
    if (user?.id) {
      dev
        .delete(`/merchant/${merchantId}/merchantUser/${user.id}`, {
          headers: {
            token: user.token,
          },
        })
        .then((response) => {
          if (response) {
            setRemove({ loading: false, removeIndex: null });
            const updatedUsers = [...users];
            updatedUsers.splice(index, 1);
            handleUsers(updatedUsers);
          }
        })
        .catch(({ response }) => {
          setRemove({ loading: false, removeIndex: null });
        });
    } else {
      const updatedUsers = [...users];
      updatedUsers.splice(index, 1);
      handleUsers(updatedUsers);
    }
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedUsers = [...users];
    updatedUsers[index][name] = value;
    handleUsers(updatedUsers);
  };

  const handleRoleChange = (index, e) => {
    const { value } = e.target;
    const updatedUsers = [...users];
    updatedUsers[index]["roleId"] = parseInt(value);
    handleUsers(updatedUsers);
  };

  const handleResponseClose = () => {
    setResponseModal({ open: false });
    if (responseModal?.toggleUserModal) toggleModal();
  };

  const onUpdate = () => {
    for (const obj of users) {
      if (!obj.email || !obj.password || !obj.roleId) {
        setResponseModal({
          open: true,
          error: true,
          toggleUserModal: false,
          title: "Please fill all the given fields for users",
        });
        return;
      }
    }
    setLoading(true);
    const updatedData = {
      merchantUsers: users.map((user) => ({
        id: user.id ?? null,
        roleId: user.roleId,
        password: user.password,
        email: user.email,
      })),
    };

    dev
      .put(`/merchant/merchantUser/${merchantId}`, updatedData, {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        setLoading(false);
        setResponseModal({
          open: true,
          error: false,
          toggleUserModal: true,
          title: response?.data?.message,
        });
      })
      .catch(({ response }) => {
        setResponseModal({
          open: true,
          error: true,
          toggleUserModal: true,
          title: response?.data?.errorr,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <FormSubmitDialog modal={responseModal} onClose={handleResponseClose} />

      <Modal
        disableBackdropClick
        open={modal}
        onClose={(event, reason) => {
          reason !== "backdropClick" && toggleModal();
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: "16px 30px",
            borderRadius: "10px",
            width: "1222px",
            height: "80%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <HeaderText
                text="Edit User Details"
                color="#272727"
                style={{
                  fontSize: "22px",
                  fontWeight: 500,
                  lineHeight: "29px",
                }}
              />
              <img
                src={corss_icon}
                alt=""
                onClick={toggleModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <Divider sx={{ color: "#ECEFF6", width: "100%" }} />
            <div
              style={{
                width: "100%",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                onClick={addUser}
                style={{
                  textAlign: "right",
                  gap: "10px",
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "end",
                }}
              >
                <img src={plus_icon} alt="" onClick={addUser} />
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    color: "#272727",
                    cursor: "pointer",
                  }}
                >
                  Add New User
                </label>
              </div>
              {users.map((user, index) => (
                <div
                  key={index}
                  style={{
                    gap: "20px",
                    height: "250px",
                    display: "flex",
                    borderRadius: "7px",
                    padding: "21px 30px",
                    background: "#E7E5E5",
                    flexDirection: "column",
                    border: "1px solid #E7E5E5",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div style={{ ...inputContainer }}>
                      <label>Email</label>
                      <input
                        style={{ ...inputStyle }}
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={user.email}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                    <div style={{ ...inputContainer, width: "670px" }}>
                      <label>Password</label>
                      <input
                        style={{ ...inputStyle, width: "100%" }}
                        type="text"
                        placeholder="Password"
                        name="password"
                        value={user.password}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  </div>

                  <div style={{ ...inputContainer, width: "100%", gap: 0 }}>
                    <label>Role of Account</label>

                    <select
                      style={{
                        padding: "8px 16px",
                        fontSize: "16px",
                        border: "1px solid white",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        outline: "none",
                        ":hover": {
                          borderColor: "#2684ff",
                        },
                        ":focus": {
                          borderColor: "#2684ff",
                          boxShadow: "0 0 0 1px #2684ff",
                        },
                      }}
                      name="roleId"
                      value={user.roleId}
                      onChange={(value) => handleRoleChange(index, value)}
                    >
                      <option value="" disabled selected>
                        Please select a role
                      </option>
                      {roles.map((opt) => (
                        <option value={opt.value}>{opt.label}</option>
                      ))}
                    </select>
                  </div>

                  <PrimaryBtn
                    disabled={remove.loading && remove.removeIndex === index}
                    text="Delete User"
                    color="white"
                    bgColor="#E94E51"
                    onClick={() => deleteUser(index, user)}
                    style={{ width: "130px" }}
                  />
                </div>
              ))}
            </div>
          </Box>
          {users.length > 0 && (
            <Box display={"flex"} sx={{ gap: "10px", mt: "30px" }}>
              <PrimaryBtn
                text="Cancel"
                onClick={() => {
                  toggleModal();
                  setLoading(false);
                }}
                bgColor="#F9F9F9"
                color="#8D8D8D"
                style={{ width: "120px" }}
              />
              <PrimaryBtn
                text="Update"
                onClick={onUpdate}
                disabled={loading}
                bgColor="#272727"
                style={{ width: "120px" }}
              />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UserDetailDialog;
