import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Typography } from "@mui/material";

const CustomTimeline = ({ events }) => {
  return (
    <Timeline>
      {events.map((event, index) => (
        <TimelineItem
          key={index}
          sx={{
            "&:before": {
              content: "none",
            },
          }}
        >
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: "#fff",
                boxShadow: "none",
                margin: 0,
              }}
            >
              <NotificationsIcon sx={{ color: "#D4FF02" }} />
            </TimelineDot>
            {index !== events.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h6"
              component="div"
              pt={0}
              color="#2D3748"
              fontSize="14px"
              lineHeight="20px"
              fontFamily="IBM Plex Sans"
            >
              {event.title}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color="#A0AEC0"
              fontSize="12px"
              lineHeight="18px"
              fontFamily="IBM Plex Sans"
            >
              {event.date}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimeline;
