import React from 'react';
import 'chart.js/auto';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import AudienceInstagramIcon from '../../assets/instagram/Rectangle 2370.png';

const AudienceEngagementChart = ({ instagram }) => {
    return (
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
            <EngagementCard
                name={'Posts'}
                color="#D3F26A"
                value={instagram?.posts}
            />
            <EngagementCard
                name={'Reels'}
                color="#F80022"
                value={instagram?.reals}
            />
            <EngagementCard
                name={'Story'}
                color="#F84C02"
                value={instagram?.story}
            />
        </Box>
    );
};

export default AudienceEngagementChart;

const EngagementCard = ({ name, color, value }) => {
    return (
        <Grid
            mt="20"
            display={'flex'}
            alignItems={'end'}
            justifyContent={'space-between'}
        >
            <Box display={'flex'} gap="20px">
                <img alt="" src={AudienceInstagramIcon} />
                <Box
                    display={'flex'}
                    alignItems={'start'}
                    flexDirection={'column'}
                >
                    <Typography>{name}</Typography>
                    <LinearProgress
                        sx={{
                            width: '310.55px',
                            height: '11.29px',
                            mt: '15px',
                            borderRadius: '5px',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: color,
                            },
                            backgroundColor: '#CDCCC8',
                        }}
                        variant="determinate"
                        value={value}
                    />
                </Box>
            </Box>
            <Box color={'#0000004D'} display={'flex'}>
                <Typography color={'black'}>{value}%</Typography>&nbsp; Reach
            </Box>
        </Grid>
    );
};
