import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box } from '@mui/material';

export default function AreaChart() {
    function getGradient1(ctx, chartArea) {
        let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top,
        );
        gradient.addColorStop(0, 'transparent');
        gradient.addColorStop(1, 'rgba(0, 157, 255, 0.31728)');
        return gradient;
    }
    function getGradient2(ctx, chartArea) {
        let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top,
        );
        gradient.addColorStop(0, 'transparent');
        gradient.addColorStop(1, 'rgba(0, 224, 150, 0.326568)');
        return gradient;
    }

    const data = {
        labels: [
            'August',
            'September',
            'October',
            'November',
            'December',
            'January',
        ],
        datasets: [
            {
                label: 'Reach',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: true,
                borderColor: '#386FFE',
                lineTension: 0.5,
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    // This case happens on initial chart load
                    if (!chartArea) return;
                    return getGradient1(ctx, chartArea);
                },
            },
            {
                label: 'Reach',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: true,
                borderColor: '#07E098',
                lineTension: 0.5,
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    // This case happens on initial chart load
                    if (!chartArea) return;
                    return getGradient2(ctx, chartArea);
                },
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                display: false,
                stacked: true,
            },
            y: {
                display: false,
                stacked: true,
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
    };
    return <Line data={data} options={options} />;
}
